import { IPrintOptions, usePrint } from './print'
import { useFetchTransportPlaceStickersV2, useGetStickerByPallet } from '@/hooks'
import { usePreviewBase64Pdf } from '@shared/hooks'
import { OperationTypes } from '@/interfaces'


/** Печать стикера транспортного места */

interface printResult {
  /** @deprecated */
  printTransportPlaceSticker: (barcode: string, operationType?: OperationTypes | string, isPreview?: boolean, options?: IPrintOptions) => Promise<void>
  printTransportPlaceStickersV2: (palletId: string, isPreview?: boolean) => Promise<void[]>
}

export const usePrintTransportPlaceSticker = (): printResult => {
  const { previewBase64Pdf } = usePreviewBase64Pdf()
  const { printSticker, handlePrintStickersList } = usePrint()

  const { fetch: getTransportPlaceSticker } = useGetStickerByPallet()
  const { fetch: getTransportPlaceStickersV2 } = useFetchTransportPlaceStickersV2()

  /**
   * @deprecated use printTransportPlaceV2
   */
  const printTransportPlaceSticker = async (barcode: string, operationType?: OperationTypes | string, isPreview?: boolean, options: IPrintOptions = {}) => {
    return await getTransportPlaceSticker({ pallet_id: barcode, operationType })
      .then((result) => {
        const { sticker } = result
        if (!result || !sticker) { return }

        if (isPreview) {
          previewBase64Pdf(sticker.content, 'Стикер транспортного места')
          return
        }

        printSticker(sticker.printer, sticker.content, {
          copies: 1,
          ...options
        })
      })
      .catch(() => {

      })
  }

  const printTransportPlaceStickersV2 = async (palletId: string, isPreview?: boolean) => {
    const { files_for_print } = await getTransportPlaceStickersV2({ transportPlaceId: palletId })
    return await handlePrintStickersList(files_for_print || [], isPreview)
  }

  return {
    printTransportPlaceSticker,
    printTransportPlaceStickersV2
  }
}