import { ComboboxItemDefault } from '@consta/uikit/Combobox'

type CountryType = {
  code: string,
  rusName: string,
}
export const countries: CountryType[] = [
    {
        "code": "RU",
        "rusName": "Россия"
    },
    {
        "code": "BY",
        "rusName": "Беларусь"
    },
    {
        "code": "KZ",
        "rusName": "Казахстан"
    },
    {
        "code": "UA",
        "rusName": "Украина"
    },
    {
        "code": "AU",
        "rusName": "Австралия"
    },
    {
        "code": "AT",
        "rusName": "Австрия"
    },
    {
        "code": "AZ",
        "rusName": "Азербайджан"
    },
    {
        "code": "AX",
        "rusName": "Аландские о-ва"
    },
    {
        "code": "AL",
        "rusName": "Албания"
    },
    {
        "code": "DZ",
        "rusName": "Алжир"
    },
    {
        "code": "AS",
        "rusName": "Американское Самоа"
    },
    {
        "code": "AI",
        "rusName": "Ангилья"
    },
    {
        "code": "AO",
        "rusName": "Ангола"
    },
    {
        "code": "AD",
        "rusName": "Андорра"
    },
    {
        "code": "AQ",
        "rusName": "Антарктида"
    },
    {
        "code": "AG",
        "rusName": "Антигуа и Барбуда"
    },
    {
        "code": "AR",
        "rusName": "Аргентина"
    },
    {
        "code": "AM",
        "rusName": "Армения"
    },
    {
        "code": "AW",
        "rusName": "Аруба"
    },
    {
        "code": "AF",
        "rusName": "Афганистан"
    },
    {
        "code": "BS",
        "rusName": "Багамские о-ва"
    },
    {
        "code": "BD",
        "rusName": "Бангладеш"
    },
    {
        "code": "BB",
        "rusName": "Барбадос"
    },
    {
        "code": "BH",
        "rusName": "Бахрейн"
    },
    {
        "code": "BZ",
        "rusName": "Белиз"
    },
    {
        "code": "BE",
        "rusName": "Бельгия"
    },
    {
        "code": "BJ",
        "rusName": "Бенин"
    },
    {
        "code": "BM",
        "rusName": "Бермудские о-ва"
    },
    {
        "code": "BG",
        "rusName": "Болгария"
    },
    {
        "code": "BO",
        "rusName": "Боливия"
    },
    {
        "code": "BQ",
        "rusName": "Бонэйр, Синт-Эстатиус и Саба"
    },
    {
        "code": "BA",
        "rusName": "Босния и Герцеговина"
    },
    {
        "code": "BW",
        "rusName": "Ботсвана"
    },
    {
        "code": "BR",
        "rusName": "Бразилия"
    },
    {
        "code": "IO",
        "rusName": "Британская территория в Индийском океане"
    },
    {
        "code": "BN",
        "rusName": "Бруней-Даруссалам"
    },
    {
        "code": "BF",
        "rusName": "Буркина-Фасо"
    },
    {
        "code": "BI",
        "rusName": "Бурунди"
    },
    {
        "code": "BT",
        "rusName": "Бутан"
    },
    {
        "code": "VU",
        "rusName": "Вануату"
    },
    {
        "code": "VA",
        "rusName": "Ватикан"
    },
    {
        "code": "GB",
        "rusName": "Великобритания"
    },
    {
        "code": "HU",
        "rusName": "Венгрия"
    },
    {
        "code": "VE",
        "rusName": "Венесуэла"
    },
    {
        "code": "VG",
        "rusName": "Виргинские о-ва (Британские)"
    },
    {
        "code": "VI",
        "rusName": "Виргинские о-ва (США)"
    },
    {
        "code": "UM",
        "rusName": "Внешние малые о-ва (США)"
    },
    {
        "code": "TL",
        "rusName": "Восточный Тимор"
    },
    {
        "code": "VN",
        "rusName": "Вьетнам"
    },
    {
        "code": "GA",
        "rusName": "Габон"
    },
    {
        "code": "HT",
        "rusName": "Гаити"
    },
    {
        "code": "GY",
        "rusName": "Гайана"
    },
    {
        "code": "GM",
        "rusName": "Гамбия"
    },
    {
        "code": "GH",
        "rusName": "Гана"
    },
    {
        "code": "GP",
        "rusName": "Гваделупа"
    },
    {
        "code": "GT",
        "rusName": "Гватемала"
    },
    {
        "code": "GN",
        "rusName": "Гвинея"
    },
    {
        "code": "GW",
        "rusName": "Гвинея-Бисау"
    },
    {
        "code": "DE",
        "rusName": "Германия"
    },
    {
        "code": "GG",
        "rusName": "Гернси"
    },
    {
        "code": "GI",
        "rusName": "Гибралтар"
    },
    {
        "code": "HN",
        "rusName": "Гондурас"
    },
    {
        "code": "HK",
        "rusName": "Гонконг"
    },
    {
        "code": "GD",
        "rusName": "Гренада"
    },
    {
        "code": "GL",
        "rusName": "Гренландия"
    },
    {
        "code": "GR",
        "rusName": "Греция"
    },
    {
        "code": "GE",
        "rusName": "Грузия"
    },
    {
        "code": "GU",
        "rusName": "Гуам"
    },
    {
        "code": "DK",
        "rusName": "Дания"
    },
    {
        "code": "JE",
        "rusName": "Джерси"
    },
    {
        "code": "DJ",
        "rusName": "Джибути"
    },
    {
        "code": "DG",
        "rusName": "Диего-Гарсия"
    },
    {
        "code": "DM",
        "rusName": "Доминика"
    },
    {
        "code": "DO",
        "rusName": "Доминиканская Республика"
    },
    {
        "code": "EG",
        "rusName": "Египет"
    },
    {
        "code": "ZM",
        "rusName": "Замбия"
    },
    {
        "code": "EH",
        "rusName": "Западная Сахара"
    },
    {
        "code": "ZW",
        "rusName": "Зимбабве"
    },
    {
        "code": "IL",
        "rusName": "Израиль"
    },
    {
        "code": "IN",
        "rusName": "Индия"
    },
    {
        "code": "ID",
        "rusName": "Индонезия"
    },
    {
        "code": "JO",
        "rusName": "Иордания"
    },
    {
        "code": "IQ",
        "rusName": "Ирак"
    },
    {
        "code": "IR",
        "rusName": "Иран"
    },
    {
        "code": "IE",
        "rusName": "Ирландия"
    },
    {
        "code": "IS",
        "rusName": "Исландия"
    },
    {
        "code": "ES",
        "rusName": "Испания"
    },
    {
        "code": "IT",
        "rusName": "Италия"
    },
    {
        "code": "YE",
        "rusName": "Йемен"
    },
    {
        "code": "CV",
        "rusName": "Кабо-Верде"
    },
    {
        "code": "KY",
        "rusName": "Каймановы о-ва"
    },
    {
        "code": "KH",
        "rusName": "Камбоджа"
    },
    {
        "code": "CM",
        "rusName": "Камерун"
    },
    {
        "code": "CA",
        "rusName": "Канада"
    },
    {
        "code": "IC",
        "rusName": "Канарские о-ва"
    },
    {
        "code": "QA",
        "rusName": "Катар"
    },
    {
        "code": "KE",
        "rusName": "Кения"
    },
    {
        "code": "CY",
        "rusName": "Кипр"
    },
    {
        "code": "KG",
        "rusName": "Киргизия"
    },
    {
        "code": "KI",
        "rusName": "Кирибати"
    },
    {
        "code": "CN",
        "rusName": "Китай"
    },
    {
        "code": "KP",
        "rusName": "КНДР"
    },
    {
        "code": "CC",
        "rusName": "Кокосовые о-ва"
    },
    {
        "code": "CO",
        "rusName": "Колумбия"
    },
    {
        "code": "KM",
        "rusName": "Коморские о-ва"
    },
    {
        "code": "CG",
        "rusName": "Конго - Браззавиль"
    },
    {
        "code": "CD",
        "rusName": "Конго - Киншаса"
    },
    {
        "code": "XK",
        "rusName": "Косово"
    },
    {
        "code": "CR",
        "rusName": "Коста-Рика"
    },
    {
        "code": "CI",
        "rusName": "Кот-д’Ивуар"
    },
    {
        "code": "CU",
        "rusName": "Куба"
    },
    {
        "code": "KW",
        "rusName": "Кувейт"
    },
    {
        "code": "CW",
        "rusName": "Кюрасао"
    },
    {
        "code": "LA",
        "rusName": "Лаос"
    },
    {
        "code": "LV",
        "rusName": "Латвия"
    },
    {
        "code": "LS",
        "rusName": "Лесото"
    },
    {
        "code": "LR",
        "rusName": "Либерия"
    },
    {
        "code": "LB",
        "rusName": "Ливан"
    },
    {
        "code": "LY",
        "rusName": "Ливия"
    },
    {
        "code": "LT",
        "rusName": "Литва"
    },
    {
        "code": "LI",
        "rusName": "Лихтенштейн"
    },
    {
        "code": "LU",
        "rusName": "Люксембург"
    },
    {
        "code": "MU",
        "rusName": "Маврикий"
    },
    {
        "code": "MR",
        "rusName": "Мавритания"
    },
    {
        "code": "MG",
        "rusName": "Мадагаскар"
    },
    {
        "code": "YT",
        "rusName": "Майотта"
    },
    {
        "code": "MO",
        "rusName": "Макао (особый район)"
    },
    {
        "code": "MK",
        "rusName": "Македония"
    },
    {
        "code": "MW",
        "rusName": "Малави"
    },
    {
        "code": "MY",
        "rusName": "Малайзия"
    },
    {
        "code": "ML",
        "rusName": "Мали"
    },
    {
        "code": "MV",
        "rusName": "Мальдивские о-ва"
    },
    {
        "code": "MT",
        "rusName": "Мальта"
    },
    {
        "code": "MA",
        "rusName": "Марокко"
    },
    {
        "code": "MQ",
        "rusName": "Мартиника"
    },
    {
        "code": "MH",
        "rusName": "Маршалловы о-ва"
    },
    {
        "code": "MX",
        "rusName": "Мексика"
    },
    {
        "code": "MZ",
        "rusName": "Мозамбик"
    },
    {
        "code": "MD",
        "rusName": "Молдова"
    },
    {
        "code": "MC",
        "rusName": "Монако"
    },
    {
        "code": "MN",
        "rusName": "Монголия"
    },
    {
        "code": "MS",
        "rusName": "Монтсеррат"
    },
    {
        "code": "MM",
        "rusName": "Мьянма (Бирма)"
    },
    {
        "code": "NA",
        "rusName": "Намибия"
    },
    {
        "code": "NR",
        "rusName": "Науру"
    },
    {
        "code": "NP",
        "rusName": "Непал"
    },
    {
        "code": "NE",
        "rusName": "Нигер"
    },
    {
        "code": "NG",
        "rusName": "Нигерия"
    },
    {
        "code": "NL",
        "rusName": "Нидерланды"
    },
    {
        "code": "NI",
        "rusName": "Никарагуа"
    },
    {
        "code": "NU",
        "rusName": "Ниуэ"
    },
    {
        "code": "NZ",
        "rusName": "Новая Зеландия"
    },
    {
        "code": "NC",
        "rusName": "Новая Каледония"
    },
    {
        "code": "NO",
        "rusName": "Норвегия"
    },
    {
        "code": "AC",
        "rusName": "о-в Вознесения"
    },
    {
        "code": "IM",
        "rusName": "О-в Мэн"
    },
    {
        "code": "NF",
        "rusName": "о-в Норфолк"
    },
    {
        "code": "CX",
        "rusName": "о-в Рождества"
    },
    {
        "code": "SH",
        "rusName": "О-в Св. Елены"
    },
    {
        "code": "CK",
        "rusName": "о-ва Кука"
    },
    {
        "code": "TC",
        "rusName": "О-ва Тёркс и Кайкос"
    },
    {
        "code": "AE",
        "rusName": "Объединенные Арабские Эмираты"
    },
    {
        "code": "OM",
        "rusName": "Оман"
    },
    {
        "code": "PK",
        "rusName": "Пакистан"
    },
    {
        "code": "PW",
        "rusName": "Палау"
    },
    {
        "code": "PS",
        "rusName": "Палестинские территории"
    },
    {
        "code": "PA",
        "rusName": "Панама"
    },
    {
        "code": "PG",
        "rusName": "Папуа – Новая Гвинея"
    },
    {
        "code": "PY",
        "rusName": "Парагвай"
    },
    {
        "code": "PE",
        "rusName": "Перу"
    },
    {
        "code": "PN",
        "rusName": "Питкэрн"
    },
    {
        "code": "PL",
        "rusName": "Польша"
    },
    {
        "code": "PT",
        "rusName": "Португалия"
    },
    {
        "code": "PR",
        "rusName": "Пуэрто-Рико"
    },
    {
        "code": "KR",
        "rusName": "Республика Корея"
    },
    {
        "code": "RE",
        "rusName": "Реюньон"
    },
    {
        "code": "RW",
        "rusName": "Руанда"
    },
    {
        "code": "RO",
        "rusName": "Румыния"
    },
    {
        "code": "SV",
        "rusName": "Сальвадор"
    },
    {
        "code": "WS",
        "rusName": "Самоа"
    },
    {
        "code": "SM",
        "rusName": "Сан-Марино"
    },
    {
        "code": "ST",
        "rusName": "Сан-Томе и Принсипи"
    },
    {
        "code": "SA",
        "rusName": "Саудовская Аравия"
    },
    {
        "code": "SZ",
        "rusName": "Свазиленд"
    },
    {
        "code": "MP",
        "rusName": "Северные Марианские о-ва"
    },
    {
        "code": "SC",
        "rusName": "Сейшельские о-ва"
    },
    {
        "code": "BL",
        "rusName": "Сен-Бартельми"
    },
    {
        "code": "MF",
        "rusName": "Сен-Мартен"
    },
    {
        "code": "PM",
        "rusName": "Сен-Пьер и Микелон"
    },
    {
        "code": "SN",
        "rusName": "Сенегал"
    },
    {
        "code": "VC",
        "rusName": "Сент-Винсент и Гренадины"
    },
    {
        "code": "KN",
        "rusName": "Сент-Китс и Невис"
    },
    {
        "code": "LC",
        "rusName": "Сент-Люсия"
    },
    {
        "code": "RS",
        "rusName": "Сербия"
    },
    {
        "code": "EA",
        "rusName": "Сеута и Мелилья"
    },
    {
        "code": "SG",
        "rusName": "Сингапур"
    },
    {
        "code": "SX",
        "rusName": "Синт-Мартен"
    },
    {
        "code": "SY",
        "rusName": "Сирия"
    },
    {
        "code": "SK",
        "rusName": "Словакия"
    },
    {
        "code": "SI",
        "rusName": "Словения"
    },
    {
        "code": "US",
        "rusName": "Соединенные Штаты"
    },
    {
        "code": "SB",
        "rusName": "Соломоновы о-ва"
    },
    {
        "code": "SO",
        "rusName": "Сомали"
    },
    {
        "code": "SD",
        "rusName": "Судан"
    },
    {
        "code": "SR",
        "rusName": "Суринам"
    },
    {
        "code": "SL",
        "rusName": "Сьерра-Леоне"
    },
    {
        "code": "TJ",
        "rusName": "Таджикистан"
    },
    {
        "code": "TH",
        "rusName": "Таиланд"
    },
    {
        "code": "TW",
        "rusName": "Тайвань"
    },
    {
        "code": "TZ",
        "rusName": "Танзания"
    },
    {
        "code": "TG",
        "rusName": "Того"
    },
    {
        "code": "TK",
        "rusName": "Токелау"
    },
    {
        "code": "TO",
        "rusName": "Тонга"
    },
    {
        "code": "TT",
        "rusName": "Тринидад и Тобаго"
    },
    {
        "code": "TA",
        "rusName": "Тристан-да-Кунья"
    },
    {
        "code": "TV",
        "rusName": "Тувалу"
    },
    {
        "code": "TN",
        "rusName": "Тунис"
    },
    {
        "code": "TM",
        "rusName": "Туркменистан"
    },
    {
        "code": "TR",
        "rusName": "Турция"
    },
    {
        "code": "UG",
        "rusName": "Уганда"
    },
    {
        "code": "UZ",
        "rusName": "Узбекистан"
    },
    {
        "code": "WF",
        "rusName": "Уоллис и Футуна"
    },
    {
        "code": "UY",
        "rusName": "Уругвай"
    },
    {
        "code": "FO",
        "rusName": "Фарерские о-ва"
    },
    {
        "code": "FM",
        "rusName": "Федеративные Штаты Микронезии"
    },
    {
        "code": "FJ",
        "rusName": "Фиджи"
    },
    {
        "code": "PH",
        "rusName": "Филиппины"
    },
    {
        "code": "FI",
        "rusName": "Финляндия"
    },
    {
        "code": "FK",
        "rusName": "Фолклендские о-ва"
    },
    {
        "code": "FR",
        "rusName": "Франция"
    },
    {
        "code": "GF",
        "rusName": "Французская Гвиана"
    },
    {
        "code": "PF",
        "rusName": "Французская Полинезия"
    },
    {
        "code": "TF",
        "rusName": "Французские Южные Территории"
    },
    {
        "code": "HR",
        "rusName": "Хорватия"
    },
    {
        "code": "CF",
        "rusName": "ЦАР"
    },
    {
        "code": "TD",
        "rusName": "Чад"
    },
    {
        "code": "ME",
        "rusName": "Черногория"
    },
    {
        "code": "CZ",
        "rusName": "Чехия"
    },
    {
        "code": "CL",
        "rusName": "Чили"
    },
    {
        "code": "CH",
        "rusName": "Швейцария"
    },
    {
        "code": "SE",
        "rusName": "Швеция"
    },
    {
        "code": "SJ",
        "rusName": "Шпицберген и Ян-Майен"
    },
    {
        "code": "LK",
        "rusName": "Шри-Ланка"
    },
    {
        "code": "EC",
        "rusName": "Эквадор"
    },
    {
        "code": "GQ",
        "rusName": "Экваториальная Гвинея"
    },
    {
        "code": "ER",
        "rusName": "Эритрея"
    },
    {
        "code": "EE",
        "rusName": "Эстония"
    },
    {
        "code": "ET",
        "rusName": "Эфиопия"
    },
    {
        "code": "ZA",
        "rusName": "ЮАР"
    },
    {
        "code": "GS",
        "rusName": "Южная Георгия и Южные Сандвичевы о-ва"
    },
    {
        "code": "SS",
        "rusName": "Южный Судан"
    },
    {
        "code": "JM",
        "rusName": "Ямайка"
    },
    {
        "code": "JP",
        "rusName": "Япония"
    }
]

export const getCountriesOptions = (): ComboboxItemDefault[] => countries.map(country => ({
    id: country.code,
    label: country.rusName,
}))

export const getCountryByCode = (code: string): CountryType | undefined => {
  return countries.find(country => country.code === code)
}