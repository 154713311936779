import React, { useCallback, useEffect, useRef } from 'react'
import cx from 'classnames'
import styles from './TanStackTable.module.scss'
import { Cell, ColumnDef, flexRender, Row, Table, useReactTable } from '@tanstack/react-table'
import { RowData } from '@tanstack/table-core/src/types'
import { ExtraProps } from '@/components/Table/TanStackTable/types/extraProps'
import {
  getTBodyStyles,
  getTBodyTRStyles,
  getTDStyles,
  getTFootStyles,
  getTHStyles,
} from '@/components/Table/TanStackTable/lib/styles'
import './lib/features/module'
import { SYMBOL_ALL_ITEMS } from '@/components/Table/TanStackTable/lib/const'
import TableHeader from './components/TableHeader/TableHeader'
import { ColumnSettingsCell } from '@/components/Table/TanStackTable/components/ColumnSettingsCell/ColumnSettingsCell'
import { TableSettingsMenu } from '@/components/Table/TanStackTable/components/TableSettingsMenu/TableSettingsMenu'
import { Flex } from '@/newSrc/shared/ui/Flex'
import { Button } from '@shared/ui/btns/Button'
import { IconSettings } from '@consta/icons/IconSettings'
import { SkeletonBrick } from '@consta/uikit/Skeleton'
import TableContextMenu from '@/components/Table/TanStackTable/components/TableContextMenu/TableContextMenu'
import { useTable } from '@/components/Table/TanStackTable/hooks/useTable'
import { TablePagination } from '@/components'
import { useTableCopy } from './hooks/useTableCopy'
import { IconExpand } from '@consta/icons/IconExpand'
import { IconCollapse } from '@consta/icons/IconCollapse'
import { useGlobalKeys } from '@consta/uikit/useGlobalKeys'
import { TanStackTableThemeType } from './types/theme'


type TableRowMetaType = {
  active?: boolean
  /** Тема строки */
  theme?: TanStackTableThemeType
  /** Тема ячеек, (приоритетнее чем строки) */
  getCellsThemes?: () => Record<string /* cell/columnId */, TanStackTableThemeType>
}

export type TableRowType = {
  /* пользовательские свойства строк */
  id?: string
  subItems?: TableRowType[]
  meta?: TableRowMetaType
} & RowData & Record<string, any>

export type TableColumnType<TRowData = TableRowType, TRowValue = unknown> = ColumnDef<TRowData, TRowValue> & {
  id: string
}

export type TableInstance = ReturnType<typeof useReactTable>;

export type TanStackTableProps<TRowData = TableRowType, TRowValue = unknown> =
  {
    id: string
    pageId?: string
    isLoading?: boolean
    data: TRowData[]
    columns: TableColumnType<TRowData, TRowValue>[]
    tableRef?: React.RefObject<TableInstance | null>
  }
  & ExtraProps<TRowData, TRowValue> /* пользовательские свойства таблицы */

export const TanStackTable = <TRowData extends TableRowType, TRowValue>(props: TanStackTableProps<TRowData, TRowValue>) => {
  const {
    id,
    pageId,
    isLoading,
    columns,
    withHeader = true,

    title,
    subtitle,
    extraHeader,

    maxHeight,
    collapseDepthDefault,

    pagination,
    onChangePage,
  } = props

  const isAutoHeight = maxHeight === 'full'

  const tableMainWrapperRef = useRef<HTMLDivElement>(null)
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const tableRef = useRef<HTMLTableElement>(null)
  const btnTableSettingsMenuRef = useRef<HTMLElement>()
  const tableHeaderRef = useRef<HTMLDivElement>(null)
  const tablePaginationRef = useRef<HTMLDivElement>(null)

  const {
    table,
    tableState: {
      density,
      columnSizingInfo,
      tableSettingsVisible,
      contextMenu,
      isFullScreenOn,
    },
    allColumns,
    tableRows,
    pinnedBottomRows,
    staticRows,
    rowVirtualizer,

    tableSettingsMenuConfig,
    tableSettingsMenuButtonVisible,
  } = useTable({ ...props, tableContainerRef })

  useEffect(() => {
    /* дефолтно раскрываем нужные строки todo возможно, стоит переделать на дефолтный стейт, а не дергать из эфекта, подумать */
    if (collapseDepthDefault) {
      table?.setExpandedAllRowsByDepth(collapseDepthDefault)
    }
  }, [])

  useEffect(() => {
    /* Проверяем и записываем глубину вложенности */
    if (table?.getCanSomeRowsExpand()) {
      table?.updateMaxExpandDepth()
    }
  }, [props.data])

  const {
    resetCopyState,
    handleCheckCellSelection,
    handleCheckColumnSelection,
    getTableCellCopyEventsProps,
  } = useTableCopy<TRowData, TRowValue>({
    table,
    tableContainerRef,
  })

  useGlobalKeys({
    Escape: (e: KeyboardEvent) => {
      if (table.options.enableCopy && !table.copyStateIsEmpty()) {
        resetCopyState()
        return
      }
      if (isFullScreenOn) {
        table.toggleFullScreen()
      }
    },
  }, 'keydown')


  /* Размер вапперов таблицы */
  const getTableHeight = useCallback(() => {
    const windowHeight = window.innerHeight || 0
    const mainWrapperExtraStyles = '60px' // todo вычислять на основе paddingTop и gap
    const tablePaginationHeight = pagination ? tablePaginationRef?.current?.clientHeight || 0 : 0
    const fullHeight = `calc(${windowHeight}px - ${tablePaginationHeight}px - ${mainWrapperExtraStyles})`
    const autoHeight = `calc(100% - ${tablePaginationHeight}px)`
    /* На весь экран */
    if (isFullScreenOn) {
      return ({
        maxHeight: fullHeight,
        height: fullHeight,
      })
    }
    if (isAutoHeight) {
      return ({
        maxHeight: autoHeight,
        height: autoHeight,
      })
    }
    return ({
      maxHeight: maxHeight,
      height: maxHeight,
    })
  }, [isFullScreenOn, isAutoHeight, maxHeight, tableHeaderRef, tableMainWrapperRef, tablePaginationRef, pagination])


  /* Размер столбцов для маленьких таблиц */
  const copyShowCopyComplete = table.getState().copyShowCopyComplete
  const maxColWidth = columns.length === 1
    ? '100%' : columns.length === 2 ? '50%' : undefined

  return (
    <Flex
      gap={'s'}
      direction={'column'}
      ref={tableMainWrapperRef}
      className={cx(styles.tableMainWrapper, { [styles.fullScreen]: isFullScreenOn })}
    >
      {/** ---- TABLE HEADER ---- */}
      {withHeader ? (
        <TableHeader
          componentRef={tableHeaderRef}
          title={title}
          subtitle={subtitle}
          extraHeader={
            <Flex gap={'xs'}>
              {extraHeader || ''}

              {
                tableSettingsMenuButtonVisible ? (
                  <Button
                    view={'ghost'}
                    label={'Настройки таблицы'}
                    onClick={table.toggleTableSettingsVisible}
                    iconRight={IconSettings}
                    size={'s'}
                    ref={btnTableSettingsMenuRef}
                  />
                ) : null
              }
              <Button
                onlyIcon
                view={'ghost'}
                size={'s'}
                iconLeft={isFullScreenOn ? IconCollapse : IconExpand}
                onClick={table.toggleFullScreen}
              />
            </Flex>
          }
        />
      ) : null}

      {/** ---- TABLE ---- */}
      <Flex
        gap={density}
        className={cx(styles.tableSecondWrapper)}
        style={{ ...getTableHeight(), height: 'auto' }}
      >
        <SkeletonBrick
          className={cx(styles.skeletonTable, { [styles.visible]: isLoading })}
          height={'100%'}
        />
        <div
          className={cx(styles.tableThirdWrapper, styles[`density_${density}`])}
          ref={tableContainerRef}
          style={getTableHeight()}
        >
          <table
            border={1}
            ref={tableRef}
            cellPadding={0}
            cellSpacing={0}
            className={styles.table}
            // style={{width: table.getCenterTotalSize()}}
          >
            <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const { column } = header
                  const isResizing = column.getIsResizing() && columnSizingInfo.isResizingColumn === column.id
                  const columnSelected = handleCheckColumnSelection(column)
                  const columnSelectedBordered = (contextMenu?.selectedColumnId === column.id) && !contextMenu?.selectedRowId
                  const cellIsSelected = columnSelected || columnSelectedBordered
                  const copyShowCopyComplete = table.getState().copyShowCopyComplete
                  return (
                    <th
                      key={header.id}
                      style={{
                        ...getTHStyles(column),
                        width: maxColWidth ? maxColWidth : column.getSize(),
                      }}

                      onContextMenu={(e) => {
                        e.preventDefault()
                        table.getOpenContextMenuHandler<TRowData>({ header })(e)
                      }}
                    >
                      <div
                        className={cx(styles.content, {
                          [styles.cellSelected]: cellIsSelected && !copyShowCopyComplete,
                          [styles.cellSelectedComplete]: copyShowCopyComplete && cellIsSelected,
                        })}
                        {...getTableCellCopyEventsProps(column.id, SYMBOL_ALL_ITEMS)}
                      >
                        {header.isPlaceholder
                          ? null
                          :
                          flexRender(
                            column.columnDef.header,
                            header.getContext(),
                          )}

                        <ColumnSettingsCell<TRowData>
                          table={table}
                          column={column}
                        />

                        {/* Ресайзер todo вынести в отдельный файл*/}
                        {column.getCanResize() && (
                          <div
                            onDoubleClick={(e) => {
                              e.stopPropagation()
                              column.resetSize()
                            }}
                            onMouseDown={(e) => {
                              e.stopPropagation()
                              header.getResizeHandler()(e)
                            }}
                            onTouchStart={(e) => {
                              e.stopPropagation()
                              header.getResizeHandler()(e)
                            }}
                            className={cx(styles.resizer, { [styles.isResizing]: isResizing })}
                          />
                        )}
                      </div>
                    </th>
                  )
                })}
              </tr>
            ))}
            </thead>
            <tbody
              style={getTBodyStyles(rowVirtualizer)}
            >
            {rowVirtualizer.getVirtualItems().map((virtualRow) => {
              const row = staticRows[virtualRow.index] as Row<TRowData>
              return (
                <tr
                  data-index={virtualRow.index} //needed for dynamic row height measurement
                  ref={rowVirtualizer.measureElement} //measure dynamic row height
                  key={row.id}
                  style={getTBodyTRStyles({ virtualRow, row })}
                >
                  {row.getVisibleCells().map((cell, i, arr) => {
                    const cellSelected = handleCheckCellSelection(cell)
                    const rowSelectedBordered = contextMenu?.selectedColumnId === cell.column.id && contextMenu?.selectedRowId === row.id
                    const selectedItem = cellSelected || rowSelectedBordered
                    return (
                      <RowCellTd
                        key={cell.id}
                        table={table}
                        row={row}
                        cell={cell}
                        copyShowCopyComplete={copyShowCopyComplete}
                        cellIsSelected={selectedItem}
                        getTableCellCopyEventsProps={getTableCellCopyEventsProps}
                        extraStyles={{ width: maxColWidth ? maxColWidth : cell.column.getSize() }}
                      />
                    )
                  })}
                </tr>
              )
            })}
            </tbody>
            {
              pinnedBottomRows?.length ? (
                <tfoot style={getTFootStyles()}>
                {pinnedBottomRows.map((row) => (
                  <tr
                    key={row.id}
                    style={getTBodyTRStyles<TRowData>({ row, table })}
                  >
                    {row.getVisibleCells().map((cell, i, arr) => {
                      const cellSelected = handleCheckCellSelection(cell)
                      const rowSelectedBordered = contextMenu?.selectedColumnId === cell.column.id && contextMenu?.selectedRowId === row.id
                      const selectedItem = cellSelected || rowSelectedBordered
                      return (
                        <RowCellTd<TRowData>
                          key={cell.id}
                          table={table}
                          row={row}
                          cell={cell}
                          copyShowCopyComplete={copyShowCopyComplete}
                          cellIsSelected={selectedItem}
                          getTableCellCopyEventsProps={getTableCellCopyEventsProps}
                          extraStyles={{ width: maxColWidth ? maxColWidth : cell.column.getSize() }}
                        />
                      )
                    })}
                  </tr>
                ))}
                </tfoot>
              ) : null
            }
          </table>
        </div>
      </Flex>

      {pagination && pagination.total_pages > 1 ? (
        <TablePagination
          paginationRef={tablePaginationRef}
          withoutHotkey={true}
          pageSize={pagination.size}
          currentPage={pagination.page}
          totalPages={pagination.total_pages}
          onChangePage={onChangePage}
        />
      ) : null}

      <TableSettingsMenu<TRowData>
        tableId={id}
        pageId={pageId}
        config={tableSettingsMenuConfig}
        isOpen={tableSettingsVisible}
        table={table}
        btnRef={btnTableSettingsMenuRef}
      />
      {table.getContextMenuIsVisible() ? (
        <TableContextMenu
          table={table}
        />
      ) : null}
    </Flex>
  )
}

type RowTdProps<TRowData> = {
  table: Table<TRowData>,
  row: Row<TRowData>,
  cell: Cell<TRowData, unknown>,
  className?: string,
  getTableCellCopyEventsProps: (columnId: string, rowId: string) => { onMouseDown: any, onMouseOver: any },
  cellIsSelected: boolean,
  copyShowCopyComplete: boolean,
  extraStyles: any,
}
const RowCellTd = <TRowData extends TableRowType, >(props: RowTdProps<TRowData>) => {
  const {
    table,
    row,
    cell,
    className,
    cellIsSelected,
    copyShowCopyComplete,
    getTableCellCopyEventsProps,
    extraStyles,
  } = props

  const {
    isActionColumn,
    isExpanderColumn,
    isSelectionColumn,
  } = cell.column.checkActionColumn()

  /* Тут выбор экшена для столбца */
  const getOnClick = () => {
    if (!isActionColumn) return
    if (row.getCanExpand() && isExpanderColumn) {
      return row.getToggleExpandedHandler()
    }
    if (row.getCanSelect() && isSelectionColumn) {
      return row.getToggleSelectedHandler()
    }
  }
  const cellsThemesObj = row.original.meta?.getCellsThemes?.() || {}
  const cellTheme = cellsThemesObj[cell.column.id] || row.original.meta?.theme || 'white'
  return (
    <td
      key={cell.id}
      className={cx(className)}
      onClick={getOnClick()}
      style={{
        ...getTDStyles(cell.column, row, isActionColumn, cellIsSelected),
        ...extraStyles,
      }}

      onContextMenu={(e) => {
        e.preventDefault()
        const copyIsEmpty = table.copyStateIsEmpty()
        if (copyIsEmpty) {
          table.handleCopyMouseDownRowById(cell.column.id, cell.row.id)
        }
        table.getOpenContextMenuHandler<TRowData>({ row, cell })(e)
      }}
    >
      <div
        className={cx(
          styles.content,
          styles[`theme-${cellTheme}`],
          {
            [styles.cellSelected]: cellIsSelected && !copyShowCopyComplete,
            [styles.cellSelectedComplete]: copyShowCopyComplete && cellIsSelected,
          })
        }
        {...getTableCellCopyEventsProps(cell.column.id, row.id)}
      >
        {flexRender(
          cell.column.columnDef.cell,
          cell.getContext(),
        )}
      </div>
    </td>
  )
}
