import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import styles from './additional-packaging-modal.module.scss'

import { Button } from '@shared/ui/btns/Button'

import { EnterHandler, Modal } from 'src/components'
import {
  AdditionalPackagingsDto,
  ISku,
  ITableColumn,
  ModalProps,
  NewPackageDto,
  OptionsPackagingPackagigns,
  PackagePostDto,
} from 'src/interfaces'
import { InputWrapper, TableWithCopy } from '@/components'
import { Grid, GridItem } from '@consta/uikit/Grid'
import { useToggle } from '@shared/hooks'
import { blurOrFocusInput } from '@shared/helpers'
import { useNotifications } from '@shared/providers/NotificationProvider'
import {
  generateAddedItemsColumns,
  generateAddedItemsRows,
  generatePlanColumns,
  generatePlanRows,
} from '@/components/widgets/AdditionalPackagingModal/tableUtils'
import { Flex } from '@shared/ui/Flex'


export interface AddedPackingItem extends NewPackageDto {
  fact_quantity: string
  additional_packagings?: (AdditionalPackagingsDto & { fact_quantity: string })[]
}

interface Props extends ModalProps {
  allSkus?: ISku[]
  skuPackagings: NewPackageDto[]
  skuPackagingsOptions?: OptionsPackagingPackagigns[]
  onSubmit: (packageData: PackagePostDto[]) => void
  withOverlay?: boolean
  withEmptyPackagings?: boolean
  refetchPackagings?: () => void
}

const AdditionalPackagingV2Modal = (props: Props) => {
  const {
    allSkus,
    withEmptyPackagings = true,
    refetchPackagings,
    skuPackagings,
    onClose,
    onSubmit,
    skuPackagingsOptions
  } = props

  const {
    itemClientOptionsObj,
    allPackagings
  } = useMemo(() => {
    const packagings = skuPackagings || []
    const options = skuPackagingsOptions || []

    let itemClientOptionsObj = {}
    let allPackagings = [...packagings]
    options.forEach((itemOption) => {
      itemClientOptionsObj[itemOption.id] = itemOption
      const packaging = packagings.find(packaging => packaging.id === itemOption.id)
      if (!packaging) {
        const skuPackaging = allSkus?.find(sku => sku.id === itemOption.id)
        if (skuPackaging) {
          const newPackaging: NewPackageDto = {
            id: skuPackaging.id,
            images: skuPackaging.images,
            available: 0,
            title: skuPackaging.title,
            measure: '',
            barcodes: skuPackaging.barcodes.map(barcode => barcode.barcode),
            quantity: 0,
            _sku_client_packaging: true
          }
          allPackagings.push(newPackaging)
        }
      }
    })
    return {
      itemClientOptionsObj,
      allPackagings
    }
  }, [allSkus, skuPackagings])


  const notifications = useNotifications()

  const inputBarcodeRef = useRef<HTMLInputElement | null>(null)

  const [addingPackaging, setAddingPackaging] = useState<NewPackageDto | null>(null)
  const [addedPackaging, setAddedPackaging] = useState<AddedPackingItem[]>([])

  useEffect(() => {
    // перезапрашиваем данные, чтобы они были актуальными
    refetchPackagings?.()
  }, [])


  const {
    value: quantityModalVisible,
    turnOn: showQuantityModal,
    turnOff: hideQuantityModal,
  } = useToggle()

  useEffect(() => {
    if (!quantityModalVisible || !addingPackaging) {
      blurOrFocusInput(inputBarcodeRef, 'focus')
    }
  }, [quantityModalVisible, addingPackaging])


  /** Записываем данные со значением fact_quantity */
  const handleCompleteAddItem = useCallback((newPackaging: AddedPackingItem) => {
    setAddedPackaging(prev => [newPackaging, ...prev])
    blurOrFocusInput(inputBarcodeRef, 'focus')
  }, [setAddedPackaging])

  /** Закрываем модалку ввода кол-ва и сбрасываем добавление item-a */
  const handleCloseQuantityModal = useCallback(() => {
    setAddingPackaging(null)
    hideQuantityModal()
  }, [hideQuantityModal, setAddingPackaging])

  /** Отдаем переданное кол-во и Закрываем модалку ввода кол-ва */
  const handleSubmitItemQuantity = useCallback((packagingItem: NewPackageDto, quantity: number) => {
    handleCompleteAddItem({
      ...packagingItem,
      fact_quantity: `${quantity}`,
      additional_packagings: packagingItem.additional_packagings
        ? packagingItem.additional_packagings.map(item => ({ ...item, fact_quantity: `${item.quantity}` }))
        : undefined,
    })
    setAddingPackaging(null)
    hideQuantityModal()
  }, [hideQuantityModal, handleCompleteAddItem, setAddingPackaging])

  const handleAddItem = useCallback((packaging: NewPackageDto) => {
    if (packaging?.quantity) {
      handleSubmitItemQuantity(packaging, packaging.quantity)
      return
    }
    setAddingPackaging(packaging)
    showQuantityModal()
    blurOrFocusInput(inputBarcodeRef, 'blur')
  }, [setAddingPackaging])

  const handleRemoveAddedItem = useCallback((packagingIndex: number) => {
    setAddedPackaging(prev => prev.filter((pack, index) => index !== packagingIndex))
  }, [setAddedPackaging])

  const getAddedItemQuantity = (packagingId: string) => {
    /* получаем уже добавленные значения упак материалов. */
    return addedPackaging
      .filter(addedPack => addedPack.id === packagingId)
      .reduce((acc, item) => acc + Number(item.fact_quantity ?? item.quantity), 0)
  }

  const handleScanItem = useCallback((barcode: string) => {
    const addingPackaging = (allPackagings || []).find(pack => pack.barcodes.includes(barcode))
    if (!addingPackaging) {
      notifications?.show('alert', 'Упаковочный материал не найден')
      return
    }
    if (addingPackaging._sku_client_packaging) {
      notifications?.show('alert', `Упаковочный материал ${addingPackaging.title} заблокирован`)
      return
    }

    if (((addingPackaging.available || 0) - getAddedItemQuantity(addingPackaging.id)) <= 0) {
      notifications?.show('alert', `Упаковочный материал ${addingPackaging.title} не имеет остатков`)
      return
    }
    handleAddItem(addingPackaging)
  }, [handleAddItem, skuPackagings])

  const handleEditQuantityItem = useCallback((packagingId: string, editIndex: number, quantity: string) => {
    setAddedPackaging(prev => {
      const currentItem = prev.reduce((acc, pack, index) => {
        if (pack.id === packagingId && index === editIndex) {
          return pack
        }
        const additionalPackaging = pack.additional_packagings.find((additionalPack, additionalIndex) => (
          additionalPack.id === packagingId
          && additionalIndex === editIndex
        ))
        if (additionalPackaging) {
          return additionalPackaging
        }
        return acc
      }, null)
      currentItem.fact_quantity = quantity
      return [...prev]
    })
  }, [setAddedPackaging])

  /* К обработке */
  const { rows, columns } = useMemo(() => ({
    rows: generatePlanRows(allPackagings, getAddedItemQuantity, itemClientOptionsObj),
    columns: generatePlanColumns(handleAddItem),
  }), [allPackagings, itemClientOptionsObj, addedPackaging, handleAddItem, getAddedItemQuantity])


  const addedRows = useMemo(
    () => generateAddedItemsRows(addedPackaging, handleEditQuantityItem),
    [addedPackaging, handleEditQuantityItem],
  )

  const addedColumns: ITableColumn[] = useMemo(
    () => generateAddedItemsColumns(handleRemoveAddedItem),
    [handleRemoveAddedItem],
  )

  const handeSubmitData = () => {
    const data: PackagePostDto[] = addedPackaging.reduce((acc, item) => {
      acc.push({ id: item.id, quantity: Number(item.fact_quantity) })
      if (item.has_additional_packagings && item.additional_packagings?.length) {
        item.additional_packagings.forEach(additionalItem => {
          acc.push({ id: additionalItem.id, quantity: Number(additionalItem.fact_quantity) })
        })
      }
      return acc
    }, [] as PackagePostDto[])
    onSubmit(data)
  }

  const clientPackagignsLen = Object.keys(itemClientOptionsObj)?.length

  return (
    <>
      <Modal
        isOpen={true}
        onOverlayClick={(): boolean => false}
        className={styles.additionalPack}
        size={'m-plus'}
        hasOverlay={true}
        withClose
        onClose={onClose}
        title={'Выбор упаковки'}
      >
        <Grid
          gap={'xl'}
          cols={6}
        >
          <GridItem col={6}>
            <InputWrapper
              inputRef={inputBarcodeRef}
              withDelay
              label={'Штрихкод упаковочного материала'}
              id={'packBarcode'}
              placeholder={'Отсканируйте штрихкод'}
              handleChange={handleScanItem}
              autoFocus
              size='s'
              value={null}
            />
          </GridItem>
          <GridItem col={6}>
            <TableWithCopy
              withCopy={false}
              title={'К обработке'}
              rows={rows}
              columns={columns}
              maxHeightBody={260}
            />
          </GridItem>
          <GridItem col={6} />
          <GridItem col={6}>
            <TableWithCopy
              withCopy={false}
              title={'Добавленные упаковочные материалы'}
              emptyPlaceholder={'Без упаковки'}
              rows={addedRows}
              columns={addedColumns}
              maxHeightBody={400}
            />
          </GridItem>

          <GridItem col={6}>
            <Flex justifyContent={'end'}>
              <Button
                label={'Сохранить'}
                disabled={
                Boolean(!addedPackaging?.length && clientPackagignsLen)
                || Boolean(!addedPackaging?.length && !withEmptyPackagings)
                }
                onClick={handeSubmitData}
              />
            </Flex>
          </GridItem>
        </Grid>
      </Modal>

      {
        quantityModalVisible && addingPackaging ? (
          <QuantityModal
            addingPackaging={addingPackaging}
            onCloseModal={handleCloseQuantityModal}
            onSubmitModal={handleSubmitItemQuantity}
          />
        ) : null
      }
    </>
  )
}

type QuantityModalProps = {
  addingPackaging: NewPackageDto
  onCloseModal: () => void
  onSubmitModal: (packagingItem: NewPackageDto, quantity: number) => void
}

const QuantityModal = (props: QuantityModalProps) => {
  const {
    addingPackaging,
    onCloseModal,
    onSubmitModal,
  } = props

  const [quantity, setQuantity] = useState('')

  const valueIsValid = (!isNaN(+quantity) || (Number(quantity) > 0)) && quantity
  const handleClickSubmit = () => {
    if (!valueIsValid) return
    onSubmitModal(addingPackaging, Number(quantity))
  }

  return (
    <EnterHandler onEnter={handleClickSubmit}>
      <Modal
        isOpen={true}
        onOverlayClick={(): boolean => false}
        className={styles.additionalPack}
        size='s'
        withClose
        onClose={onCloseModal}
        title={'Количество материала'}
      >
        <Grid cols={8} gap={'m'}>
          <GridItem col={8} />
          <GridItem col={8}>
            <InputWrapper
              isNumber
              label={''}
              id={'packBarcode'}
              placeholder={'Введите кол-во материала'}
              handleChange={setQuantity}
              autoFocus
              size='s'
              value={quantity}
              rightSide={addingPackaging.measure}
            />
          </GridItem>

          <GridItem col={8} />

          <GridItem col={2}>
            <Button
              width={'full'}
              view={'ghost'}
              label={'Отмена'}
              onClick={onCloseModal}
            />
          </GridItem>
          <GridItem colStart={7} col={2}>
            <Button
              width={'full'}
              view={'primary'}
              label={'Принять'}
              disabled={!valueIsValid}
              onClick={handleClickSubmit}
            />
          </GridItem>
        </Grid>
      </Modal>
    </EnterHandler>
  )
}

export default AdditionalPackagingV2Modal
