import React from 'react'
import { Text } from '@shared/ui/Text'
import { Flex } from '@shared/ui/Flex'
import { Button } from '@shared/ui/btns/Button'


interface EditFormHeaderProps {
  isEdit: boolean
	title: string
  onStartEditClick: () => void
	onCancelClick: () => void
	onSaveClick: () => void
}

export const EditFormHeader = (props: EditFormHeaderProps) => {
  const {
    title,
    isEdit,
    onCancelClick,
    onSaveClick,
    onStartEditClick
  } = props
	return (
		<Flex justifyContent={'between'} fullWidth>
      <Text as={'h2'} size={'l'} children={title}/>
      <Flex gap={'m'}>
        {
          isEdit ? (
            <>
              <Button
                size={'s'}
                label={'Отменить'}
                view={'ghost'}
                onClick={onCancelClick}
              />
              <Button
                size={'s'}
                label={'Сохранить'}
                onClick={onSaveClick}
              />
            </>
          ) : (
             <Button
              size={'s'}
              label={'Редактировать'}
              onClick={onStartEditClick}
            />
          )
        }
      </Flex>
    </Flex>
	);
};
