import React, { FC, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { IRouteParams } from '@pages/DirectoriesPage/types'
import { useFilterState } from '@shared/hooks'
import { SECTIONS } from '../../../../constants'
import { GeneratorFields } from '@widgets/GeneratorFields'
import { IInstruction } from '@/interfaces/instructions'
import styles from './list.module.scss'
import { useGetInstructionsList } from '@/hooks/warehouse/instructions'

import { generateFilterParams, generateFilters } from './utils/filters'
import { generateTableData } from './utils/table'
import DirectoriesTable from '../../../../components/DirectoriesTable/DirectoriesTable'

interface ListProps {}

const List: FC<ListProps> = () => {
  const { page = '', section = SECTIONS.instructions.value } =
    useParams<IRouteParams>()

  const {
    filterStateDebounced,
    filterState,
    handleChangeFilter
  } = useFilterState({withoutHistory: true, onChangeFilterState: () => {
    setPagination(0)
  }})
  const [instructions, setInstructions] = useState<IInstruction[]>([])
  const [pagination, setPagination] = useState(0)
  const {
    isFetching: isFetchingInstructions,
    refetch: refetchInstructions,
    data: instructionsData,
  } = useGetInstructionsList({
    params: generateFilterParams({ ...filterState, page: pagination } || {}),
  })
  useEffect(() => {
    if (isFetchingInstructions) {
      return
    }

    refetchInstructions()
  }, [filterStateDebounced, pagination])

  useEffect(() => {
    if (!instructionsData) {
      return
    }

    setInstructions(instructionsData.instructions)
  }, [instructionsData])

  const filtersConfig = generateFilters()

  const { rows, columns } = useMemo(() => {
    return generateTableData(instructions, page, section)
  }, [instructions])

  return (
    <>
      <GeneratorFields
        wrapperClassName={styles.filterBlock}
        visible
        fieldsConfig={filtersConfig}
        valuesState={filterState || {}}
        onChangeField={handleChangeFilter}
      />

      <DirectoriesTable
        withCopy
        isLoading={isFetchingInstructions}
        columns={columns}
        rows={rows}
        pagination={instructionsData?.pagination}
        onChangePage={setPagination}
      />
    </>
  )
}

export default List
