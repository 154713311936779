import type { FC } from 'react'
import React, { useState } from 'react'

import { IUserCompanyRow } from './types'

import type { ISection } from '../../types'
import { useFilterState, useToggle } from '@shared/hooks'
import { IPaginationFilter } from '@/interfaces'
import useGetUserCompanyList from '../../../../entities/UserCompany/lib/api/useGetUserCompanyList'
import { EditCreateUserCompanyModal, useDeleteUserCompany, UserCompanyDto } from '@entities/UserCompany'
import { EditRemoveButtons } from '@pages/DirectoriesPage/components/EditRemoveButtons/EditRemoveButtons'
import { DirectoriesLayout } from '@pages/DirectoriesPage/components/DirectoriesLayout/DirectoriesLayout'
import { TableColumnType } from '@/components/Table/TanStackTable'

const UserCompanies: FC<ISection> = (props) => {
  const {
    className = '',
    title = '',
  } = props

   const {
    filterStateDebounced,
    filterState,
    handleChangeFilter
  }  = useFilterState({withoutHistory: true, onChangeFilterState: () => {
    setUsersParams(prev => ({ ...prev, page: undefined }))
  }})


  const {
    value: isCreateEditModalVisible,
    turnOn: showCreateEditModal,
    turnOff: hideCreateEditModal,
  } = useToggle()

  const [userCompanyEdit, setUserCompanyEdit] = useState<UserCompanyDto | null>(null)

  const [usersCompanies, setUsersCompanies] = useState<IUserCompanyRow[]>([])

  const [usersParams, setUsersParams] = useState<IPaginationFilter>({})

  const {
    data,
    isLoading: usersIsLoading,
    refetch: refetchUsersData,
  } = useGetUserCompanyList({ search: filterStateDebounced?.search, ...usersParams }, {
    onSuccess: (data) => {
      if (!data?.success) return
      setUsersCompanies(data.companies)
    },
  })

  const {
    mutateAsync: deleteUserCompany,
    isLoading: isUserCompanyDelete,
  } = useDeleteUserCompany()

  const onClickEdit = (company: UserCompanyDto) => {
    setUserCompanyEdit(company)
    showCreateEditModal()
  }
  const onClickDelete = async (companyId: string) => {
    await deleteUserCompany({companyId}).catch(console.error)
    await refetchUsersData()
  }

  const columns: TableColumnType[] = [
    {
      header: 'Название',
      id: 'title',
      accessorKey: 'title',
    },
    {
      header: '',
      id: 'buttonEl__action',
      accessorKey: 'buttonEl__action',
      size: 150,
      cell: ({ row }) => {
        return (
          <EditRemoveButtons
            withEdit
            withRemove
            onEditClick={() => onClickEdit({ id: row.original.id, title: row.original.title })}
            onRemoveClick={() => onClickDelete(row.original.id)}
            isLoading={isUserCompanyDelete}
          />
        )
      },
    },
  ]

  return (
    <DirectoriesLayout
      withTable
      withCreate
      withFilter
      withSearch

      title={title}

      filterState={filterState}
      handleChangeFilter={handleChangeFilter}

      onCreateBtnClick={() => {
        showCreateEditModal()
        setUserCompanyEdit(null)
      }}

      isLoading={usersIsLoading}
      columns={columns}
      rows={usersCompanies}
      pagination={data?.[0]?.pagination}
      onChangePage={(page) => setUsersParams({ page })}
    >

      {
        isCreateEditModalVisible ? (
          <EditCreateUserCompanyModal
            company={userCompanyEdit}
            onCancel={() => {
              hideCreateEditModal()
              setUserCompanyEdit(null)
            }}
            onSubmit={() => {
              refetchUsersData()
              hideCreateEditModal()
            }}
          />
        ) : null
      }
    </DirectoriesLayout>
  )
}

UserCompanies.displayName = 'UsersSection'

export default UserCompanies
