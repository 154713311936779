import type { FC, ReactNode } from 'react'
import React from 'react'
import { TextFieldPropValue } from '@consta/uikit/TextField'

import { InputWrapper } from '@/components'

import styles from './zone-part.module.scss'

import { Text } from '@shared/ui/Text'
import { Flex } from '@shared/ui/Flex'

interface IZoneCreate {
  iconNode?: ReactNode
  title: string
  total: number
  valueFrom: TextFieldPropValue
  valueTo: TextFieldPropValue
  fromError?: string
  toError?: string
  onFromChange: (value: TextFieldPropValue) => void
  onToChange: (value: TextFieldPropValue) => void
  onClearFrom: () => void
  onClearTo: () => void
}

/** Контент попа редактирования зоны */
const ZonePart: FC<IZoneCreate> = ({
  iconNode,
  title,
  total,
  valueFrom,
  valueTo,
  fromError = '',
  toError = '',
  onFromChange,
  onToChange,
  onClearFrom,
  onClearTo,
}) => {
  return (
    <Flex
      gap={'xs'}
      direction={'column'}
    >
      <Flex
        align={'center'}
        justifyContent={'between'}
      >
        <Flex
          gap={'xs'}
          align={'center'}
        >
          {!!iconNode && iconNode}
          <Text
            size={'s'}
            as={'span'}
            view={'secondary'}
            children={title}
          />
        </Flex>

        <Text
          size={'s'}
          as={'span'}
          view={'secondary'}
          children={`Всего: ${!isNaN(total) ? total : '-'}`}
        />
      </Flex>

      <Flex
        gap={'xs'}
        direction={'column'}
      >
        <Text
          size={'xs'}
          view={'secondary'}
          children={'Номера, от и до'}
        />
        <Flex className={styles.inputsRow}>
          <InputWrapper
            size={'s'}
            className={styles.input}
            leftSide={'от'}
            value={valueFrom}
            form={'defaultBrick'}
            error={fromError}
            handleChange={(value) => onFromChange(value)}
          />

          <InputWrapper
            size={'s'}
            className={styles.input}
            leftSide={'до'}
            value={valueTo}
            form={'clearDefault'}
            error={toError}
            handleChange={(value) => onToChange(value)}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

ZonePart.displayName = 'ZonePart'

export default ZonePart
