import {
  IPhysicalSetOrderPlaceDto,
  IPhysicalSetOrderPlaceItemDto,
  IPutPhysicalSetOrderPlaceInnerDto,
  IPutPhysicalSetOrderPlaceInnerItemDto,
} from '@/interfaces'

export const convertServerPlaceItemToDraft = (placeItemRaw: IPhysicalSetOrderPlaceItemDto): IPutPhysicalSetOrderPlaceInnerItemDto => {
  return {
    sku_id: placeItemRaw.id,
    ...placeItemRaw
  }
}

export const convertServerPlaceToDraft = (placeRaw: IPhysicalSetOrderPlaceDto): IPutPhysicalSetOrderPlaceInnerDto => {
  const { items, ...restParams } = placeRaw
  return {
    items: items.map(convertServerPlaceItemToDraft),
    ...restParams
  }
}