import { ComboboxItemDefault } from '@consta/uikit/Combobox'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import {
  ApiReqResult,
  CommonSerialNumberFilled,
  ConditionEnum,
  ICommonDocument,
  IFile,
  IPaginationFilter,
  ISelectOption,
  ISku,
  IUserData,
} from './index'
import { ValueOf } from '@shared/helpers'

export interface IContragent extends ISelectOption {
  subtitle: string
  id: number
  disabled?: boolean
}

export interface ICombobox extends ISelectOption {
  id: number | string
  disabled?: boolean
}

export interface IAcceptanceFilterDate {
  from: Date | null
  to: Date | null
}

export interface IPackType {
  id: string
  title: string
  quantity: number
}

export interface IBatch {
  id: string
  exp_date?: string
  num?: string
}

export interface IMerchant {
  id: string
  title: string
  trade_title: string
  internal_title?: string
  sorting_number?: string
  contract_num?: string
  contract_date?: string
  cam_id?: string
  dlv_login?: string
  enabled?: boolean
  merlion_storage?: boolean
  inn?: string
  kpp?: string
  login?: string
  ogrn?: string
  service_group_set_id?: string
}

export interface IProperties {
  compare_type: string
  dimensions_sum: number
  weight: number
}

export interface ISupplier {
  id: string
  title: string
}

export type IAcceptanceSkuObj = Record<string, IAcceptanceSku>

export interface IAcceptanceSku extends ISku {
  /** Кол-во товара */
  quantity: number
  /** Выбранная партия в модалке */
  batch?: IBatch
  /**
   * Фронтовый флаг, которые говорит, какие серийники надо сканировать
   */
  needScanSerialCodes?: string[]
  /**
   * Просканированные серийные номера
   *  - Добавляется на фронте,
   *  - Нужен для заполнения серийными номерами при добавлении товара в тару,
   *    если есть задание на сканир. серийных номеров
   */
  scanned_serial_numbers?: CommonSerialNumberFilled[]
  /** Используемый ШК товара при добавлении
   *   - Добавляется на фронте для вспомогательных функций
   *   (например для preview товара в модалке серийных номеров)
   * */
  barcode_used?: string
  /**
   * Id дефекта для бракованного товара
   */
  defect_type_id?: string
}

export const ISupplyConditionDescription: Record<string, string> = {
  [ConditionEnum.GOOD]: 'Годный',
  [ConditionEnum.DEFECT]: 'Брак',
}

export const SupplyStatusEnum = {
  entered: 'ENTERED',
  arrived: 'ARRIVED',
  processing: 'PROCESSING',
  avaitingPlacing: 'AWAITING_PLACING',
  placed: 'PLACED',
  done: 'DONE',
  error: 'ERROR',
} as const
export type SupplyStatusEnum = ValueOf<typeof SupplyStatusEnum>

// Элемент анкеты
export type SupplyFormItem = {
  id: string,
  title: string,
  value: string,
  comment: string
}

export type TrustBoxItem = {
  quantity: number,
  sku_id: string
  sku_batch_id?: string
  id?: string
  multiplicity?: number | null
  acceptance_type?: AcceptanceType
  barcode_used?: string
  serial_numbers?: string[]
}

export type TrustBoxObj = {
  [key: string]: TrustBoxItem[]
}
export type TrustBox = {
  barcode: string,
  items: TrustBoxItem[]
}

export interface ISupply {
  /** Идентификатор UUID документа */
  uuid?: string
  /** Флаг активности (false для удаленных документов) */
  activated?: boolean
  /** Комментарий */
  comment?: string
  /** Время создания */
  create_ts: string
  /** Время документа */
  date: string
  /** Идентификатор из внешней системы */
  external_id?: string
  /** Доп. файлы */
  files: IFile[]
  /** Идентификатор */
  id: string
  /** Позиции документа */
  items: ISupplyItem[]
  /** Организация */
  merchant?: IMerchant
  /** Части приёмки */
  receipts: ISupplyReceipt[]
  /** Время начала приёмки */
  receipt_started_at: string
  /** Статус */
  status: SupplyStatusEnum
  /** Комментарий склада */
  warehouse_comment?: string
  /** Поставщик */
  supplier?: ISupplier
  /** Время последнего обновления */
  update_ts: string
  /** Определяет есть ли расхождения с планом */
  has_diffs?: boolean
  supply_type: SupplyTypEnum
  supply_form?: SupplyFormItem[]
  accompanying_doc_num?: string
  /*Поле доверительной приемки*/
  trust_acceptance_boxes: TrustBox[]
}

export enum SupplyTypEnum {
  DEFAULT = "DEFAULT",
  FROM_DISTRIBUTION_CENTER = "FROM_DISTRIBUTION_CENTER",
  FROM_SUPPLIER = "FROM_SUPPLIER",
  FROM_SUPPLIER_WITH_DRIVER = "FROM_SUPPLIER_WITH_DRIVER",
}

export interface ISupplyReceiptData {
  tare_barcode: string
  condition: ConditionEnum
  started_at: string
}

export const ReceiptStatusEnum = {
  open: 'OPEN',
  closed: 'CLOSED',
  done: 'DONE',
} as const
export type ReceiptStatusEnum = ValueOf<typeof ReceiptStatusEnum>
// переименовал enum к более точному значению
export { ReceiptStatusEnum as TareStatusEnum }

export const TaskTypeEnum = {
  disassembly: 'DISASSEMBLING',
  assembling: 'ASSEMBLING',
} as const
export type TaskTypeEnum = ValueOf<typeof TaskTypeEnum>

export const DeliveryStatusEnum = {
  pickup: 'PICKUP',
  drop_off: 'DROP_OFF',
} as const
export type DeliveryStatusEnum = ValueOf<typeof DeliveryStatusEnum>

export const InventoryStatusEnum: Record<string, string> = {
  scheduled: 'SCHEDULED',
  preparation: 'PREPARATION',
  processing: 'PROCESSING',
  done: 'DONE',
} as const
export type InventoryStatusEnum = ValueOf<typeof InventoryStatusEnum>

export interface ISupplyReceipt extends Partial<Omit<ICommonDocument, 'executor'>> {
  /** Состояние товара */
  condition: ConditionEnum
  /** Время создания */
  created_ts: string
  /** Позиции документа */
  items: ISupplyReceiptItem[]
  /** Статус */
  status: ReceiptStatusEnum
  /** ШК тары */
  tare_barcode: string
  multiplicity?: number
  executor?: Partial<IUserData>
}
export interface IServicesDocuments {
  activated: boolean

  comment?: string

  create_ts: string

  date: string

  executor?: IUserData

  external_id: string

  id: string

  items: IServiceDocumentsItem[]

  merchant?: IMerchant

  merchant_title?: string

  status?: ReceiptStatusEnum

  update_ts?: string

  uuid?: string

  warehouse_comment?: string
}
export interface IServices {
  active: boolean
  code: number
  group_id: string
  id: string
  measure: string
  is_material: boolean
  position: number
  properties: IProperties
  title: string
}
export interface IServiceDocumentsItem {
  id: string

  quantity: number

  service_id: string
}

export enum PriceVATEnum {
  NO_VAT = 'NO_VAT',
  VAT_0 = 'VAT_0',
  VAT_10 = 'VAT_10',
  VAT_18 = 'VAT_18',
  VAT_20 = 'VAT_20',
}
export interface PriceData {
  /** Валюта */
  currency: string
  /** Цена */
  price: number
  /** Тип НДС */
  vat: PriceVATEnum
}

export type AcceptanceType = 'PIECE' | 'BOX' | 'PALLET'

export interface ISupplyReceiptItem {
  comment?: string
  id: string
  quantity: number
  serial_numbers: CommonSerialNumberFilled[]
  sku_batch_id: string | null
  sku_id: string
  acceptance_type?: AcceptanceType
  defect_type_id?: string
  /**
   * Фронтовый флаг излишека
   * Показывает, является ли товар излишком, или нет
   */
  isSurplus?: boolean
  /**
   * Используемый ШК товара при добавлении
   * - Добавляется на фронте для вспомогательных функций
   */
  barcode_used?: string
  multiplicity?: number | null
  trust_acceptance_box_barcode?: string
}

export interface ISupplyReceiptItemRequest
  extends Omit<ISupplyReceiptItem, 'id'> {}

export interface ISupplyItem {
  /** Идентификатор позиции документа*/
  id: string
  /** Идентификатор привязанного заказа */
  order_id?: string
  /** Данные о цене */
  price_data?: PriceData
  /** Список, определяющий какие серийные номера надо сканировать */
  scan_serial_numbers: string[]
  /** Кол-во товара */
  quantity: number
  /** Идентификатор партии товара */
  sku_batch_id: string
  /** Идентификатор товара */
  sku_id: string
  /** Кратность упаковки*/
  multiplicity?: number | string

  __front__trust_acceptance_box_barcode?: string
}

/* Старое todo надо удалить */
export interface IAcceptanceFormState {
  items: ISupplyItem[]
  originalSkus: IAcceptanceSku[]
  indicateItemCount: boolean
  startFormDate: string
  addingItem: IAcceptanceSku | null
  curTareId: TextFieldPropValue
  tares: ISupplyReceipt[]
  supplyIsStart: boolean

  showSelectBatch: boolean
  showDiscrepancies: boolean
  showDeleteConfirm: boolean
  showSearchItemsModal: boolean
  showPrintError: boolean
  sticker: {
    content: string
    printer: string
  } | null
}

export enum AcceptanceFormActionTypes {
  setItems = `setItems`,
  setCacheItems = `setCacheItems`,
  setIndicateItemCount = 'setIndicateItemCount',
  setStartFormDate = 'setStartFormDate',
  setAddingItem = 'setAddingItem',
  setCurItem = 'setCurItem',
  setCurTareId = 'setCurTareId',
  setTares = 'setTares',
  setShowSelectBatch = 'setShowSelectBatch',
  setShowDiscrepancies = 'setShowDiscrepancies',
  setStartSupply = 'setStartSupply',
  setShowScanSerial = 'setShowScanSerial',
  setSavedChz = 'setSavedChz',
  setshowDeleteTareConfirmModal = 'setshowDeleteTareConfirmModal',
  setShowSearchItemsModal = 'setShowSearchItemsModal',
  setShowPrintError = 'setShowPrintError',
  setSticker = 'setSticker',
}
export interface getAcceptanceOptionsResult extends ApiReqResult {
  type: string
  options: ISelectOption[] | []
}

export interface ComboboxItem extends ComboboxItemDefault {
  subtitle?: string
  value?: string
}

export interface IAcceptanceFilterOptions {
  suppliers: ICombobox[] | []
  contragents: IContragent[] | []
  warehouses: ICombobox[] | []
}

export interface IGetSupplyReqObj extends IPaginationFilter {
  search?: string
  from?: string
  to?: string
  merchant_ids?: string[]
  supplier_ids?: string[]
  plan_sku_ids?: string[]
  fact_sku_ids?: string[]
  status?: string
  sorting_required?: boolean
}

export interface IOptionsLoading {
  suppliers: boolean
  contragents: boolean
  warehouses: boolean
}
