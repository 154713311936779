import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import type { ISection } from '../../types'
import { PopupZoneType } from '../../types'
import {
  CheckboxStateType,
  IChangedSettings,
  ICheckbox,
  ICheckboxes,
  ILvlItem,
  IZoneLvlCounts,
  IZoneTree,
  LevelType,
  ModalType,
  SubmitCellSettingsProps,
  SubmitLevelSettingsProps,
} from './types'

import { CellTypesEnum, ConditionEnum, ICellPlace } from '@/interfaces'

import {
  IGetCellStickersResponse,
  useCreateZone,
  useDeleteCells,
  useDeleteZone,
  useGenerateCells,
  useGetCellStickers,
  useGetZoneSettings,
  useUpdateCells,
  useUpdateZone,
} from '@/hooks'
import { usePreviewBase64Pdf, usePrint } from '@shared/hooks'

import { Button } from '@shared/ui/btns/Button'
import { IconAdd } from '@consta/icons/IconAdd'
import { IconEdit } from '@consta/icons/IconEdit'
import { IconLock } from '@consta/icons/IconLock'
import { Checkbox } from '@consta/uikit/Checkbox'
import { Modal } from '@consta/uikit/Modal'
import ZoneCreate from '../../components/ZoneCreate/ZoneCreate'
import LevelSettings from '../../components/LevelSettings/LevelSettings'
import SectionTitle from '../../components/SectionTitle/SectionTitle'
import Collapse from '@/components/Collapse'

import { lvlShift, titleSeparator } from './constants'

import cx from 'classnames'
import styles from './zone-section.module.scss'
import { ITypeCondition } from '../../components/LevelSettings/types'
import { LevelCard } from './components/LevelCard/LevelCard'
import { Loader } from '@consta/uikit/Loader'
import { v4 } from 'uuid'
import { IconFolders } from '@consta/icons/IconFolders'
import { Flex } from '@shared/ui/Flex'
import { Text } from '@shared/ui/Text'
import { IconBox } from '@shared/assets/icons/IconBox'
import { IconPallet } from '@shared/assets/icons/IconPallet'

export type CheckedCellType = {
  [key: string]: { [key: string]: ICellPlace }
}


const ID_SEPARATOR = '__'
const getItemLvlId = (title: string, index: string | number) => `${title}${ID_SEPARATOR}${index}`

/**
 * Страница настройки зон
 */
const ZonesSection: FC<ISection> = (props) => {
  const {
    className = '',
    title = '',
  } = props

  const [checkboxes, setCheckboxes] = useState<ICheckboxes>({})
  const [cellsTree, setCellsTree] = useState<IZoneTree[] | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState<ModalType>('')
  const [editingItem, setEditingItem] = useState<ICellPlace | ICheckbox | null>(
    null,
  )
  const [levelModalItems, setLevelModalItems] = useState<
    null | ITypeCondition[]
  >(null)

  const [editZoneData, setEditZoneData] = useState<any>({})
  const [checkedCells, setCheckedCells] = useState<CheckedCellType>({})

  const { mutate: mutateCells } = useUpdateCells()
  const { mutate: createZone } = useCreateZone()
  const { mutate: deleteZone } = useDeleteZone()
  const { mutate: generateCells } =
    useGenerateCells()
  const { mutate: deleteCells } = useDeleteCells()
  const { mutate: getCellStickers, isLoading: isLoadingStickers } =
    useGetCellStickers()
  const { mutate: updateZone } = useUpdateZone()


  const {
    isLoading: isLoadingZoneData,
    data: zoneData,
    refetch,
    isSuccess: isZoneDataSuccess,
    isFetching: isZoneDataFetching,
  } = useGetZoneSettings({})

  const data = useMemo(() => zoneData?.zones || [], [zoneData?.zones])


  const { printSticker } = usePrint({})
  const { previewBase64Pdf } = usePreviewBase64Pdf()

  const handleCheck = useCallback((
    prevState: ICheckboxes,
    __special_id: string,
    checkState: CheckboxStateType,
  ) => {
    const current = checkboxes[__special_id]
    return {
      ...prevState,
      [__special_id]: {
        ...current,
        state: checkState,
        childrenChecked: !!checkState ? current.childrenNum : 0,
      },
    }
  }, [checkboxes])

  const handleDeleteCells = (zoneId: string) => {
    const cellBarcodes: string[] = Object.keys(checkedCells[zoneId])

    if (!cellBarcodes.length) {
      return
    }

    deleteCells(cellBarcodes, { onSuccess: () => refetch() })
  }

  const handleDeleteZone = (zoneId: string) => {
    const isConfirmed = window.confirm('Подтвердите удаление')

    if (!isConfirmed) {
      return
    }

    deleteZone(zoneId, { onSuccess: () => refetch() })
  }

  const handleBlockCells = (zoneId: string, actionType: 'block' | 'unblock') => {
    const cells = (
      Object.values(checkedCells[zoneId])
        .map((cell) => ({ ...cell, is_blocked: actionType === 'block' }))
    )

    if (!cells.length) {
      return
    }

    mutateCells(cells, { onSuccess: () => refetch() })
  }

  const handlePrintStickers = useCallback((
    zoneId: string,
    cellId?: string,
    printType?: string,
  ) => {
    const cellBarcodes = cellId ? [cellId] : Object.keys(checkedCells[zoneId])

    getCellStickers(cellBarcodes, {
      onSuccess: async (data: IGetCellStickersResponse) => {
        const { stickers } = data

        if (!stickers || !stickers.length) {
          return
        }

        for (let i = 0; i < stickers.length; i++) {
          const sticker = stickers[i]
          if (printType === 'preview') {
            previewBase64Pdf(sticker.content)
            continue
          }

          await printSticker(sticker.printer, sticker.content)
        }
      },
    })
  }, [checkedCells, getCellStickers, previewBase64Pdf, printSticker])

  const setParentBlock = useCallback((parent: ICheckbox, allCheckboxes: ICheckboxes) => {
    parent.childrenBlocked++
    parent.is_blocked = parent.childrenNum === parent.childrenBlocked

    if (parent.is_blocked && !!parent.__parent_special_id) {
      setParentBlock(allCheckboxes[parent.__parent_special_id], allCheckboxes)
    }
  }, [])

  const setParentAccess = useCallback((
    parent: ICheckbox,
    allCheckboxes: ICheckboxes,
    accessLevel: string | number,
  ) => {
    parent.differentAccess.add(accessLevel)

    if (parent.differentAccess.size > 1) {
      parent.access_level = '-'
    } else {
      parent.access_level = parent.differentAccess.values().next().value
    }

    if (parent.__parent_special_id) {
      setParentAccess(
        allCheckboxes[parent.__parent_special_id],
        allCheckboxes,
        accessLevel,
      )
    }
  }, [])

  /** Создаёт объект ICheckboxes для стейта чекбоксов */
  const createCheckboxes = useCallback((tree: IZoneTree[]): ICheckboxes => {
    let allCheckboxes: ICheckboxes = {}
    const getTitles = (
      item: ILvlItem | ICellPlace,
      zoneId: string,
      __parent_special_id: string = '',
      itemIndex: string = '',
    ) => {
      let childrenNum = 0
      let levelType = LevelType.cell

      if ((item as ILvlItem).items) {
        childrenNum = (item as ILvlItem).items.length
        levelType = LevelType.level
      }
      allCheckboxes[item.__special_id] = {
        barcode: (item as ICellPlace)?.barcode || '',
        title: item.title,
        state: CheckboxStateType.unchecked,
        levelType,
        childrenChecked: 0,
        childrenBlocked: 0,
        childrenNum,
        __parent_special_id: __parent_special_id,
        is_blocked: !!item?.is_blocked,
        access_level: item?.access_level || '0',
        differentAccess: new Set(),
        zone_id: zoneId,
      }

      if (item.is_blocked) {
        setParentBlock(allCheckboxes[__parent_special_id], allCheckboxes)
      }

      if (item.access_level || item?.access_level === 0) {
        setParentAccess(
          allCheckboxes[item.__special_id],
          allCheckboxes,
          item.access_level,
        )
      }
      if (!(item as ICellPlace).type) {
        ;(item as ILvlItem).items.forEach((childItem: ILvlItem | ICellPlace, childIndex) =>
          getTitles(childItem, zoneId, item.__special_id, `${childIndex}`),
        )
      }
    }

    tree?.forEach((zone, i) => {
      zone.items.forEach((item) => getTitles(item, zone.id, '', `${i}`))
    })

    return allCheckboxes
  }, [setParentAccess, setParentBlock])

  useEffect(() => {
    if (!data?.length) {
      return
    }

    function addIdToTreeItems(data: any[]): any[] {
      return data.map((item) => {
        const newItem = { ...item, __special_id: v4() }
        if (!newItem.items) return newItem
        return ({
          ...newItem, items: addIdToTreeItems(newItem.items),
        })
      })
    }


    const treeData = addIdToTreeItems(data || [])
    setCellsTree(treeData)
    setCheckboxes(createCheckboxes(treeData))
    setCheckedCells({})
  }, [createCheckboxes, data])

  const openModal = (modalType: ModalType) => {
    setModalContent(modalType)
    setIsModalOpen(true)
  }

  /** Массовое редактирование ячеек */
  const modifyCells = (cells: ICellPlace[], changes: IChangedSettings) => {
    const { cellType, accessLevel, isBlocked, condition } = changes
    const modifiedCells = cells.map((cell) => ({
      ...cell,
      is_blocked: isBlocked,
      condition: condition as ConditionEnum,
      type: cellType as CellTypesEnum,
      access_level: +accessLevel,
    }))
    mutateCells(modifiedCells, { onSuccess: () => refetch() })
  }

  const onSubmitCreateZone = (
    event: React.SyntheticEvent,
    zoneData: any,
    cellsData: any,
  ) => {
    event.preventDefault()
    createZone(zoneData, {
      onSuccess: (data) => {
        cellsData.zone_id = data?.data?.id
        generateCells(cellsData, { onSuccess: () => refetch() })
      },
    })
    setIsModalOpen(false)
  }

  const onUpdateZone = (zoneData: any) => {
    updateZone(zoneData, { onSuccess: () => refetch() })
    setIsModalOpen(false)
  }

  const onGenerateCells = (cellsData: any) => {
    generateCells(cellsData, { onSuccess: () => refetch() })
    setIsModalOpen(false)
  }

  const onSubmitCellSettings = (args: SubmitCellSettingsProps) => {
    const {
      event,
      changes,
      cell,
    } = args
    event.preventDefault()
    modifyCells([cell], changes)
    setIsModalOpen(false)
  }

  const onSubmitLevelSettings = (args: SubmitLevelSettingsProps) => {
    const {
      event,
      changes,
      levelTitle,
      levelZoneId,
    } = args
    event.preventDefault()
    const cells = getCells(levelTitle, levelZoneId).filter(item => item.barcode)
    modifyCells(cells, changes)
    setIsModalOpen(false)
  }

  const renderModalContent = () => {
    switch (modalContent) {
      case 'createZone':
        return (
          <ZoneCreate
            onClose={() => setIsModalOpen(false)}
            onSubmit={onSubmitCreateZone}
          />
        )

      case 'editZone':
        return (
          <ZoneCreate
            popupType={PopupZoneType.edit}
            zoneData={editZoneData}
            onClose={() => setIsModalOpen(false)}
            onSubmit={onSubmitCreateZone}
            onUpdate={onUpdateZone}
            onGenerateCells={onGenerateCells}
          />
        )

      case 'cellSettings':
        return (
          <LevelSettings
            editType={LevelType.cell}
            levelItem={editingItem}
            isPrinting={isLoadingStickers}
            onPrintStickers={handlePrintStickers}
            onClose={() => setIsModalOpen(false)}
            onSubmit={(arg) =>
              onSubmitCellSettings(arg as SubmitCellSettingsProps)
            }
          />
        )

      case 'levelSettings':
        return (
          <LevelSettings
            levelItem={editingItem}
            levelModalItems={levelModalItems}
            onPrintStickers={handlePrintStickers}
            onClose={() => setIsModalOpen(false)}
            onSubmit={(arg) =>
              onSubmitLevelSettings(arg as SubmitLevelSettingsProps)
            }
          />
        )

      default:
        return null
    }
  }

  const openLevelSettings = useCallback((specialId: string) => {
    const editingItem = checkboxes[specialId]

    setEditingItem(editingItem)
    openModal('levelSettings')
  }, [checkboxes])

  const openCellSettings = useCallback((cell: ICellPlace) => {
    setEditingItem(cell)
    openModal('cellSettings')
  }, [])

  /** Считает сколько вложенных элементов есть на уровнях */
  const countZoneLevels = (
    level: (ILvlItem | ICellPlace | IZoneTree)[],
    countByLevels: IZoneLvlCounts[],
  ) => {
    let childrenCount = 0
    let firstChildNum = ''
    let lastChildNum = ''
    let levelDepth = 0

    if (!(level[0] as ILvlItem).items) {
      return
    }

    level.forEach((item) => {
      const splittedTitle = item.title.split(titleSeparator)

      levelDepth = splittedTitle.length
      childrenCount += (item as ILvlItem).items.length

      const itemFirstChildNum = (item as ILvlItem).items[0].title.split(
        titleSeparator,
      )[levelDepth]
      const itemLastChildNum = (item as ILvlItem).items[
      (item as ILvlItem).items.length - 1
        ].title.split(titleSeparator)[levelDepth]

      if (!!firstChildNum) {
        firstChildNum =
          +itemFirstChildNum < +firstChildNum
            ? itemFirstChildNum
            : firstChildNum
      } else {
        firstChildNum = itemFirstChildNum
      }

      if (!!lastChildNum) {
        lastChildNum =
          +itemLastChildNum > +lastChildNum ? itemLastChildNum : lastChildNum
      } else {
        lastChildNum = itemLastChildNum
      }

      countZoneLevels((item as ILvlItem).items, countByLevels)
    })

    countByLevels[levelDepth - 1] = {
      valueFrom: firstChildNum,
      valueTo: lastChildNum,
      amount: childrenCount,
    }
  }

  const openZoneEdit = (zone: IZoneTree) => {
    let countByLevels: IZoneLvlCounts[] = []
    countZoneLevels([zone], countByLevels)

    setEditZoneData({
      id: zone.id,
      title: zone.title,
      totalCells: zone.cells_quantity,
      countByLevels: countByLevels.filter(Boolean),
    })

    openModal('editZone')
  }

  /** Находит все ячейки указанного уровня */
  const findNestedCells = useCallback((
    items: (ILvlItem | ICellPlace)[],
    findBetween: boolean = false,
  ): any[] => {
    return (items as ILvlItem[]).reduce(
      (acc: (ILvlItem | ICellPlace)[], item: ILvlItem | ICellPlace) => {
        if ((item as ILvlItem).items) {
          // промежуточные уровни между нажатым и ячейками
          if (findBetween) {
            acc.push(item)
          }

          acc = acc.concat(findNestedCells((item as ILvlItem).items, findBetween))
        } else {
          acc.push(item)
        }
        return acc
      },
      [],
    )
  }, [])

  /** Находит объект уровня по пути из тайтла */
  const findLvlByTitle = useCallback((
    currentLvl: number = 0,
    currentSpecialId: string,
    pathArr: string[],
    items: (ILvlItem | ICellPlace)[],
    findBetween: boolean = false,
  ): any[] => {

    if (pathArr[currentLvl]) {
      currentLvl++

      const currentTitle = pathArr.slice(0, currentLvl).join(titleSeparator)

      if (!checkboxes[currentTitle]) {
        return findLvlByTitle(currentLvl, currentSpecialId, pathArr, items, findBetween)
      }

      for (let i = 0; i < items.length; i++) {
        if (items[i].title === currentTitle) {
          return findLvlByTitle(
            currentLvl,
            items[i].__special_id,
            pathArr,
            (items[i] as ILvlItem).items,
            findBetween,
          )
        }
      }
    } else {
      return findNestedCells(items, findBetween)
    }
  }, [checkboxes, findNestedCells])

  /** Находит дочерние ячейки и уровни(опционально) */
  const getCells = useCallback((
    title: string,
    zoneId: string,
    findBetween: boolean = false,
  ): ICellPlace[] => {
    let result: ICellPlace[] = []
    cellsTree?.forEach((zone) => {
      if (zone.id === zoneId) {
        const path = title.split(titleSeparator)
        // result = findLvlByTitle(0, zone.__special_id, path, zone.items, findBetween)

        const findCorrectChildItems = (items: (ILvlItem | ICellPlace)[]) => {
          return items.reduce((acc, item) => {
            const titleCorrect = item.title.split(titleSeparator).every((char, i) => {
              if (path[i] === undefined) return true
              return char === path[i]
            })
            if (!titleCorrect) return acc

            const childItems = (item as ILvlItem).items
            if (childItems?.length) {
              return [...acc, item, ...findCorrectChildItems(childItems)]
            }
            return [...acc, item]
          }, [])
        }
        result = findCorrectChildItems(zone.items)
      }
    })

    return result
  }, [cellsTree, findLvlByTitle])

  /** Выставляет верхним уровням значение чекбокса */
  const checkParents = useCallback((__special_id: string, checked: CheckboxStateType) => {
    const currentCheckbox = checkboxes[__special_id]

    const childrenNum = currentCheckbox.childrenNum
    let childrenChecked = currentCheckbox.childrenChecked
    let checkState = CheckboxStateType.unchecked

    if (checked === CheckboxStateType.checked) {
      childrenChecked++

      if (childrenChecked === childrenNum) {
        checkState = CheckboxStateType.checked
      } else {
        checkState = CheckboxStateType.intermediate
      }
    } else if (checked === CheckboxStateType.unchecked) {
      if (childrenChecked > 0) {
        childrenChecked--
      }

      if (childrenChecked === 0) {
        checkState = CheckboxStateType.unchecked
      } else {
        checkState = CheckboxStateType.intermediate
      }
    } else if (checked === CheckboxStateType.intermediate) {
      checkState = CheckboxStateType.intermediate
    }

    setCheckboxes((prevState) => ({
      ...prevState,
      [__special_id]: {
        ...checkboxes[__special_id],
        state: checkState,
        childrenChecked,
      },
    }))

    if (!!currentCheckbox.__parent_special_id) {
      checkParents(currentCheckbox.__parent_special_id, checkState)
    }
  }, [checkboxes])

  /** Добавляет/удаляет ячейку в список чекнутых */

  const addCheckedCell = (cell: ICellPlace, checked?: boolean) => {
    setCheckedCells((prevState) => {
      const newState = { ...prevState }
      if (!cell.zone_id) return newState
      if (checked) {
        if (newState[cell.zone_id]) {
          newState[cell.zone_id][cell.barcode] = cell
        } else {
          newState[cell.zone_id] = { [cell.barcode]: cell }
        }
      } else {
        if (newState[cell.zone_id]) {
          delete newState[cell.zone_id][cell.barcode]
        }
      }

      return newState
    })
  }

  /** Проставляет значение чекбокса по всем уровням */
  const checkCells = useCallback((
    zoneId: string,
    title: string,
    __special_id: string,
    checked: boolean,
    cell?: ICellPlace,
  ) => {
    const current = checkboxes[__special_id]
    setCheckboxes((prevState) => handleCheck(prevState, __special_id, +checked))

    if (!!current.__parent_special_id) {
      checkParents(current.__parent_special_id, +checked)
    }

    if (cell) {
      return addCheckedCell(cell, checked)
    }

    const cells = getCells(title, zoneId, true)
    cells.forEach((cell: ICellPlace | ILvlItem, i) => {
      setCheckboxes((prevState) => handleCheck(prevState, cell.__special_id, +checked))
      addCheckedCell(cell as ICellPlace, checked)
    })
  }, [checkParents, checkboxes, getCells, handleCheck])

  const renderLvls = useCallback((items: ILvlItem[] | ICellPlace[], zoneId: string) => {
    return items.map((item, i) => {
      const level = item.title.split(titleSeparator).length

      if ((item as ILvlItem).items) {
        const lvlItem = item as ILvlItem
        return (
          <Collapse
            key={lvlItem.title}
            labelClassName={styles.collapse_label}
            label={
              <div className={styles.level}>
                {checkboxes[lvlItem.__special_id]?.is_blocked && (
                  <IconLock className={styles.cellLock} size='s' />
                )}

                <div className={styles.levelImgWrapper}><IconFolders view={'ghost'} /></div>

                <Flex direction={'column'}>
                  <Text as={'span'}>{lvlItem.level_title}</Text>
                  <Text as={'span'}>{lvlItem.title}</Text>
                </Flex>

                <div className={styles.levelRightContainer}>
                  <Flex direction={'column'}>
                    <Text as={'span'}>Доступность</Text>
                    <Text as={'span'}>{checkboxes[lvlItem.__special_id]?.access_level}</Text>
                  </Flex>

                  <Button
                    view={'clear'}
                    iconLeft={IconEdit}
                    size={'s'}
                    onClick={(e) => {
                      e.stopPropagation()
                      const itemsProps = (
                        (item as ILvlItem).items[0] as ILvlItem
                      ).items as ITypeCondition[]
                      if (itemsProps) {
                        setLevelModalItems(
                          (
                            ((item as ILvlItem).items[0] as ILvlItem)
                              .items as ITypeCondition[]
                          ).map((el) => ({
                            condition: el.condition,
                            type: el.type,
                          })),
                        )
                      } else {
                        setLevelModalItems(
                          ((item as ILvlItem).items as ITypeCondition[]).map(
                            (el) => ({
                              condition: el.condition,
                              type: el.type,
                            }),
                          ),
                        )
                      }

                      const cells = getCells(item.title, zoneId, true)
                      cells.forEach((cell: ICellPlace | ILvlItem, i) => {
                        setCheckboxes((prevState) =>
                          handleCheck(prevState, cell.__special_id, +false),
                        )
                        if ((cell as ICellPlace).type === 'CELL') {
                          addCheckedCell(cell as ICellPlace, true)
                        }
                      })

                      openLevelSettings(item.__special_id)
                    }}
                  />
                </div>
              </div>
            }
            leftSideNode={
              <Checkbox
                id={`${lvlItem.__special_id}`}
                className={styles.levelCheckbox}
                checked={
                  checkboxes[lvlItem.__special_id]?.state ===
                  CheckboxStateType.checked
                }
                intermediate={
                  checkboxes[lvlItem.__special_id]?.state ===
                  CheckboxStateType.intermediate
                }
                style={{ marginRight: `${lvlShift[level]}px` }}
                onClick={(e) => {
                  e.stopPropagation()
                }}
                onChange={(e) => {
                  e.stopPropagation()
                  checkCells(zoneId, lvlItem.title, lvlItem.__special_id, e.target.checked)
                }}
              />
            }
          >
            {renderLvls(lvlItem.items, zoneId)}
          </Collapse>
        )
      }

      const cellTypeClasses = cx(
        styles.cellTypeTitle,
        (item as ICellPlace).condition === ConditionEnum.DEFECT &&
        styles.cellTypeTitle_bad,
        (item as ICellPlace).condition === ConditionEnum.EXPIRED &&
        styles.cellTypeTitle_expired,
      )

      const cellConditionClasses = cx(
        styles.cellCondition,
        (item as ICellPlace).condition === ConditionEnum.DEFECT &&
        styles.cellCondition_bad,
        (item as ICellPlace).condition === ConditionEnum.EXPIRED &&
        styles.cellCondition_expired,
      )

      return (
        <div
          key={(item as ICellPlace).id}
          className={cx(styles.cellLevel, styles.collapse_label)}
        >
          <Checkbox
            checked={
              checkboxes[item.__special_id]?.state === CheckboxStateType.checked
            }
            style={{ marginRight: `${lvlShift[level]}px` }}
            onChange={(e) => {
              checkCells(zoneId, item.title, item.__special_id, e.target.checked, item as ICellPlace)
            }}
          />

          <div className={styles.levelImgWrapper}>
            {item.is_blocked && (
              <IconLock className={styles.cellLock} view={'primary'} size='s' />
            )}

            {(item as ICellPlace).type === CellTypesEnum.cell ? (
              <IconBox view={'ghost'}/>
            ) : (
              <IconPallet view={'ghost'}/>
            )}
          </div>

          <div className={styles.cellTitleWrapper}>
            <Text
              as={'span'}
              className={cellTypeClasses}
              children={
                (item as ICellPlace).type === CellTypesEnum.cell
                  ? 'Малая ячейка'
                  : 'Палет'
              }
            />
            <Text
              as={'span'}
              className={styles.cellTitle}
              children={item.title}
            />
          </div>

          <div>
            <Text
              as={'span'}
              className={cellConditionClasses}
              children={(item as ICellPlace).condition === ConditionEnum.GOOD
                ? 'Годный товар'
                : (item as ICellPlace).condition === ConditionEnum.DEFECT
                  ? 'Брак'
                  : 'Просрочка'}
            />

            <Text children={(item as ICellPlace).barcode}/>
          </div>

          <div className={styles.levelRightContainer}>
            <Flex direction={'column'}>
              <Text children={"Доступность"}/>
              <Text children={(item as ICellPlace).access_level}/>
            </Flex>

            <Button
              size={'s'}
              view={'clear'}
              iconLeft={IconEdit}
              onClick={() => openCellSettings(item as ICellPlace)}
            />
          </div>
        </div>
      )
    })
  }, [checkCells, checkboxes, getCells, handleCheck, openCellSettings, openLevelSettings])

  return (
    <Flex
      gap={'2xl'}
      direction={'column'}
      className={className}
    >
      <SectionTitle title={title}>
        <Button
          size={'s'}
          iconSize={'s'}
          label='Создать'
          iconLeft={IconAdd}
          onClick={() => openModal('createZone')}
        />
      </SectionTitle>

      {!cellsTree ? (
        <Loader
          className={styles.loader}
          size={'m'}
        />
      ) : (
        <div>
          {cellsTree.map((zone) => {
            return (
              <LevelCard
                key={zone.id}
                zone={zone}
                handlePrintStickers={handlePrintStickers}
                handleBlockCells={handleBlockCells}
                handleDeleteCells={handleDeleteCells}
                isLoadingStickers={isLoadingStickers}
                checkedCells={checkedCells}
                openZoneEdit={openZoneEdit}
                handleDeleteZone={handleDeleteZone}
                renderLvls={renderLvls}
              />
            )
          })}
        </div>
      )}

      <Modal
        isOpen={isModalOpen}
        hasOverlay
        onClickOutside={() => setIsModalOpen(false)}
      >
        {renderModalContent()}
      </Modal>
    </Flex>
  )
}

export default ZonesSection
