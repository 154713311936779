import { FieldRow, FieldType } from '@widgets/GeneratorFields'
import { ISku } from '@/interfaces'
import { convertLength, convertWeight } from '@shared/helpers'
import { ComboboxItemDefault } from '@consta/uikit/Combobox'

export const generateDimensionsConfig = (isEdit: boolean): FieldRow[] => {
  return [
    {
      fields: [
        {
          type: FieldType.input,
          fieldProps: {
            id: 'width',
            label: 'Ширина (см)',
            isDimensions: true,
            readOnly: !isEdit
          }
        },
        {
          type: FieldType.input,
          fieldProps: {
            id: 'height',
            label: 'Высота (см)',
            isDimensions: true,
            readOnly: !isEdit
          }
        },
        {
          type: FieldType.input,
          fieldProps: {
            id: 'depth',
            label: 'Длина (см)',
            isDimensions: true,
            readOnly: !isEdit
          }
        },
      ]
    },
    {
      fields: [
        {
          type: FieldType.input,
          fieldProps: {
            id: 'weight',
            label: 'Вес (кг)',
            isWeight: true,
            readOnly: !isEdit
          }
        },
      ]
    }
  ]
}
export const generateDimensionsFactConfig = (): FieldRow[] => generateDimensionsConfig(false)
export const generateDimensionsFactState = (item: ISku): Record<string, string> => ({
  width: `${convertLength('mm', 'sm', Number(item?.dimensions_fact.width)) || '-'}`,
  height: `${convertLength('mm', 'sm', Number(item?.dimensions_fact.height)) || '-'}`,
  depth: `${convertLength('mm', 'sm', Number(item?.dimensions_fact.depth)) || '-'}`,
  weight: `${convertWeight('g', 'kg', Number(item?.weight_fact)) || '-'}`,
})

export const generateMerchantConfig = (isEdit: boolean) => {
  return  [
    {
      fields: [
        {
          type: FieldType.MERCHANT,
          fieldProps: {
            id: 'merchant',
            label: '',
            readOnly: !isEdit
          }
        },
      ]
    }
  ]
}

export const generateSkuInfoConfig = (isCreate: boolean, isEdit: boolean, countriesOptions: ComboboxItemDefault[]) => {
  return  [
    {
      fields: [
        {
          type: FieldType.input,
          fieldProps: {
            id: 'title',
            label: 'Наименование',
            readOnly: !isEdit
          }
        },
        {
          type: FieldType.input,
          fieldProps: {
            id: 'article',
            label: 'Артикул',
            readOnly: !isEdit
          }
        },
        {
          type: FieldType.input,
          fieldProps: {
            id: 'id',
            label: 'ID',
            readOnly: !isEdit || !isCreate
          }
        },
      ]
    },
    {
      fields: [
        {
          type: FieldType.input,
          fieldProps: {
            id: 'external_id',
            label: 'Внешний ключ',
            readOnly: !isEdit || !isCreate
          }
        },
        {
          type: FieldType.input,
          fieldProps: {
            id: 'tnved_code',
            label: 'Код ТНВЭД',
            readOnly: !isEdit
          }
        },
        {
          type: FieldType.input,
          fieldProps: {
            id: 'title_en',
            label: 'Наименование на английском',
            readOnly: !isEdit
          }
        },
      ]
    },
    {
      fields: [
        {
          type: FieldType.BOOLEAN,
          fieldProps: {
            id: 'require_weight',
            label: 'Необходимость взвешивания',
            readOnly: !isEdit
          }
        },
        {
          type: FieldType.input,
          fieldProps: {
            id: 'manufacturer',
            label: 'Изготовитель',
            readOnly: !isEdit
          }
        },
        {
          type: FieldType.BOOLEAN,
          fieldProps: {
            id: 'fragile',
            label: 'Хрупкий товар',
            readOnly: !isEdit
          }
        },
      ]
    },
    {
      fields: [
        {
          type: FieldType.input,
          fieldProps: {
            id: 'expiration_months',
            label: 'Срок годности (месяца)',
            isInteger: true,
            readOnly: true
          }
        },
        {
          type: FieldType.input,
          fieldProps: {
            id: 'min_days_before_expiration_date',
            label: 'Минимальный срок годности (дни)',
            isInteger: true,
            readOnly: true
          }
        },
        {
          type: FieldType.BOOLEAN,
          fieldProps: {
            id: 'batch_accounting',
            label: 'Учет по партиям',
            readOnly: !isEdit || !isCreate
          }
        },
      ]
    },
    {
      fields: [
        {
          type: FieldType.BOOLEAN,
          fieldProps: {
            id: 'batch_expdate_mandatory',
            label: 'Учет по срокам годности',
            readOnly: !isEdit || !isCreate
          }
        },
        {
          type: FieldType.BOOLEAN,
          fieldProps: {
            id: 'require_dimensions',
            label: 'Необходимость измерения габаритов',
            readOnly: !isEdit
          }
        },
        {
          type: FieldType.combobox,
          fieldProps: {
            id: 'country_of_origin',
            label: 'Страна изготовления',
            readOnly: !isEdit,
            items: countriesOptions
          }
        },
      ]
    },
  ]
}