import { ITableColumn, ITableRow, NewPackageDto, OptionsPackagingPackagigns } from '@/interfaces'
import { Flex } from '@shared/ui/Flex'
import { Text } from '@shared/ui/Text'
import { Button } from '@shared/ui/btns/Button'
import { IconRemove } from '@consta/icons/IconRemove'
import React from 'react'
import { ImageCell, InputWrapper } from '@/components'
import { AddedPackingItem } from './AdditionalPackagingV2Modal'
import { IconAdd } from '@consta/icons/IconAdd'


export const generatePlanRows = (
  packagings: NewPackageDto[],
  handleGetAvailableItem: (packagingId: string) => number,
  itemClientOptionsObj: Record<string, OptionsPackagingPackagigns>,
): ITableRow[] => {

  return (
    packagings
      .map((packaging) => {
        const addedPackagingQuantity = handleGetAvailableItem(packaging.id)
        const clientPackaging = itemClientOptionsObj[packaging.id]
        const clientSkuPackaging = packaging._sku_client_packaging
        const theme = clientSkuPackaging ? 'red' : clientPackaging ? 'yellow' : undefined
        const availablePackagings = (packaging.available - addedPackagingQuantity)
        return ({
          id: packaging.id,
          img: packaging.images?.[0]?.url,
          name: packaging.title,
          packaging: packaging,
          theme: theme,
          available: availablePackagings <= 0 ? 0 : availablePackagings,
          isClientPackagigng: Boolean(clientPackaging),
          quantity: addedPackagingQuantity,
          quantityPlan: clientPackaging?.quantity || 0,
          _sku_client_packaging: clientSkuPackaging,
        })
      })
      .sort((itemA, itemB) => {
        if (itemA.isClientPackagigng) return -1
        if (itemA.available > 0) return 1
        return 0
      })
  ) as ITableRow[]
}

export const generatePlanColumns = (handleAddItem: (rowPackaging: NewPackageDto) => void): ITableColumn[] => {
  return [
    {
      key: 'img',
      title: 'Фото',
      title_txt: 'Фото',
      gridCellWidth: '90px',
      renderCell: ({ row }) => <ImageCell image={row.img} />,
    },
    {
      key: 'name',
      title: 'Материал упаковки',
      title_txt: 'Материал упаковки',
      renderCell: ({ row }) => (
        <Flex direction={'column'}>
          <Text size={'s'} children={row.name} />
          {
            !row.available ? (
              <Text size={'xs'} view={'alert'} children={row._sku_client_packaging ? 'Расходник заблокирован' : 'Остаток 0'} />
            ) : null
          }
        </Flex>
      ),
    },
    {
      key: 'quantity',
      title: 'Кол-во',
      title_txt: 'Кол-во',
      renderCell: ({ row }) => row.isClientPackagigng ? (
        <Text
          size={'s'}
          weight={'bold'}
          children={`${row.quantity} / ${row.quantityPlan}`}
        />
      ) : null,
    },
    {
      key: 'action__add',
      title: '',
      title_txt: '',
      renderCell: ({ row }) => (
        <Flex
          pointer
          justifyContent={'end'}
          onClick={() => row.available ? handleAddItem(row.packaging) : null}
          children={
            <Button
              onlyIcon
              label={'Добавить'}
              view={'ghost'}
              size={'xs'}
              iconSize={'s'}
              iconRight={IconAdd}
              disabled={!row.available || row._sku_client_packaging}
            />
          }
        />
      ),
    },
  ]
}


export const generateAddedItemsRows = (
  addedPackaging: AddedPackingItem[],
  handleEditQuantityItem: (packagingId: string, index: number, quantity: string) => void,
): ITableRow[] => {
  return addedPackaging.map((packaging, rowIndex) => {
    const availablePackagings = (packaging.available - Number(packaging.fact_quantity || 0))
    return ({
      id: packaging.id,
      index: rowIndex,
      name: packaging.title,
      defaultIsExpanded: true,
      available: availablePackagings <= 0 ? 0 : availablePackagings,
      quantity: (
        <InputWrapper
          isNumber
          label={''}
          placeholder={'кол-во'}
          handleChange={(value) => handleEditQuantityItem(packaging.id, rowIndex, value ?? '')}
          size='s'
          value={`${packaging.fact_quantity}`}
        />
      ),
      measure: packaging.measure,
      subItems: packaging.has_additional_packagings && packaging.additional_packagings?.length
        ? packaging.additional_packagings.map((additionalPack, additionalRowIndex) => ({
          id: additionalPack.id,
          name: additionalPack.title,
          measure: additionalPack.measure,
          quantity: (
            <InputWrapper
              isNumber
              label={''}
              disabled={true}
              placeholder={'кол-во'}
              handleChange={(value) => handleEditQuantityItem(additionalPack.id, additionalRowIndex, value ?? '')}
              size='s'
              value={`${additionalPack.fact_quantity}`}
            />
          ),
        }))
        : undefined,
    })
  })
}

export const generateAddedItemsColumns = (handleRemoveAddedItem: (index: number) => void): ITableColumn[] => {
  return [
    {
      key: 'name',
      title: 'Материал упаковки',
      title_txt: 'Материал упаковки',
       renderCell: ({ row }) => (
        <Flex direction={'column'}>
          <Text size={'s'} children={row.name} />
          {
            !row.available ? (
              <Text size={'xs'} view={'alert'} children={'Остаток 0'} />
            ) : null
          }
        </Flex>
      ),
    },
    {
      key: 'quantity',
      title: '',
      title_txt: '',
      width: '60px',
      renderCell: ({ row }) => (
        <Flex
          style={{ maxWidth: '100px' }}
          justifyContent={'center'}
        >
          {row.quantity}
        </Flex>
      ),
    },
    {
      key: 'measure',
      title: 'Ед. изм.',
      title_txt: 'Ед. изм.',
    },
    {
      key: 'action__delete',
      title: '',
      title_txt: '',
      width: '60px',
      renderCell: ({ row, isSubItem }) => isSubItem
        ? null
        : (
          <Flex
            pointer
            justifyContent={'end'}
            onClick={() => handleRemoveAddedItem(row.index)}
            children={
              <Button
                onlyIcon
                theme={'danger'}
                view={'ghost'}
                size={'xs'}
                iconSize={'s'}
                iconRight={IconRemove}
                onClick={() => {
                }}
              />
            }
          />
        ),
    },
  ]
}