import { ISku, SkuSerialNumberDto } from '@/interfaces'
import React from 'react'
import { NomenclatureCell, TitleCell } from '@pages/OperationsAndDocumentsPage/components'
import { serialNumbersStatus } from './filters'
import { SECTIONS, tabs } from '@pages/DirectoriesPage/constants'
import { generateDirectoriesPath } from '@shared/routing/config/paths'
import { TableColumnType, TableRowType } from '@/components/Table/TanStackTable'
import { Text } from '@shared/ui/Text'

export const generateTableData = (
  serialNumbers: SkuSerialNumberDto[],
  skus: ISku[]
) => {
  const rows: TableRowType[] = serialNumbers.map((item) => {
    const sku = skus.find((sku) => sku.id === item.sku_id)
    const status = serialNumbersStatus[item.status]

    return {
      id: item.value,
      value: item.value,
      code: item.code,
      skuName: sku.title,
      status: status?.label || '-',
      sku: sku,
      __custom_hrefLink: generateDirectoriesPath({
        page: tabs.guides.value,
        section: SECTIONS.nomenclature.value,
        type: 'item',
        id: sku.id || '',
      })
    }
  })

  const columns: TableColumnType[] = [
    {
      id: 'skuName',
      accessorKey: 'skuName',
      header: 'Номенклатура',
      size: 300,
      meta: {
        generateLink: (row, column) => row.original.__custom_hrefLink,
      },
      cell: ({ row }) => {
        return (
          <NomenclatureCell
            link={row.original.__custom_hrefLink}
            title={(
              <TitleCell
                title={row.original.sku?.title || ''}
                article={row.original.sku?.article || ''}
                titleSettings={{
                  view: 'link'
                }}
                articleSettings={{
                  view: 'link'
                }}
              />
            )}
            itemId={row.original.sku?.id || '-'}
          />
        )
      }
    },
    {
      id: 'code',
      accessorKey: 'code',
      header: 'Тип',
      size: 300,
    },
    {
      id: 'status',
      accessorKey: 'status',
      header: 'Статус',
      size: 300,
    },
    {
      id: 'value',
      accessorKey: 'value',
      header: 'Значение',
      size: 300,
      cell: ({ row }) => {
        return (
          <Text
            as={'p'}
            style={{ maxWidth: '700px', wordWrap: 'break-word' }}
            children={row.original.value}
          />
        )
      }
    },
  ]
  return { rows, columns }
}
