import type { FC } from 'react'
import React, { useEffect, useState } from 'react'

import { IPrivilegeRes, IPrivileges, IRole, IRoleRender } from './types'
import type { ISection } from '../../types'
import { ConfirmModal } from '@pages/OperationsAndDocumentsPage/components'

import { useCreateRole, useDeleteRole, useGetRoles } from '@/hooks'
import { IProps as CreateRoleReqProps } from '@/hooks/warehouse/useCreateRole'
import { Badge } from '@consta/uikit/Badge'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { Modal } from '@/components'
import RoleCreate from '../../components/RoleCreate/RoleCreate'
import { Flex } from '@/newSrc/shared/ui/Flex'
import { DirectoriesLayout } from '../../components/DirectoriesLayout/DirectoriesLayout'
import { useFilterState } from '@shared/hooks'
import { EditRemoveButtons } from '@pages/DirectoriesPage/components/EditRemoveButtons/EditRemoveButtons'
import { TableColumnType } from '@/components/Table/TanStackTable'

const RoleSection: FC<ISection> = ({
  className = '',
  title = '',
}) => {

  const {
    isLoading: isLoadingRoles,
    data: rolesData,
    refetch: refetchRoles,
    isSuccess: isRolesDataSuccess,
    isFetching: isRolesDataFetching,
  } = useGetRoles()


  const {
    filterStateDebounced,
    filterState,
    handleChangeFilter
  } = useFilterState({withoutHistory: true})

  const [roles, setRoles] = useState<IRoleRender[]>([])
  const [filteredRoles, setFilteredRoles] = useState<IRoleRender[]>([])
  const [privileges, setPrivileges] = useState<IPrivileges>({})

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [roleId, setRoleId] = useState<string | null>(null)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)

  const { mutate: createRole, isLoading: isRoleCreating } = useCreateRole()
  const { mutate: deleteRole, isLoading: isRoleDelete } = useDeleteRole()

  const columns: TableColumnType[] = [
    {
      header: 'Название роли',
      id: 'titleEl',
      accessorKey: 'titleEl',
      enableSorting: true,
      size: 250
    },
    {
      header: 'Привилегии',
      id: 'privileges',
      accessorKey: 'privileges',
      size: 700,
      cell: ({ row }) => row.original.privileges
    },
    {
      header: '',
      id: 'editDelete__action',
      accessorKey: 'editDelete__action',
      size: 300,
      cell: ({row}) => (
        <EditRemoveButtons
          withEdit
          withRemove
          onEditClick={() => {
            setRoleId(row.original.id)
            setIsModalOpen(true)
          }}
          onRemoveClick={() => {
            setRoleId(row.original.id)
            setOpenConfirmModal(true)
          }}
        />
      )
    },
  ]

  const searchRoles = (query: TextFieldPropValue = '') => {
    const rows = roles.filter((role) => {
      if (!query || role.title.toLowerCase().includes(query.toLowerCase())) {
        return role
      }

      return false
    })

    setFilteredRoles(rows)
  }

  useEffect(() => {
    searchRoles(filterStateDebounced?.search || '')
  }, [filterStateDebounced])


  const handleDeleteRole = () => {
    if (!roleId) return
    deleteRole(roleId, { onSuccess: () => refetchRoles() })
    handleCloseModal()
  }

  const handleCreateRole = (roleData: CreateRoleReqProps) => {
    createRole(roleData, { onSuccess: () => refetchRoles() })
    handleCloseModal()
  }

  const renderModalContent = () => {
    if (!rolesData) return

    const [data, privilegesData] = rolesData
    const privilegesList = privilegesData?.values?.reduce(
      (acc: IPrivileges, privilege: IPrivilegeRes) => {
        acc[privilege.value] = {
          ...privilege,
          checked: false,
        }

        return acc
      },
      {},
    )

    return (
      <RoleCreate
        privilegesList={privilegesList}
        roleId={roleId}
        rolesData={data?.roles}
        onSubmit={handleCreateRole}
        onClose={handleCloseModal}
      />
    )
  }

  useEffect(() => {
    if (!rolesData || !rolesData?.length) {
      return
    }

    const [data, privilegesData] = rolesData

    const privilegesSet = privilegesData.values.reduce(
      (acc: IPrivileges, privilege: IPrivilegeRes) => {
        acc[privilege.value] = {
          ...privilege,
          checked: false,
        }

        return acc
      },
      {},
    )

    setPrivileges(privilegesSet)

    const roles = data.roles.map((role: IRole) => {
      const result: any = {
        id: role.id,
        title: role.title,
        titleEl: role.title,
      }

      if (role.privileges) {
        result.privileges = (
          <Flex gap={'xs'} flexWrap={'wrap'} align={'start'}>
            {role.privileges.map((privilege) => (
              <Badge
                key={privilege}
                label={privilegesSet[privilege]?.title || ''}
                view={'filled'}
                size={'s'}
                status={'system'}
              />
            ))}
          </Flex>
        )
      }

      return result
    })

    setRoles(roles)
    setFilteredRoles(roles)
  }, [rolesData])

  const handleCloseModal = () => {
    setRoleId(null)
    setOpenConfirmModal(false)
    setIsModalOpen(false)
  }

  return (
    <DirectoriesLayout
      withTable
      withCreate
      withFilter
      withSearch

      title={title}

      filterState={filterState || {}}
      handleChangeFilter={handleChangeFilter}

      onCreateBtnClick={() => setIsModalOpen(true)}

      withCopy={false}
      isLoading={isLoadingRoles}
      columns={columns}
      rows={filteredRoles}
    >
      <Modal
        isOpen={isModalOpen}
        withClose
        hasOverlay
        size={'l'}
        onClose={handleCloseModal}
        onClickOutside={handleCloseModal}
        children={renderModalContent()}
      />
      {openConfirmModal ? (
        <ConfirmModal
          isOpen={openConfirmModal}
          onClose={handleCloseModal}
          onSubmit={handleDeleteRole}
        />
      ) : null}
    </DirectoriesLayout>
  )
}

RoleSection.displayName = 'RoleSection'

export default RoleSection
