import { Link } from 'react-router-dom'
import React from 'react'
import { IMerchant } from '@/interfaces'
import { Text } from '@shared/ui/Text'
import { Switch } from '@consta/uikit/Switch'
import { generateDirectoriesPath } from '@shared/routing/config/paths'
import { TableColumnType, TableRowType } from '@/components/Table/TanStackTable'


export const generateTableData = (
  merchnatsItems: IMerchant[],
  page: string,
  section: string,
  handleClickActionBtn: (value: boolean, row: any) => void,
) => {
  const rows: TableRowType[] = merchnatsItems.map((item) => {
    return ({
      id: item.id,
      title: item.title,
      inn: item.inn,
      camId: item.cam_id,
      service_group_set_id: item.service_group_set_id,
      enabled__action: item.enabled,
      __custom_hrefLink: generateDirectoriesPath({page, section, type: 'item', id: item.id})
    })
  })

  const columns: TableColumnType[] = [
    {
      header: 'Название',
      id: 'title',
      accessorKey: 'title',
      size: 300,
      meta: {
        generateLink: (row, column) => row.original.__custom_hrefLink,
      },
      cell: ({ row }) => (
        <Link to={row.original.__custom_hrefLink}>
          <Text view={'link'} children={row.original.title} />
        </Link>
      )
    },
    {
      header: 'ИНН',
      id: 'inn',
      accessorKey: 'inn',
      size: 300,
    },
    {
      header: 'Идентификатор в системе CAM',
      id: 'camId',
      accessorKey: 'camId',
      size: 300,
    },
    {
      header: 'Активность',
      id: 'enabled__action',
      accessorKey: 'enabled__action',
      size: 200,
      cell: ({ row }) => (
         <Switch
           size={'s'}
           label={row.original.enabled ? 'Активен' : 'Скрыт'}
           checked={row.original.enabled}
           onChange={(e) => {
             handleClickActionBtn(e.target.checked, row.original)
           }}
         />
      )
    },
  ]
  return {rows, columns}
}