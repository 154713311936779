import { ICategory } from '@/interfaces'
import { EditRemoveButtons } from '@pages/DirectoriesPage/components/EditRemoveButtons/EditRemoveButtons'
import React from 'react'
import { TableColumnType, TableRowType } from '@/components/Table/TanStackTable'

export const generateTableData = (
  categories: ICategory[],
  search: string,
  handleConfirmModal: (code: string) => void,
  handleEdit: (code: string) => void,
) => {
  let rows: TableRowType[] =categories.map((item) => {
    return {
      id: item.code,
      title: item.title,
      edit: '',
    }
  })

  if (search) {
    rows = rows.filter(row => row.id.includes(search) || row.title.includes(search))
  }

  const columns: TableColumnType[] = [
    {
      header: 'Код',
      id: 'id',
      accessorKey: 'id',
      size: 400,
    },
    {
      header: 'Наименование',
      id: 'title',
      accessorKey: 'title',
      size: 400,
    },
    {
      header: '',
      id: 'edit__action',
      accessorKey: 'edit__action',
      size: 200,
      cell: ({ row }) => {
        return (
          <EditRemoveButtons
            withEdit
            withRemove
            onEditClick={() => handleConfirmModal(row.original.id)}
            onRemoveClick={() => handleEdit(row.original.id)}
          />
        )
      },
    },
  ]
  return { rows, columns }
}
