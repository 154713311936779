import type { FC } from 'react'
import React, { useMemo } from 'react'
import styles from './DirectoriesTable.module.scss'
import { IPagination } from '@/interfaces'
import { SnackBar } from '@consta/uikit/SnackBar'
import { SnackBarPropItemAction } from '@consta/uikit/__internal__/src/components/SnackBar/types'
import { TableColumnType, TableRowType, TanStackTable } from '@/components/Table/TanStackTable'
import { useParams } from 'react-router-dom'


interface Props {
  snackBarActions?: SnackBarPropItemAction[]
  withNumeric?: boolean
  withCreate?: boolean
  withCopy?: boolean
  tableCopy?: boolean
  isLoading?: boolean
  pagination?: IPagination
  extraHeader?: React.ReactNode
  onRowCreate?: () => void
  onChangePage?: (page: number) => void
  maxHeight?: number

  withRowSelection?: boolean
  setSelectedRows?: React.Dispatch<React.SetStateAction<TableRowType[]>>
  columns: TableColumnType[]
  rows: TableRowType[]
}

const DirectoriesTable: FC<Props> = (props) => {
  const {
    withNumeric,
    snackBarActions,
    withCopy = false,
    tableCopy = false,
    isLoading,
    pagination,
    onChangePage = () => {
    },
    onRowCreate,
    maxHeight,
    extraHeader,

    withRowSelection,
    setSelectedRows,

    ...restProps
  } = props

  const {
    id, page, section, type,
  } = useParams<{ id: string, section: string, type: string, page: string }>()

  const memoRows = useMemo(() => restProps.rows, [restProps.rows])
  const memoColumns = useMemo(() => restProps.columns, [restProps.columns])

  return (
    <div className={styles.tableContainer}>
      <TanStackTable
        withRowSelection={withRowSelection ? true : undefined}
        onRowSelectionChange={setSelectedRows}
        withHeader={extraHeader ? true : undefined}
        extraHeader={extraHeader}
        withNumerableRows={withNumeric}
        id={`directoriesTable-${section}-${id}`}
        pageId={'derictories'}
        maxHeight={'800px'}
        data={memoRows}
        columns={memoColumns}
        withPagination
        withCopy={withCopy}
        isLoading={isLoading}
        pagination={pagination}
        onChangePage={onChangePage}
      />
      {
        snackBarActions?.length ? (
          <SnackBar
            className={styles.snackBar}
            items={[
              {
                key: 1,
                message: 'Что сделать с выбранными элементами?',
                status: 'system',
                actions: snackBarActions,
              },
            ]}
          />
        ) : null
      }
    </div>
  )
}

export default DirectoriesTable
