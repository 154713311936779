import React from 'react'

import styles from './img.module.scss'

import { Modal } from '@/components'

import { getYandexStoreImage } from '@shared/helpers'

import mockImg from '@shared/assets/images/noImage.png'
import cx from 'classnames'
import { useToggle } from '@shared/hooks'

interface ImgWithModalProps {
  src?: string
  size?: number
  yandex?: boolean
  modal?: boolean
  className?: string
}

export const Img = (props: ImgWithModalProps) => {
  const { src, size, yandex, modal, className } = props

  const {value: imgModalVisible, turnOn: showImgModal, turnOff: hideImgModal} = useToggle()

  let value = !src ? mockImg : src

  if (src && yandex) {
    value = getYandexStoreImage(src, size)
  }

  let style = !value
    ? {}
    : {
        backgroundImage: `url(${value})`,
      }

  const handleClick = (e) => {
    if (modal) {
      e.stopPropagation()
      showImgModal()
    }
  }
  return (
    <>
      <div
        className={cx(styles.img, className)}
        style={style}
        onClick={handleClick}
      />

      {src && modal ? (
        <Modal
          isOpen={imgModalVisible}
          withClose
          size="min"
          onOverlayClick={hideImgModal}
          onClose={hideImgModal}
        >
          <img className={styles.modalImg} src={src} />
        </Modal>
      ) : null}
    </>
  )
}
