export const BarcodeTypesOptions = [
  { id: 'COMMON', label: 'COMMON' },
  { id: 'WILDBERRIES', label: 'WILDBERRIES' },
  { id: 'OZON', label: 'OZON' },
  { id: 'LETOILE', label: 'LETOILE' },
  { id: 'ZOLOTOE_YABLOKO', label: 'ZOLOTOE_YABLOKO' },
  { id: 'SBERMEGAMARKET', label: 'SBERMEGAMARKET' },
  { id: 'YANDEX_MARKET', label: 'YANDEX_MARKET' },
  { id: 'LAMODA', label: 'LAMODA' },
  { id: 'DET_MIR', label: 'DET_MIR' },
  { id: 'VSEINSTRUMENTI', label: 'VSEINSTRUMENTI' },
  { id: 'MVIDEO', label: 'MVIDEO' },
  { id: 'MAGNIT', label: 'MAGNIT' },
  { id: 'RETAIL_NETWORK', label: 'RETAIL_NETWORK' },
  { id: 'RETAIL_NETWORK_4', label: 'RETAIL_NETWORK_4' },
  { id: 'RETAIL_NETWORK_6', label: 'RETAIL_NETWORK_6' },
]