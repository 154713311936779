import React, { FC, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { IRouteParams, ISection } from '@pages/DirectoriesPage/types'
import { useGetItems } from '@/hooks'
import { useFilterState } from '@shared/hooks'
import { generateFilterParams, generateFilters } from './utils/filter'
import { SECTIONS } from '../../../../constants'
import { generateTableData } from './utils/table'
import { DirectoriesLayout } from '@/newSrc/pages/DirectoriesPage/components/DirectoriesLayout/DirectoriesLayout'
import { generateDirectoriesPath } from '@shared/routing/config/paths'

interface ListProps extends ISection {

}

const List: FC<ListProps> = ({ title }) => {
  const { page = '', section = SECTIONS.nomenclature.value } = useParams<IRouteParams>()

  const [pagination, setPagination] = useState(0)

  const history = useHistory()

  const {
    filterStateDebounced,
    filterState,
    handleChangeFilter,
  } = useFilterState({
    withoutHistory: true,
    onChangeFilterState: () => setPagination(0),
  })

  const {
    isFetching: isFetchingSkus,
    data: skusData,
  } = useGetItems(
    { ...generateFilterParams({ ...filterStateDebounced } || {}), page: pagination })

  const filtersConfig = generateFilters()

  const { rows, columns } = useMemo(() => {
    return generateTableData(skusData?.skus || [], page, section)
  }, [skusData?.skus])

  const handleClickCreate = () => {
    history.push(generateDirectoriesPath({
      page,
      section,
      type: 'item',
      id: 'create',
    }))
  }

  return (
    <DirectoriesLayout
      withTable
      withCreate
      withFilter
      withSearch={false}

      title={title}
      filtersConfig={filtersConfig}
      filterState={filterState}
      handleChangeFilter={handleChangeFilter}

      onCreateBtnClick={handleClickCreate}

      isLoading={isFetchingSkus}
      columns={columns}
      rows={rows}
      pagination={skusData?.pagination}
      onChangePage={setPagination}
    />
  )
}

List.displayName = 'List'

export default List
