import React from 'react'
import cx from 'classnames'

import styles from './modal.module.scss'

import { Modal as ConstaModal } from '@consta/uikit/Modal'
import { IconClose } from '@consta/icons/IconClose'
import isMobile from 'ismobilejs'
import { Flex } from '@shared/ui/Flex'
import { Text } from '@shared/ui/Text'
import { IconComponent } from '@consta/icons/Icon'
import { Button } from '@shared/ui/btns/Button'
import { LoaderWrapper } from '@shared/ui/LoaderWrapper'

declare const modalPropWidth: readonly ['auto']
export declare type ModalPropWidth = typeof modalPropWidth[number]

declare const ModalPropSize: readonly ['s', 'm', 'm-plus', 'l', 'min', 'xl']
export declare type ModalPropSize = typeof ModalPropSize[number]

declare const modalPropPosition: readonly ['center', 'top']
declare type ModalPropPosition = typeof modalPropPosition[number]

interface ConstaModalProps {
  isOpen?: boolean
  onClose?: () => void
  onOpen?: () => void
  hasOverlay?: boolean
  onOverlayClick?: (event: MouseEvent) => void
  onClickOutside?: (event: MouseEvent) => void
  className?: string
  rootClassName?: string
  width?: ModalPropWidth
  position?: ModalPropPosition
  children?: React.ReactNode
  container?: HTMLDivElement | undefined
  refsForExcludeClickOutside?: React.RefObject<HTMLElement>[]
  title?: string
  subtitle?: string
  alertSubtitle?: boolean
  titleAlign?: 'center' | 'left'
  img?: string
  Icon?: IconComponent
  imgClass?: string
  size?: ModalPropSize
  withClose?: boolean
  isLoading?: boolean
  closeClass?: string
  headerTitle?: string
}

const Modal = (props: ConstaModalProps) => {
  const {
    img,
    Icon,
    imgClass,
    title,
    titleAlign = 'center',
    subtitle,
    withClose = false,
    onClose = () => false,
    onOverlayClick,
    onClickOutside,
    size,
    headerTitle,
    closeClass,
    alertSubtitle = false,
    isLoading = false,
    ...restProps
  } = props

  const isTsd = isMobile()?.any

  return (
    <ConstaModal
      {...restProps}
      rootClassName={styles.rootClassName}
      onClickOutside={onOverlayClick || onClickOutside}
      className={cx(
        styles.modal,
        props.className,
        size ? styles[size] : styles.m,
      )}
    >
      <Flex gap={'s'} justifyContent={'between'}>
        <Flex align={'center'} fullWidth direction={'column'} gap={isTsd ? 'xs' : 's'} className={styles.titleBlock}>
          {headerTitle ? (
            <Text
              as={'h3'}
              align={titleAlign}
              size={isTsd ? 'l' : 'xl'}
              weight={'bold'}
              className={styles.headerTitle}
              children={headerTitle}
            />
          ) : null}
          {img ? (
            <img className={cx(styles.img, imgClass)} src={img} alt='' />
          ) : null}
          {Icon ? (
            <Icon className={cx(styles.img, imgClass)} />
          ) : null}
          {title ? (
            <Text
              as={'h3'}
              size={isTsd ? 'l' : '2xl'}
              weight={'bold'}
              align={titleAlign}
              children={title}
            />
          ) : null}
          {subtitle ? (
            <Text
              as={'h5'}
              size={isTsd ? 's' : 'l'}
              align={titleAlign}
              view={'secondary'}
              weight={'semibold'}
              className={cx(
                styles.subtitle,
                alertSubtitle ? styles.alert : null,
              )}
              children={subtitle}
            />
          ) : null}
        </Flex>
        {withClose ? (
          <Button
            onlyIcon
            iconLeft={IconClose}
            size={'s'}
            view='ghost'
            className={cx(styles.close, { [styles.closeTSD]: isMobile().any }, closeClass)}
            onClick={() => onClose()}
          />
        ) : null}
      </Flex>
      <LoaderWrapper isLoading={isLoading}>
        {props.children}
      </LoaderWrapper>
    </ConstaModal>
  )
}

export default Modal
