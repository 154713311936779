import {
  PackagingSettingsColors,
  PackagingSettingsDto,
  PackagingSettingsOverrideDto,
  RequiredFieldsToSet,
} from '@/interfaces/packaging'
import { ISku } from '@/interfaces'
import { NomenclatureCell, TitleCell } from '@/newSrc/pages/OperationsAndDocumentsPage'
import React from 'react'
import { PackagingSettingTypeCombobox } from '@/newSrc/entities/PackagingSetting'
import { Text } from '@shared/ui/Text'
import { Flex } from '@shared/ui/Flex'
import { Button, ImageCell } from '@/components'
import { IconOpenInNew } from '@consta/icons/IconOpenInNew'
import { TableColumnType, TableRowType, TanStackTableThemeType } from '@/components/Table/TanStackTable'

export const generateTableData = (args: {
  settings: PackagingSettingsDto[],
  settingsOverride: PackagingSettingsOverrideDto[],
  skus: ISku[]
  changeState: Record<string /* packagingId */, RequiredFieldsToSet>,
  handleUpdateChangeState: (newSettingData: PackagingSettingsDto) => void,
  handleGetRuleTypeTitle: (type: string) => void,
  setOverrideItems: React.Dispatch<React.SetStateAction<PackagingSettingsOverrideDto[] | null>>
}) => {
  const {
    skus = [],
    settings = [],
    settingsOverride = [],
    changeState,
    handleUpdateChangeState,
    handleGetRuleTypeTitle,
    setOverrideItems
  } = args

  let rows: TableRowType[] =settings.map((item, i) => {
    const overrideItems = settingsOverride.filter(override => override.packaging_id === item.packaging_id)
    const packagingSku = skus.find(sku => sku.id === item.packaging_id)
    const changeItem = changeState[item.packaging_id]
    const itemWasChange = changeItem && (changeItem?.type !== item.type)
    return {
      meta: {
        theme: PackagingSettingsColors[item.type] as TanStackTableThemeType,
      },
      id: item.packaging_id,
      sku: packagingSku,
      typeValue: (changeItem?.type || item.type),
      type: (
        <PackagingSettingTypeCombobox
          value={null}
          status={itemWasChange ? 'warning' : undefined}
          caption={itemWasChange ? 'Изменен' : ''}
          valueId={changeItem?.type || item.type}
          onChange={(value) => handleUpdateChangeState({
            packaging_id: item.packaging_id,
            type: !value || value?.id === item.type ? undefined : value?.id
          })}
          size={'s'}
        />
      ),
      typeOverrides: overrideItems,
    }
  })

  const columns: TableColumnType[] = [
    {
      id: 'image',
      accessorKey: 'image',
      enableHiding: false,
      header: 'Фото',
      meta: { headerText: 'Фото' },
      size: 50,
      cell: ({ row }) => <ImageCell image={row.original.sku?.image} />
    },
    {
      id: 'name',
      accessorKey: 'name',
      header: 'Наименование',
      meta: { headerText: 'Наименование' },
      enableHiding: false,
      size: 400,
      cell: ({ row }) => {
        return (
          <NomenclatureCell
            title={
              <TitleCell
                title={row.original.sku?.title || ''}
                article={''}
                titleSettings={{
                  view: 'link'
                }}
              />
            }
            itemId={row.original.sku?.id}
          />
        )
      }
    },
    {
      id: 'type',
      accessorKey: 'type',
      header: 'Тип настройки',
      meta: { headerText: 'Тип настройки' },
      enableHiding: false,
      size: 400,
      cell: ({ row }) => row.original.type
    },
    {
      id: 'overrides',
      accessorKey: 'overrides',
      header: 'Тип настройки перезаписан',
      meta: { headerText: 'Тип настройки перезаписан' },
      size: 350,
      cell: ({ row }) => {
        const typeOverrides = row.original.typeOverrides
        if (!typeOverrides?.length) return null
        let type = Array.from(new Set(typeOverrides.map(item => handleGetRuleTypeTitle(item.type)))).join(', ')
        return (
          <Flex fullWidth justifyContent={'center'}>
            <Flex
              gap={'2xs'}
              align={'center'}
              direction={'column'}
              justifyContent={'center'}
              style={{width: '300px', height: '100%'}}
            >
              <Text
                align={'center'}
                size={'s'}
                children={type}
              />
              <Button
                label={'Открыть фильтры'}
                size={'xs'}
                view={'clear'}
                iconRight={IconOpenInNew}
                onClick={() => setOverrideItems(typeOverrides)}
              />
            </Flex>
          </Flex>
        )
      }
    },
  ]
  return { rows, columns }
}
