import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { IReducerAction } from '@/interfaces'
import { IPhysicalSetState } from '../store/interfaces'
import { initialState, reducer } from '../store/reducer'
import { PhysicalSetPagesEnum } from '../types'
import { useParams } from 'react-router-dom'
import { setPackagingsAction, setPackagingsSkusAction } from '../store/actions'
import usePhysicalSetRequests from '../hooks/usePhysicalSetRequests'
import useManageState from '../hooks/useManageState'

interface IReducer {
  state: IPhysicalSetState
  dispatch: React.Dispatch<IReducerAction>
}

interface IContext extends IReducer {
}

const defaultValue: IContext = {
  state: initialState,
  dispatch: (action: IReducerAction) => action,
}

export const PhysicalSetContext = createContext<IContext>(defaultValue)

export const PhysicalSetContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props
  const {
    page = PhysicalSetPagesEnum.scan,
    docId,
  } = useParams<{ page: PhysicalSetPagesEnum, docId: string }>()

  const [state, dispatch] = useReducer<React.Reducer<IPhysicalSetState, IReducerAction>>(reducer, initialState)

  const {
    setNewOrderData
  } = useManageState({ state, dispatch })

  useEffect(() => {
    // console.clear()
    // console.log('----------\n__DEBUG__\n', state, '\n-----------')
  }, [state])

  const {
    physicalSetQuery,
    packagingsQuery,
  } = usePhysicalSetRequests({
    onSuccessPhysicalSet: (order, containers, skus) => setNewOrderData(order, containers, skus),
    onSuccessPackagings: (packagings) => dispatch(setPackagingsAction(packagings)),
    onSuccessPackagingsOptions: (packagingsSkus) => dispatch(setPackagingsSkusAction(packagingsSkus))
  })

  useEffect(() => {
    if (docId) {
      physicalSetQuery.refetch()
    }
  }, [docId])

  useEffect(() => {
    packagingsQuery.refetch()
  }, [page])

  return (
    <PhysicalSetContext.Provider value={{ state, dispatch }}>
      {children}
    </PhysicalSetContext.Provider>
  )
}

export const usePhysicalSetContext = () => useContext(PhysicalSetContext)
