import {
  IContainer,
  IDraftPhysicalSetPlace,
  IDraftPhysicalSetPlaceItem,
  IPackageDto,
  IPhysicalSetOrderDto,
  IPutPhysicalSetOrderPlaceInnerDto,
  ISku,
  PackageSkuDto,
} from '@/interfaces'

export const SET_PHYSICAL_SET_ORDERS = 'SET_PHYSICAL_SET_ORDERS'
export const setPhysicalSetOrdersAction = (orders: IPhysicalSetOrderDto[]) => {
  return {
    type: SET_PHYSICAL_SET_ORDERS,
    payload: orders,
  }
}

export const SET_PHYSICAL_SET_ORDER = 'SET_PHYSICAL_SET_ORDER'
export const setPhysicalSetOrderAction = (order: IPhysicalSetOrderDto | null) => {
  return {
    type: SET_PHYSICAL_SET_ORDER,
    payload: order,
  }
}

export const SET_ALL_CONTAINERS = 'SET_ALL_CONTAINERS'
export const setAllContainersAction = (containers: IContainer[]) => {
  return {
    type: SET_ALL_CONTAINERS,
    payload: containers,
  }
}

export const SET_PACKAGINGS = 'SET_PACKAGINGS'
export const setPackagingsAction = (packagings: IPackageDto[]) => {
  return {
    type: SET_PACKAGINGS,
    payload: packagings,
  }
}


export const SET_PACKAGINGS_SKUS = 'SET_PACKAGINGS_SKUS'
export const setPackagingsSkusAction = (packagings: PackageSkuDto[]) => {
  return {
    type: SET_PACKAGINGS_SKUS,
    payload: packagings,
  }
}

export const SET_CACHE_SERIAL_NUMBERS = 'SET_CACHE_SERIAL_NUMBERS'
export const setCacheSerialsAction = (cacheSerials: string[]) => {
  return {
    type: SET_CACHE_SERIAL_NUMBERS,
    payload: cacheSerials,
  }
}

export const SET_PHYSICAL_SET_ADDING_SKU_ITEM = 'SET_PHYSICAL_SET_ADDING_SKU_ITEM'
export const setAddingSkuItemAction = (currentItem: IDraftPhysicalSetPlaceItem | null) => {
  return {
    type: SET_PHYSICAL_SET_ADDING_SKU_ITEM,
    payload: currentItem,
  }
}
export const SET_PHYSICAL_SET_ORDER_PLACES = 'SET_PHYSICAL_SET_ORDER_PLACES'
export const setAllPhysicalSetPlacesAction = (places: IDraftPhysicalSetPlace[]) => {
  return {
    type: SET_PHYSICAL_SET_ORDER_PLACES,
    payload: places,
  }
}
export const SET_ALL_SKUS = 'SET_ALL_SKUS'
export const setAllSkusAction = (allSkus: ISku[]) => {
  return {
    type: SET_ALL_SKUS,
    payload: allSkus,
  }
}
export const SET_PHYSICAL_SET_PLACE_MODAL_VISIBLE = 'SET_PHYSICAL_SET_PLACE_MODAL_VISIBLE'
export const setPhysicalSetPlaceModalVisibleAction = (visible: boolean) => {
  return {
    type: SET_PHYSICAL_SET_PLACE_MODAL_VISIBLE,
    payload: visible,
  }
}
export const SET_CHANGE_PACKAGING_PLACE_VISIBLE = 'SET_CHANGE_PACKAGING_PLACE_VISIBLE'
export const setChangePackagingPlaceVisibleAction = (visible: boolean) => {
  return {
    type: SET_CHANGE_PACKAGING_PLACE_VISIBLE,
    payload: visible,
  }
}

export const SET_WEIGHT_DIMENSIONS_PLACE_VISIBLE = 'SET_WEIGHT_DIMENSIONS_PLACE_VISIBLE'
export const setWeightDimensionsPlaceVisibleAction = (place: IPutPhysicalSetOrderPlaceInnerDto | null) => {
  return {
    type: SET_WEIGHT_DIMENSIONS_PLACE_VISIBLE,
    payload: place,
  }
}

export const RESET_STATE = 'RESET_STATE'
export const resetState = () => {
  return {
    type: RESET_STATE,
    payload: null,
  }
}

export const SET_MODAL_CHZ_VISIBLE = 'SET_MODAL_CHZ_VISIBLE'
export const setModalChzVisibleAction = (place: IDraftPhysicalSetPlace | null) => {
  return {
    type: SET_MODAL_CHZ_VISIBLE,
    payload: place,
  }
}
