import React, { useState } from 'react'
import styles from './CountModal.module.scss'
import { Button, InputWrapper, Modal } from 'src/components'
import { SkuItemPreview } from '@entities/Sku/ui/SkuItemPreview'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { IconAdd } from '@consta/icons/IconAdd'
import { IconRemove } from '@consta/icons/IconRemove'

interface Props {
  title?: string
  subtitle?: string
  itemData?: {
    skuImage: string
    skuTitle: string
    skuArticle?: string
  }
  minCount?: number
  maxCount?: number
  onCancel: () => void
  onSubmit: (productAmount: number) => void
  valueValidation?: (value: TextFieldPropValue) => string
}

const CountModal = (props: Props) => {
  const {
    title = "Массовый отбор",
    subtitle = "Введите кол-во отбираемого товара",
    itemData,
    onCancel,
    onSubmit,
    valueValidation,
    minCount = 0,
    maxCount
  } = props

  const [productAmount, setProductAmount] = useState<TextFieldPropValue>('')

  const handleClickSubmit = () => {
    if (!productAmount) {
      return
    }
    onSubmit(Number(productAmount))
  }

  const hasErrorTxt = valueValidation?.(productAmount)
  return (
    <Modal
      isOpen={true}
      hasOverlay
      onOverlayClick={(): boolean => false}
      className={styles.modal}
      title={title}
      subtitle={subtitle}
    >
      {
        itemData ? (
          <SkuItemPreview
            direction={'vertical'}
            image={itemData.skuImage}
            title={itemData.skuTitle}
            article={itemData.skuArticle}
          />
        ) : null
      }

      <div className={styles.inputWrapper}>
        <Button
          onlyIcon
          iconLeft={IconRemove}
          view={'ghost'}
          form={'roundBrick'}
          disabled={!Boolean(productAmount) || Boolean(productAmount && Number(productAmount) <= minCount)}
          onClick={() => {
            setProductAmount((prev) => {
              if (!prev) {
                return prev
              }
              const newValue = Number(prev) - 1
              if (newValue <= 0) {
                return `${0}`
              }
              return `${newValue}`
            })
          }}
        />
        <InputWrapper
          autoFocus
          label={'Количество'}
          value={productAmount}
          id={'amount'}
          form={'brick'}
          handleChange={setProductAmount}
          className={styles.itemInput}
          isInteger
        />
        <Button
          onlyIcon
          iconLeft={IconAdd}
          form={'brickRound'}
          onClick={() => {
            setProductAmount((prev) => `${prev ? Number(prev) + 1 : 1}`)
          }}
          view={'ghost'}
          disabled={Boolean(productAmount && maxCount && Number(productAmount) >= maxCount)}
        />
        { hasErrorTxt ? <p className={styles.error}>{hasErrorTxt}</p> : null }
      </div>
      <div className={styles.btns}>
        <Button
          label={'Отмена'}
          view={'ghost'}
          onClick={onCancel}
          width={'full'}
          size={'s'}
        />
        <Button
          label={'Подтвердить'}
          onClick={handleClickSubmit}
          width={'full'}
          size={'s'}
          disabled={!Boolean(productAmount) || (Number(productAmount) < minCount) || Boolean(hasErrorTxt)}
        />
      </div>
    </Modal>
  )
}

export default CountModal
