import { ICarrier } from '@/interfaces'
import { ActivationBadge } from '@shared/ui/Badges/ActivationBadge'
import React from 'react'
import { EditRemoveButtons } from '@pages/DirectoriesPage/components/EditRemoveButtons/EditRemoveButtons'
import { TableColumnType, TableRowType } from '@/components/Table/TanStackTable'

export const generateTableData = (
  carriers: ICarrier[],
  handleEdit: (code: string) => void,
  handleDeleteRevert: (code: string, isDelete: boolean) => void,
) => {
  const rows: TableRowType[] = carriers.map((item) => {
    return {
      id: item.id,
      title: `${item.name} ${item.surname} ${item.patronymic}`,
      phone: item.phone,
      company: item.company?.title,
      deleted: item.deleted,
      edit__action: '',
    }
  })

  const columns: TableColumnType[] = [
    {
      header: 'ФИО',
      id: 'title',
      accessorKey: 'title',
      size: 200,
    },
    {
      header: 'Номер',
      id: 'phone',
      accessorKey: 'phone',
      size: 200,
    },
    {
      header: 'Компания',
      id: 'company',
      accessorKey: 'company',
      size: 200,
    },
    {
      header: 'Компания',
      id: 'deleted__action',
      accessorKey: 'deleted__action',
      size: 200,
      cell: ({ row }) => {
        return (
          <ActivationBadge size={row.getTableDensity()} active={!row.original.deleted} />
        )
      }
    },
    {
      header: '',
      id: 'edit__action',
      accessorKey: 'edit__action',
      size: 400,
      cell: ({ row }) => {
        return (
          <EditRemoveButtons
            withEdit
            withRemove
            withRevert
            deleted={row.original.deleted}
            onEditClick={() => handleEdit(row.original.id)}
            onRemoveClick={() => handleDeleteRevert(row.original.id, !row.original.deleted)}
          />
        )
      },
    },
  ]
  return { rows, columns }
}
