import { ItemProcessResultType } from '@/components/widgets'
import {
  ConditionEnum,
  IDraftPhysicalSetPlace,
  IPhysicalSetOrderPlaceDto,
  IPhysicalSetOrderPlaceItemDto,
  IPutPhysicalSetOrderPlaceInnerItemDto,
} from '@/interfaces'

export type AddingItemDataType = ItemProcessResultType & { condition?: ConditionEnum }

export type CombinedPlaceType = IPhysicalSetOrderPlaceDto | IDraftPhysicalSetPlace
export type CombinedPlaceItemType = IPhysicalSetOrderPlaceItemDto | IPutPhysicalSetOrderPlaceInnerItemDto

export enum PhysicalSetPagesEnum {
  scan = 'scan',
  form = 'form',
}
