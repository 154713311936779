import { IPaymentMethods } from '@/interfaces/paymentMethods'
import { TableColumnType, TableRowType } from '@/components/Table/TanStackTable'

export const generateTableData = (values: IPaymentMethods[], search: string) => {
  let rows: TableRowType[] =values.map((item) => {
    return {
      id: item.value,
      title: item.title,
    }
  })

  if (search) {
    rows = rows.filter(row => row.title.includes(search))
  }

  const columns: TableColumnType[] = [
    {
      header: 'Наименование',
      id: 'title',
      accessorKey: 'title',
    },
  ]
  return { rows, columns }
}
