import { saveAs } from 'file-saver'
import { v4 } from 'uuid'

export const saveBase64File = (content:string, title: string = '', type = "application/xls") => {
  let byteCharacters = atob(content);
  let byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  let byteArray = new Uint8Array(byteNumbers);
  let blob = new Blob([byteArray], {type: type});

  saveAs(blob, title)
}


export const getYandexStoreImage = (value?: string, size?: number): string => {
  if (value && value.indexOf('https://storage.yandexcloud.net/') === 0) {
    if (typeof size !== 'undefined') {
      const splitted = value.split('.')
      const extension = splitted.pop()
      value = splitted.join('.') + '-' + String(size) + '.' + extension
    }
  }

  return value || ''
}

export const checkBase64MIMEType = (b64: string) => {
  const signatures = {
    JVBERi0: 'application/pdf',
  } as any //TODO TS enum

  for (const s in signatures) {
    if (b64?.indexOf(s) === 0) {
      return signatures[s]
    }
  }

  return null
}

export const getBase64Extension = (fileBase64: string) => {
  if (!fileBase64) return
  const base64HasDataInfo = fileBase64.includes('data:')
  if (!base64HasDataInfo) {
    let fileHeader = new Map()

    //get the first 3 char of base64
    fileHeader.set('/9j', 'jpg')
    fileHeader.set('iVB', 'png')
    fileHeader.set('Qk0', 'bmp')
    fileHeader.set('SUk', 'tiff')
    fileHeader.set('JVB', 'pdf')
    fileHeader.set('UEs', 'ofd')

    let res = ''

    fileHeader.forEach((v, k) => {
      if (k == fileBase64.substring(0, 3)) {
        res = v
      }
    })
    return res
  }
  return fileBase64.split(';base64,')?.[0]?.split('/')[1]
}

export const base64ToImgStrWithInfo = (base64: string) =>
  `data:image/png;base64,${base64}`

export const getBase64Content = (base64WithDataInfo: string) =>
  base64WithDataInfo?.split(';base64,')?.[1] || ''

export const dataURItoBlob = (dataURI: string): Blob => {
  const byteString = atob(dataURI.split(',')[1])
  const mimeString = dataURI.split(',')?.[0].split(':')[1].split(';')?.[0]
  const ab = new ArrayBuffer(byteString.length)
  let ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ab], { type: mimeString })
}

export const saveBase64AsPdf = (data: {
  content: string
  fileName: string
}) => {
  const reportBlob = dataURItoBlob(`data:application/octet-stream;base64,${data.content}`)
  saveAs(reportBlob, data.fileName)
}

export const getBase64ContentByStr = (base64: string) => {
  const metaInfoIndicator = 'base64,'
  const base64HasMetaInfo = base64.includes(metaInfoIndicator)
  return base64.split(metaInfoIndicator)[base64HasMetaInfo ? 1 : 0]
}

export const convertFileToBase64 = (
  file: File,
  withFileFormatInfo: boolean = false,
): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      if (typeof reader.result === 'string') {
        const resultRaw = reader.result
        const formatedResult = getBase64ContentByStr(resultRaw)
        resolve(!withFileFormatInfo ? formatedResult : resultRaw)
      } else {
        console.error('Ошибка загрузки')
      }
    }
    reader.onerror = function (error) {
      reject(error)
    }
  })
}

export const convertBase64ToFile = (base64: string, name?: string) => {
  const blob = dataURItoBlob(base64)
  const fileName = name || `generated_img_name_${v4()}`
  return new File([blob], fileName)
}