import { useState, useEffect } from 'react'

const useIsMobile = (): boolean => {
  const [isMobile, setIsMobile] = useState(
    window.matchMedia('(max-width: 500px)').matches,
  )

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 500px)')
    const handler = (e: MediaQueryListEvent) => setIsMobile(e.matches)

    mediaQuery.addEventListener('change', handler)
    return () => mediaQuery.removeEventListener('change', handler)
  }, [])

  return isMobile
}
export default useIsMobile
