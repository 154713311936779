import { useMutation } from 'react-query'

import api from '@shared/api'
import { ApiReqResult, IBarcode, IDimensionsObj } from 'src/interfaces'
import { UseMutationOptions } from 'react-query/types/react/types'


export interface IUpdateSkuItemData {
  id?: string
  article?: string
  barcodes: IBarcode[]
  batch_accounting?: boolean
  batch_expdate_mandatory?: boolean
  composition?: string
  country_of_origin?: string
  dimensions_plan?: IDimensionsObj
  external_id?: string
  fragile?: boolean
  manufacturer?: string
  require_dimensions?: boolean
  require_weight?: boolean
  title?: string
  title_en?: string
  tnved_code?: string
  type?: string
  weight_plan?: number
}

interface Params {
  item: IUpdateSkuItemData
}
interface Result extends ApiReqResult {
  id: string
}

const useCreateEditItem = (options?: UseMutationOptions<Result, any, Params>) => {
  return useMutation<Result, any, Params>(
    async (data: Params) => {
      const result = await api.post(`/items`, data.item)
      if (!result?.data?.success) {
        throw result
      }
      return result.data
    },
    options
  )
}

export default useCreateEditItem
