import React from 'react'
import { IDeliveryContract } from '@/interfaces'
import { TableColumnType, TableRowType } from '@/components/Table/TanStackTable'

export const generateTableData = (
  deliveryContracts: IDeliveryContract[],
) => {
  const rows: TableRowType[] = deliveryContracts.map(
    (contract, i) => {
      return {
        id: `${contract.id || contract.dlv_id || i}${contract.code}`,
        nameEl: contract.title,
        deliveryEl: contract.delivery_company.title,
        productEl: contract.delivery_company_product.title,
      }
    },
  )

  const columns: TableColumnType[] = [
    {
      header: "Наименование",
      id: 'nameEl',
      accessorKey: 'nameEl',
      enableSorting: true,
      size: 400
    },
    {
      header: "Способ доставки",
      id: 'deliveryEl',
      accessorKey: 'deliveryEl',
      enableSorting: true,
      size: 400
    },
    {
      header: "Продукт",
      id: 'productEl',
      accessorKey: 'productEl',
      enableSorting: true,
      size: 400
    },
  ]
  return { rows, columns }
}
