import React, { useState } from 'react'
import { Button, InputWrapper } from 'src/components'
import styles from './CellManualInput.module.scss'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { IconEdit } from '@consta/icons/IconEdit'
import { FormType } from 'src/components/InputWrapper/InputWrapper'
import { CellManualModal } from '../../modal'
import { IError } from 'src/interfaces'

interface IProps {
  handleSubmitBarcode: (value: TextFieldPropValue) => void
  btnVisible?: boolean
  label?: string
  error?: IError
  inputForm?: FormType
  inputRef?: React.Ref<HTMLTextAreaElement | HTMLInputElement>
}

const CellManualInput: React.FC<IProps> = (props) => {
  const {
    handleSubmitBarcode,
    btnVisible = false,
    label = 'Отсканируйте штрихкод ячейки',
    inputForm = 'default',
    inputRef,
    error
  } = props

  const [modalVisible, setModalVisible] = useState(false)

  return (
    <>
      <div className={styles.wrapper}>
        <InputWrapper
          id={'barcode'}
          autoFocus
          withDelay
          form={inputForm}
          label={label}
          placeholder={''}
          handleChange={handleSubmitBarcode}
          className={styles.barcodeInput}
          inputRef={inputRef}
          inputMode={'none'}
          size={'s'}
        />
        {
          btnVisible ? (
            <Button
              label=''
              view='ghost'
              className={styles.editBtn}
              iconLeft={IconEdit}
              size='s'
              onClick={() => setModalVisible(true)}
            />
          ) : null
        }
      </div>

      <CellManualModal
        isCellLoading={false}
        isOpen={modalVisible}
        error={error}
        onSubmit={handleSubmitBarcode}
        onClose={() => setModalVisible(false)}
      />
    </>
  )
}
export default CellManualInput
