import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { useGetItems, useGetItemsStock } from '@/hooks'
import { IBarcode, IDimensions, ISku, ModalProps } from '@/interfaces'
import { IRouteParams } from '@pages/DirectoriesPage/types'
import { SkuItemPreview } from '@entities/Sku/ui/SkuItemPreview'
import { Table, TableColumn, TableRow } from '@consta/uikit/Table'
import { ComboboxWrapper, InputWrapper, Modal, WeightDimensionsModal } from '@/components'
import { Button } from '@shared/ui/btns/Button'
import { Text } from '@shared/ui/Text'

import { generatePostData, getBarcodeTable, getCellsStock, getPartsTable, getUnTable } from './utils'

import styles from './item.module.scss'
import {
  convertDimensionsStr,
  convertLength,
  convertWeight,
  getBase64ContentByStr,
  getCountriesOptions,
} from '@shared/helpers'
import noImage from '../../../../../../shared/assets/images/noImage.png'
import { Flex } from '@shared/ui/Flex'
import { FormState, IBarcodeForm, InfoItemType } from '@pages/DirectoriesPage/pages/Nomenclature/pages/Item/types'
import { FileField } from '@shared/ui/fields/FileField/FileField'
import useAddSkuItemImage from '@/hooks/items/useAddSkuItemImage'
import { useDebounce, useToggle } from '@shared/hooks'
import { EditFormHeader } from '@pages/DirectoriesPage/components/EditFormHeader/EditFormHeader'
import { FieldRow, GeneratorFields } from '@widgets/GeneratorFields'
import {
  generateDimensionsConfig,
  generateDimensionsFactConfig,
  generateDimensionsFactState,
  generateMerchantConfig,
  generateSkuInfoConfig,
} from '@pages/DirectoriesPage/pages/Nomenclature/pages/Item/fields'
import { generateDirectoriesPath } from '@shared/routing/config/paths'
import useCreateEditItem from '@/hooks/items/useCreateEditItem'
import { useNotifications } from '@shared/providers/NotificationProvider'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { ComboboxItemDefault } from '@consta/uikit/Combobox'
import { BarcodeTypesOptions } from '@shared/helpers/barcodes'
import { Grid, GridItem } from '@consta/uikit/Grid'
import { v4 } from 'uuid'
import cx from 'classnames'


type TyblesType = {
  id: string
  title: string
  cols: TableColumn<TableRow>[]
  rows: TableRow[]
  visible?: boolean
  withCreateEdit?: boolean
  onCreate?: () => void
}

type BarcodeFormState = Pick<IBarcode, 'barcode' | 'type'> & {
  _id?: string
}

interface ItemProps {
}

const requiredFields = ['barcodes']

const Item: FC<ItemProps> = () => {
  const { id = '', page, section } = useParams<IRouteParams>()
  const skuId = decodeURIComponent(id)
  const isCreation = skuId === 'create'

  const history = useHistory()
  const notification = useNotifications()

  const [formImage, setFormImage] = useState<{ base64: string, title: string } | null>(null)
  const [fieldsStateDebounced, fieldsState, setFieldsState] = useDebounce<FormState>({})
  const [barcodes, setBarcodes] = useState<IBarcodeForm[]>([])
  const [selectedBarcode, setSelectedBarcode] = useState<BarcodeFormState | null>(null)
  const [item, setItem] = useState<ISku | null>(null)

  const handleChangeFieldsState = useCallback((fieldId: string, value: any) => {
    setFieldsState((prev) => ({ ...prev, [fieldId]: value }))
  }, [setFieldsState])

  const countriesOptions = useMemo(getCountriesOptions, [])

  const handleSetSkuState = useCallback((currentSku?: ISku) => {
    if (!currentSku) return
    setBarcodes(currentSku.barcodes.map(barcode => ({ ...barcode, _id: v4() })))
    setFieldsState({
      merchant: {
        id: currentSku.merchant?.id,
        label: currentSku.merchant?.internal_title || currentSku.merchant?.title,
      },

      width: convertLength('mm', 'sm', Number(currentSku.dimensions_plan?.width)),
      height: convertLength('mm', 'sm', Number(currentSku.dimensions_plan?.height)),
      depth: convertLength('mm', 'sm', Number(currentSku.dimensions_plan?.depth)),
      weight: convertWeight('g', 'kg', Number(currentSku.weight_plan)),

      article: currentSku?.article,
      id: currentSku?.id,
      external_id: currentSku?.external_id,
      title: currentSku?.title,
      title_en: currentSku?.title_en,
      manufacturer: currentSku?.manufacturer,
      fragile: `${currentSku?.fragile}`,

      require_weight: `${currentSku?.require_weight}`,
      batch_accounting: `${currentSku?.batch_accounting}`,
      batch_expdate_mandatory: `${currentSku?.batch_expdate_mandatory}`,
      require_dimensions: `${currentSku?.require_dimensions}`,

      min_days_before_expiration_date: currentSku?.min_days_before_expiration_date ? `${currentSku?.min_days_before_expiration_date}` : '',
      expiration_months: currentSku?.expiration_months ? `${currentSku?.expiration_months}` : '',

      country_of_origin: countriesOptions.find(country => country.id === currentSku?.country_of_origin),
    })
  }, [
    setBarcodes,
    setFieldsState
  ])

  const {
    isFetching: isFetchingSkus,
    data: skusData,
    refetch: refetchSkuData,
  } = useGetItems({
    id: skuId, options: {
      enabled: !isCreation,
      onSuccess: (data) => {
        const currentSku = data.skus[0] as ISku
        if (!currentSku) {
          console.error('Sku not found')
          return
        }
        handleSetSkuState(currentSku)
        setFormImage(null)
      },
    },
  })

  const {
    isFetching: isFetchingStock,
    data: stockData,
  } = useGetItemsStock({
    skus: [{ sku_id: skuId }],
  }, {
    enabled: !isCreation,
  })

  const {
    mutateAsync: updateSkuItemImage,
  } = useAddSkuItemImage()

  const {
    mutateAsync: createEditItem,
  } = useCreateEditItem()

  const handleUpdateItem = useCallback(async () => {
    const data = generatePostData(fieldsState, barcodes, id)
    const result = await createEditItem({ item: data })
    const skuId = result.id
    if (formImage && skuId) {
      try {
        const base64ForServer = getBase64ContentByStr(formImage.base64)
        await updateSkuItemImage({ skuId: skuId, data: [{ data: base64ForServer, title: formImage.title }] })
      } catch (e) {
        console.error(e)
      }
    }
    await refetchSkuData()
    notification.show('success', 'Форма успешно сохранена')
  }, [formImage, createEditItem, updateSkuItemImage, fieldsState, generatePostData, barcodes, id, refetchSkuData])

  const {
    value: isEdit,
    turnOn: editOn,
    turnOff: editOff,
  } = useToggle({ defaultState: isCreation })

  const {
    value: isDimensionsModalVisible,
    turnOn: showDimensionsModal,
    turnOff: hideDimensionsModal,
  } = useToggle()


  useEffect(() => {
    if (!skusData) return
    setItem(skusData.skus[0])
  }, [skusData])


  const handleResetSelectedBarcode = useCallback(() => setSelectedBarcode(null), [setSelectedBarcode])

  const handleAddBarcode = useCallback((newBarcode: BarcodeFormState) => {
    setBarcodes(prev => {
      if (newBarcode._id) {
        const foundItem = prev.find(item => item._id === newBarcode._id)
        if (foundItem) {
          foundItem.barcode = newBarcode.barcode
          foundItem.type = newBarcode.type
          return [...prev]
        }
      }
      return [{ ...newBarcode, _id: v4() }, ...prev]
    })
    handleResetSelectedBarcode()
  }, [setBarcodes, handleResetSelectedBarcode])

  const handleClickEditBarcode = useCallback((barcode) => setSelectedBarcode(barcode), [setSelectedBarcode])
  const handleClickDeleteBarcode = useCallback((barcode) => {
    setBarcodes(prev => prev.filter(barcodeData => barcodeData.barcode !== barcode))
  }, [setBarcodes])

  const { barcodeRows, barcodeCols } = useMemo(
    () => getBarcodeTable(barcodes, handleClickEditBarcode, handleClickDeleteBarcode, isEdit),
    [barcodes, handleClickEditBarcode, handleClickDeleteBarcode, isEdit],
  )
  const { partsRows, partsCols } = useMemo(
    () => getPartsTable(item),
    [item],
  )
  const { unRows, unCols } = useMemo(() => getUnTable(item), [item])
  const { stockRows, stockCols } = useMemo(
    () =>
      getCellsStock({
        sku: item,
        stock: stockData?.stock || [],
        places: stockData?.places || [],
      }),
    [stockData, item],
  )

  const tables: TyblesType[] = useMemo(() => {
    return [
      {
        id: 'barcodes',
        title: 'Штрихкода',
        cols: barcodeCols,
        rows: barcodeRows,
        visible: true,
        withCreateEdit: true,
        onCreate: () => setSelectedBarcode({ barcode: '', type: '' }),
      },
      {
        id: 'setContent',
        title: 'Состав набора',
        cols: partsCols,
        rows: partsRows,
        visible: !isCreation && !isEdit,
      },
      {
        id: 'serialNumbers',
        title: 'Уникальные номера',
        cols: unCols,
        rows: unRows,
        visible: !isCreation && !isEdit,
      },
      {
        id: 'storageAddresses',
        title: 'Адреса хранения ',
        cols: stockCols,
        rows: stockRows,
        visible: !isCreation && !isEdit,
      },
    ] as TyblesType[]
  }, [
    isCreation,
    barcodeCols,
    barcodeRows,
    partsCols,
    partsRows,
    unCols,
    unRows,
    stockCols,
    stockRows,
  ])

  const handleCancelClick = useCallback(() => {
    const currentSku = skusData?.skus?.[0]
    if (isCreation || !currentSku) {
      history.push(generateDirectoriesPath({
        page,
        section,
        type: 'list',
      }))
      return
    }
    handleSetSkuState(currentSku)
    editOff()
  }, [editOff, isCreation, skusData, handleSetSkuState])

  const handleSaveClick = useCallback(async () => {
    try {
      await handleUpdateItem()
      editOff()
    } catch (e) {
      console.error(e)
    }
  }, [editOff, handleUpdateItem])

  const handleStartEditClick = useCallback(() => {
    editOn()
  }, [editOn])

  const generateSkuInfoFieldsConfig = useMemo(
    () => generateSkuInfoConfig(isCreation, isEdit, countriesOptions),
    [isCreation, isEdit, countriesOptions]
  )
  const generateMerchantFieldsConfig = useMemo(() => generateMerchantConfig(isEdit), [isEdit])
  const generateDimensionsPlanFieldsConfig = useMemo(() => generateDimensionsConfig(isEdit), [isEdit])
  const generateDimensionsFactFieldsConfig = useMemo(() => generateDimensionsFactConfig(), [])
  const generateDimensionsFactFieldsValues = useMemo(() => generateDimensionsFactState(item), [item])

  const handleChangeImage = useCallback((base64Value: string, title?: string) => {
    setFormImage({ base64: base64Value, title: title })
  }, [setFormImage])


  return (
    <div>
      <Flex direction='column' align={'start'} gap={'m'}>
        <EditFormHeader
          isEdit={isEdit}
          title={item?.title || (isCreation ? 'Создание номенклатуры' : '')}
          onCancelClick={handleCancelClick}
          onSaveClick={handleSaveClick}
          onStartEditClick={handleStartEditClick}
        />
        <FileField
          withPhoto={false}
          readOnly={!isEdit}
          imgSize={'l'}
          id={'skuImage'}
          value={formImage?.base64 || item?.image || noImage}
          handleChange={handleChangeImage}
        />
      </Flex>
      <div className={styles.fieldsWrapper}>
        <FieldsSection
          fieldsState={fieldsState}
          fieldsConfig={generateSkuInfoFieldsConfig}
          onChangeFieldsState={handleChangeFieldsState}
        />
        <FieldsSection
          fieldsState={fieldsState}
          fieldsConfig={generateMerchantFieldsConfig}
          onChangeFieldsState={handleChangeFieldsState}
          title={'Организация'}
        />
        <Flex gap={'l'} direction={'column'}>
          <Flex
            gap={'s'}
            align={'start'}
            direction={'column'}
          >
            <FieldsSection
              fieldsState={fieldsState}
              fieldsConfig={generateDimensionsPlanFieldsConfig}
              onChangeFieldsState={handleChangeFieldsState}
              title={'Габариты (План)'}
            />
          </Flex>
          {!isEdit ? (
            <Flex
              gap={'s'}
              align={'start'}
              direction={'column'}
            >
              <FieldsSection
                fieldsState={generateDimensionsFactFieldsValues}
                fieldsConfig={generateDimensionsFactFieldsConfig}
                title={'Габариты (Факт)'}
              />
              <Button
                label={'Поменять вес/размер'}
                size={'s'}
                onClick={showDimensionsModal}
              />
            </Flex>
          ) : null}
        </Flex>
      </div>
      <div>
        {tables.map((item, i) => {
          if (!item.visible) return null
          return (
            <Flex key={item.id} gap={'xs'} direction='column'>
              <Flex justifyContent={'between'}>
                <Text
                  as={'p'}
                  size={'l'}
                  weight='bold'
                  children={item.title}
                  className={cx({ [styles.requiredLabel]: requiredFields.includes(item.id) })}
                />
                {item.withCreateEdit && isEdit ? (
                  <Flex justifyContent={'end'}>
                    <Button
                      size={'s'}
                      label={'Добавить'}
                      onClick={item.onCreate}
                    />
                  </Flex>
                ) : null}
              </Flex>
              <Table
                className={styles.table}
                columns={item.cols as TableColumn<(typeof item.rows)[number]>[]}
                rows={item.rows || []}
              />
            </Flex>
          )
        })}
      </div>

      {isDimensionsModalVisible ? (
        <WeightDimensionsModal
          item={{
            id: skuId,
            weight_plan: item.weight_plan,
            weight:
              item.weight_fact !== undefined
                ? convertWeight('g', 'kg', Number(item?.weight_fact))
                : undefined,
            dimensions:
              item?.dimensions_fact && Object.keys(item?.dimensions_fact).length
                ? convertDimensionsStr(
                  'mm',
                  'sm',
                  item.dimensions_fact as IDimensions,
                )
                : undefined,
          }}
          top={
            <SkuItemPreview
              image={item.image}
              title={item.title}
              barcode={item.barcodes[0].barcode}
            />
          }
          onSubmit={() => refetchSkuData()}
          isOpen={isDimensionsModalVisible}
          onClose={hideDimensionsModal}
          withClose
          hardClose
          alwaysUpdate
          withZero
        />
      ) : null}
      {
        selectedBarcode ? (
          <CreateEditBarcodeModal
            barcodes={barcodes}
            editBarcode={selectedBarcode}
            onSubmit={handleAddBarcode}
            onClose={handleResetSelectedBarcode}
          />
        ) : null
      }
    </div>
  )
}


type EditBarcodeModalProps = {
  editBarcode?: BarcodeFormState
  barcodes: IBarcode[]
  onSubmit: (value: BarcodeFormState) => void
} & Omit<ModalProps, 'isOpen'>
const CreateEditBarcodeModal = (props: EditBarcodeModalProps) => {
  const {
    editBarcode,
    barcodes,
    onSubmit,
    onClose,
  } = props

  const isEdit = Boolean(editBarcode.barcode)
  const [newBarcodeDebounced, newBarcode, setNewBarcode] = useDebounce<BarcodeFormState | null>(editBarcode?.barcode ? editBarcode : null)

  /** Проверяем ввод ШК */
  const barcodeError = useMemo(() => {
    let error = ''
    // const foundBarcode = barcodes.find(barcodeObj => barcodeObj.barcode === newBarcodeDebounced?.barcode)
    // if (foundBarcode) {
    //   error = 'Такой ШК уже существует'
    // }
    return error
  }, [newBarcodeDebounced, barcodes])

  const handleClickSubmit = () => {
    if (!newBarcode?.barcode || !newBarcode?.type || barcodeError) {
      return
    }
    onSubmit(newBarcode)
  }
  const handleClickCancel = () => {
    onClose()
  }

  const handleChangeBarcode = (value: TextFieldPropValue) => {
    setNewBarcode(prev => ({ ...prev, barcode: value }))
  }
  const handleChangeBarcodeType = (value: ComboboxItemDefault | null) => {
    setNewBarcode(prev => ({ ...prev, type: value ? `${value.id}` : '' }))
  }

  /** Получаем значение для комбобокса из типа ШК */
  const barcodeTypeValue = useMemo(() => {
    return BarcodeTypesOptions.find(type => type.id === newBarcode?.type)
  }, [newBarcode?.type])

  return (
    <Modal
      isOpen={true}
      title={`${isEdit ? 'Редактирование' : 'Добавление'} штрихкода`}
      subtitle={' '}
      withClose
      onClose={onClose}
      onOverlayClick={onClose}
    >
      <Grid cols={4} gap={'s'}>
        <GridItem col={2}>
          <InputWrapper
            label={'Штрихкод'}
            value={newBarcode?.barcode}
            size={'s'}
            error={barcodeError}
            handleChange={handleChangeBarcode}
          />
        </GridItem>
        <GridItem col={2}>
          <ComboboxWrapper
            label={'Тип'}
            items={BarcodeTypesOptions}
            size={'s'}
            value={barcodeTypeValue}
            onChange={handleChangeBarcodeType}
          />
        </GridItem>
        <GridItem col={4} />
        <GridItem col={4} />
        <GridItem col={4}>
          <Flex justifyContent={'between'}>
            <Button
              size={'s'}
              label={'Отменить'}
              onClick={handleClickCancel}
              view={'ghost'}
            />
            <Button
              size={'s'}
              label={'Сохранить'}
              onClick={handleClickSubmit}
              disabled={!newBarcode?.barcode || !newBarcode?.type || Boolean(barcodeError)}
            />
          </Flex>
        </GridItem>
      </Grid>
    </Modal>
  )
}


type FieldsSectionProps = {
  fieldsConfig?: FieldRow[]
  fieldsState?: Record<string, any>
  onChangeFieldsState?: (fieldId: string, value: any) => void
  title?: string,
  fields?: InfoItemType[]
}
const FieldsSection = (props: FieldsSectionProps) => {
  const {
    fieldsConfig,
    fieldsState,
    onChangeFieldsState,
    title,
    fields,
  } = props
  return (
    <div className={styles.sectionWrapper}>
      {title ? <Text as={'p'} size={'m'} weight='bold' children={title} /> : null}
      {
        !fields ? (
          <GeneratorFields
            visible={true}
            visibleValuesPreview={false}
            gapSize={'s'}
            fieldsConfig={fieldsConfig}
            valuesState={fieldsState || {}}
            onChangeField={onChangeFieldsState}
          />
        ) : (
          <div className={styles.sectionFieldsWrapper}>
            {props.fields.map((item, i) => {
              const value = `${item.value}`
              return (
                <InputWrapper
                  key={i}
                  size={'s'}
                  label={item.label}
                  value={value}
                  className={styles.fieldItem}
                  disabled
                />
              )
            })}
          </div>
        )
      }
    </div>
  )
}


Item.displayName = 'Item'

export default Item
