import React, { useMemo } from 'react'
import { Grid } from '@consta/uikit/Grid'
import SectionTitle from '@pages/DirectoriesPage/components/SectionTitle'
import { GeneratorFields } from '@widgets/GeneratorFields'
import { Button } from '@shared/ui/btns/Button'
import { Flex } from '@shared/ui/Flex'
import { BoolConditionalProps } from '@shared/types/helpers/condition'
import DirectoriesTable from '@pages/DirectoriesPage/components/DirectoriesTable'
import { getFiltersConfig } from '@pages/DirectoriesPage/components/DirectoriesLayout/utils'
import { IconAdd } from '@consta/icons/IconAdd'
import { TableColumnType, TableRowType } from '@/components/Table/TanStackTable'


type GeneratorTypes = React.ComponentProps<typeof GeneratorFields>
type DirectoriesTableTypes = React.ComponentProps<typeof DirectoriesTable>

type CreateBtnProps = BoolConditionalProps<
  'withCreate', {
  createBtnLabel?: string,
  onCreateBtnClick?: () => void
  createBtnNode?: React.ReactNode
}>
type DirectoriesTableProps = BoolConditionalProps<'withTable', {
  withCopy?: boolean
  columns: TableColumnType[]
  rows: TableRowType[]
}>
type FilterProps = BoolConditionalProps<'withFilter', {
  filtersConfig?: GeneratorTypes['fieldsConfig']
  withSearch?: boolean
  filterState:  GeneratorTypes['valuesState']
  handleChangeFilter:  GeneratorTypes['onChangeField']
}>


type DirectoriesLayoutProps = {
	title: string
  isLoading?: boolean
  pagination?: DirectoriesTableTypes['pagination']
  onChangePage?: DirectoriesTableTypes['onChangePage']
  children?: React.ReactNode
  extraHeader?: React.ReactNode
} & CreateBtnProps & FilterProps & DirectoriesTableProps

export const DirectoriesLayout = (props: DirectoriesLayoutProps) => {
  const {
    title,
    withCreate,
    withFilter,
    withTable,

    withSearch = true,

    createBtnLabel = 'Добавить',
    onCreateBtnClick,
    createBtnNode,

    filtersConfig,
    filterState,
    handleChangeFilter,

    withCopy = true,
    rows,
    columns,
    isLoading,

    pagination,
    onChangePage,
    extraHeader,

    children
  } = props

  const config = useMemo(() => getFiltersConfig(filtersConfig, withSearch), [filtersConfig, withSearch])

  return (
		<Grid gap={'s'}>
      <SectionTitle title={title} />

      <Flex fullWidth={true} justifyContent={'between'} align={'end'}>
        <>
        {
          withFilter ? (
            <GeneratorFields
              size={'s'}
              gapSize={'xs'}
              visible
              fieldsConfig={config}
              valuesState={filterState || {}}
              onChangeField={handleChangeFilter}
              visibleValuesPreview={false}
            />
          ) : null
        }
        {
          withCreate ? (
            createBtnNode
              ? createBtnNode
              : (
                <Button
                  size={'s'}
                  iconSize={'s'}
                  label={createBtnLabel}
                  iconLeft={IconAdd}
                  onClick={onCreateBtnClick}
                />
              )
          ) : null
        }
        </>
      </Flex>
      {
        withTable ? (
          <DirectoriesTable
            extraHeader={extraHeader}
            withCopy={withCopy}
            isLoading={isLoading}
            rows={rows}
            columns={columns}
            pagination={pagination}
            onChangePage={onChangePage}
          />
        ) : null
      }
      {children}
    </Grid>
	);
};