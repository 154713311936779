import { Link } from 'react-router-dom'
import React from 'react'
import { IDocumentStatuses } from '@/interfaces/documentStatuses'
import { Text } from '@shared/ui/Text'
import { generateDirectoriesPath } from '@shared/routing/config/paths'
import { TableColumnType, TableRowType } from '@/components/Table/TanStackTable'

export const generateTableData = (
  types: IDocumentStatuses[],
  search: string,
  page: string,
  section: string,
) => {
  let rows: TableRowType[] = types.map((item) => {
    return {
      id: item.value,
      title: item.title,
      __custom_hrefLink: generateDirectoriesPath({
        page,
        section,
        type: 'item',
        id: item.value,
      }),
    }
  })
  if (search) {
    rows = rows.filter(row => row.title.includes(search))
  }
  const columns: TableColumnType[] = [
    {
      header: 'Наименование',
      id: 'title',
      accessorKey: 'title',
      meta: {
        generateLink: (row, column) => row.original.__custom_hrefLink,
      },
      cell: ({ row }) => (
        <Link to={row.original.__custom_hrefLink}>
          <Text view={'link'} children={row.original.title} />
        </Link>
      ),
    },
  ]
  return { rows, columns }
}
