import {
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  RowSelectionState,
  Updater,
  useReactTable,
} from '@tanstack/react-table'
import { TableSettingsFeature } from '@/components/Table/TanStackTable/lib/features/tableSettings/TableSettingsFeature'
import { CustomSortingFeature } from '@/components/Table/TanStackTable/lib/features/customSorting/CustomSortingFeature'
import {
  ExpandExtensionFeature,
} from '@/components/Table/TanStackTable/lib/features/enpandExtension/ExpandExtensionFeature'
import { ContextMenuFeature } from '@/components/Table/TanStackTable/lib/features/contextMenu/ContextMenuFeature'
import { TableRowType, TanStackTableProps } from '../TanStackTable'
import React, { useCallback, useImperativeHandle, useMemo } from 'react'
import { useTableState } from '@/components/Table/TanStackTable/hooks/useTableState'
import { usePreManageTable } from '@/components/Table/TanStackTable/hooks/usePreManageTable'
import { useVirtualizer } from '@tanstack/react-virtual'
import { SettingsConfig } from '@/components/Table/TanStackTable/components/TableSettingsMenu/TableSettingsMenu'
import { CopyFeature } from '@/components/Table/TanStackTable/lib/features/copyFeature/CopyFeature'
import {
  CommonExtensionFeature,
} from '@/components/Table/TanStackTable/lib/features/commonExtension/CommonExtensionFeature'
import { DensityFeature } from '@/components/Table/TanStackTable/lib/features/density/DensityFeature'


type Props<TRowData, TRowValue> = TanStackTableProps<TRowData, TRowValue> & {
  tableContainerRef: React.RefObject<HTMLDivElement>
}

export const useTable = <TRowData extends TableRowType, TRowValue>(props: Props<TRowData, TRowValue>) => {
  const {
    id: tableId,
    pageId,
    /* todo придумать как передавать эти данные сверху, так как дефолтно назначенные данные приходится дублировать */
    withCopy = true,
    withHeader = true,
    withColumnSettings = true,
    withExpand = true,
    withSorting = true,
    withColumnResizing = true,
    withRowSelection = false,
    withNumerableRows = false,

    onRowSelectionChange,

    tableRef,

    sorting,
    columnPinningDefault,
    setSorting,

    tableContainerRef,

    extraDensity,
  } = props

  const {
    pinnedTotalRowsIndexes,
    allInitialRows,
    columnsWithExtra, // todo нужно будет при пагинации добавлять(обновить с учетом стр. пагинации) значения для нумерации
    columnsDefaultHidden,
  } = usePreManageTable<TRowData, TRowValue>({ ...props, withNumerableRows })


  const {
    columnsVisibilityState,
    tableSettingsMenu,
    contextMenuState,
    rowSelectionState,
    tableRowsMaxDepthState,
    copyTableState,
    densityState,
    fullScreenState
  } = useTableState({
    tableId,
    pageId,
    extraDensity: extraDensity,
    columnsVisibleHiddenDefault: columnsDefaultHidden,
  })

  /*
    Метод получения строк по выделенным
      ToDo пдумать куда вынести
   */
  const handleOnRowSelectionChange = useCallback(
    (valueFn: Updater<RowSelectionState>) => {
      if (typeof valueFn === "function") {
        const updatedRowSelection = valueFn(rowSelectionState.rowSelection);
        rowSelectionState.setRowSelection(updatedRowSelection);

        const selectedRows = Object.keys(updatedRowSelection).reduce(
        (acc, key) => {
          if (updatedRowSelection[key]) {
            const index = parseInt(key, 10);
            const row = allInitialRows[index];
            if (row) {
              acc.push(row);
            }
          }
          return acc;
        }, []);

        onRowSelectionChange?.(selectedRows);
        return
      }
      rowSelectionState.setRowSelection(valueFn);
    },
    [allInitialRows, onRowSelectionChange, rowSelectionState.rowSelection]
  );

  /* Таблица */
  const table = useReactTable<TRowData>({
    _features: [
      DensityFeature,
      CommonExtensionFeature,
      TableSettingsFeature,
      CustomSortingFeature,
      ExpandExtensionFeature,
      ContextMenuFeature,
      CopyFeature
    ],
    data: allInitialRows,
    columns: columnsWithExtra,

    enableCopy: withCopy,
    enableHiding: withColumnSettings && withHeader, // добавить возможность скрывать / раскрывать столбцы
    enableExpanding: withExpand,
    enableRowSelection: withRowSelection,
    enableCustomSorting: withSorting,

    defaultColumn: {
      enableResizing: true,
      size: 200,
      minSize: 100, //enforced during column resizing
      maxSize: 500,
    },
    state: {
      isFullScreenOn: fullScreenState.isFullScreenOn,
      density: densityState.density,
      copyState: copyTableState.copyState,

      tableRowsMaxDepth: tableRowsMaxDepthState.tableRowsMaxDepth,
      contextMenu: contextMenuState.contextMenu,
      rowSelection: rowSelectionState.rowSelection,
      customSorting: sorting,
      columnVisibility: columnsVisibilityState.columnVisibility,
      tableSettingsVisible: tableSettingsMenu.visibleTableSettings,
      columnPinning: {
        left: columnPinningDefault?.left,
        right: columnPinningDefault?.right,
      },
      rowPinning: {
        // вычисляем индексы итоговых строк для закрепления
        bottom: pinnedTotalRowsIndexes,
      },
    },

    enableColumnResizing: withColumnResizing,
    columnResizeMode: 'onChange',

    manualPagination: true,

    onFullScreenChange: fullScreenState.setFullScreen,
    onCopyStateChange: copyTableState.setCopyState,
    onDensityChange: densityState.setDensity,
    onTableRowsMaxDepthChange: tableRowsMaxDepthState.setTableRowsMaxDepth,
    onContextMenuChange: contextMenuState.setContextMenu,
    onRowSelectionChange: handleOnRowSelectionChange,
    onCustomSortingChange: setSorting,
    onColumnVisibilityChange: columnsVisibilityState.setColumnVisibility,
    onTableSettingsVisibleChange: tableSettingsMenu.setTableSettingsVisible,


    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSubRows: (data) => data.subItems as TRowData[],
    // debugTable
    // debugTable: true,
    // debugColumns: true,
  })

  // @ts-ignore
  useImperativeHandle(tableRef, () => table);

  const { rows: tableRows } = table.getRowModel()
  // console.log(flatRows, tableRows)
  /* Все столбцы */
  const allColumns = table.getAllColumns()

  /* Закрепленные снизу строки */
  const pinnedBottomRows = table.getBottomRows()

  /* Строки без закрепленных */
  const staticRows = useMemo(() =>
      tableRows.filter(row => !pinnedBottomRows.find(pRow => pRow.id === row.id)),
    [pinnedBottomRows, tableRows],
  )

  /* Виртуализация аблицы */
  const rowVirtualizer = useVirtualizer({
    count: staticRows.length,
    estimateSize: () => 50, //estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== 'undefined' &&
      navigator.userAgent.indexOf('Firefox') === -1
        ? element => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  })


  const tableSettingsMenuConfig: SettingsConfig = useMemo(() => {
    const columnsHiding = table.options.enableHiding
    const density = table.options.enableDensity
    return {
      columnsHiding,
      density,

    }
  }, [table.options])

  const tableSettingsMenuButtonVisible = Object.values(tableSettingsMenuConfig).some(Boolean)


  return {
    table,
    tableState: table.getState(),
    allColumns,

    tableRows,
    staticRows,
    pinnedBottomRows,
    rowVirtualizer,

    tableSettingsMenuConfig,
    tableSettingsMenuButtonVisible
  }
}