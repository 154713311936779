import {
  IPhysicalSetOrderListParams,
  useChangePhysicalSetPackage,
  useCloseTare,
  useCreateEditPhysicalSetPlace,
  useFetchPhysicalSetOrderPlace,
  useGenerateTare,
  useGetPackagingsV2,
  useGetPhysicalSet,
  useGetPhysicalSetList,
  useQueryGetPackagings,
  useResetPackingPhysicalSet,
  useStartPackingPhysicalSet,
} from '@/hooks'
import {
  IContainer,
  IPackageDto,
  IPagination,
  IPhysicalSetOrderDto,
  ISku,
  PackageSkuDto,
  PackagingPurposes,
} from '@/interfaces'
import { useParams } from 'react-router-dom'
import { useAppContext } from '@shared/providers/AppContextProvider'
import { useEffect } from 'react'
import useCompletePackingPhysicalSet from '@/hooks/physicalSet/useCompletePackingPhysicalSet'


interface IProps {
  physicalSetListParams?: IPhysicalSetOrderListParams
  onSuccessPhysicalSetList?: (orders: IPhysicalSetOrderDto[], pagination: IPagination) => void
  onSuccessPhysicalSet?: (order: IPhysicalSetOrderDto, containers: IContainer[], skus: ISku[]) => void
  onSuccessPackagings?: (data: IPackageDto[]) => void
  onSuccessPackagingsOptions?: (packagingsSkus: PackageSkuDto[]) => void
}

const usePhysicalSetRequests = (props: IProps) => {
  const { docId } = useParams<{ docId: string }>()
  const {
    setGlobalLoading
  } = useAppContext()

  const { mutateAsync: requestResetPacking } = useResetPackingPhysicalSet()
  const { mutateAsync: requestCompletePacking } = useCompletePackingPhysicalSet()
  const { mutateAsync: requestStartPacking } = useStartPackingPhysicalSet()
  const { mutateAsync: requestGenerateTare } = useGenerateTare()
  const { mutateAsync: requestCloseTare } = useCloseTare()
  const { isLoading: createEditPlaceLoading, mutateAsync: requestCreateEditPhysicalSetPlace } = useCreateEditPhysicalSetPlace()
  const { mutateAsync: requestChangePhysicalSetPackage } = useChangePhysicalSetPackage()

  const getPackagings = useGetPackagingsV2({ documentId: docId }, {
    onSuccess: (data) => {
      props.onSuccessPackagingsOptions?.(data.skus)
    }
  })

  const { fetch: fetchPhysicalSetOrderPlace } = useFetchPhysicalSetOrderPlace()

  const physicalSetListQuery = useGetPhysicalSetList(props.physicalSetListParams, {
    enabled: false,
    onSuccess: (data) => {
      props.onSuccessPhysicalSetList?.(data.orders, data.pagination)
    },
  })
  const physicalSetQuery = useGetPhysicalSet({ order_id: docId }, {
    enabled: false,
    onSuccess: (data) => {
      props.onSuccessPhysicalSet?.(data.order, data.containers, data.skus)
    },
  })
  const packagingsQuery = useQueryGetPackagings({ purpose: PackagingPurposes.physicalSet }, {
    enabled: false,
    onSuccess: (data) => {
      props.onSuccessPackagings?.(data.packagings)
    },
  })

  useEffect(() => {
    const loading = (
      physicalSetListQuery.isFetching
      || physicalSetQuery.isFetching
    )
    setGlobalLoading(loading)
  }, [physicalSetListQuery.isFetching, physicalSetQuery.isFetching])

  return {
    requestCompletePacking,
    requestResetPacking,
    requestStartPacking,
    requestGenerateTare,
    requestCloseTare,
    requestCreateEditPhysicalSetPlace,
    requestChangePhysicalSetPackage,

    physicalSetOrderPlaceQuery: { refetch: fetchPhysicalSetOrderPlace },
    physicalSetListQuery,
    physicalSetQuery,
    packagingsQuery
  }
}

export default usePhysicalSetRequests