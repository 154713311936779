import { Link } from 'react-router-dom'
import { IInstruction } from '@/interfaces/instructions'
import { Text } from '@shared/ui/Text'
import React from 'react'
import { generateDirectoriesPath } from '@shared/routing/config/paths'
import { TableColumnType, TableRowType } from '@/components/Table/TanStackTable'

export const generateTableData = (
  instructions: IInstruction[],
  page: string,
  section: string,
) => {
  const rows: TableRowType[] = instructions.map((item) => {
    return {
      id: item.id,
      delivery_company: item.delivery_company || '-',
      title: item?.title || '',
      __custom_hrefLink: generateDirectoriesPath({
        page,
        section,
        type: 'item',
        id: item.id,
      }),
    }
  })

  const columns: TableColumnType[] = [
    {
      header: 'Служба доставки',
      id: 'delivery_company',
      accessorKey: 'delivery_company',
    },
    {
      header: 'Наименование',
      id: 'title',
      accessorKey: 'title',
      meta: {
        generateLink: (row, column) => row.original.__custom_hrefLink,
      },
      cell: ({ row }) => (
        <Link to={row.original.__custom_hrefLink}>
          <Text view={'link'} children={row.original.title} />
        </Link>
      ),
    },
  ]
  return { rows, columns }
}
