import { IContractorDto } from '@/interfaces'
import { EditRemoveButtons } from '@pages/DirectoriesPage/components/EditRemoveButtons/EditRemoveButtons'
import React from 'react'
import { generateDirectoriesPath } from '@shared/routing/config/paths'
import { Text } from '@shared/ui/Text'
import { Link } from 'react-router-dom'
import { TableColumnType, TableRowType } from '@/components/Table/TanStackTable'

export const generateTableData = (
  contractors: IContractorDto[],
  search: string,
  handleConfirmModal: (code: string) => void,
  handleEdit: (code: string) => void,
  page: string,
  section: string,
) => {
  const rows: TableRowType[] = (contractors || []).map((item) => {
    return {
      id: item.id,
      title: item.title,
      inn: item.inn,
      kpp: item.kpp,
      ogrn: item.ogrn,
      __custom_hrefLink: generateDirectoriesPath({page, section, type: 'item', id: item.id}),
    }
  })
  const columns: TableColumnType[] = [
    {
      header: 'Наименование',
      id: 'title',
      accessorKey: 'title',
      size: 500,
      meta: {
        generateLink: (row, column) => row.original.__custom_hrefLink,
      },
      cell: ({ row }) => {
        return (
          <Link to={row.original.__custom_hrefLink}>
            <Text view={'link'} children={row.original.title} />
          </Link>
        )
      },
    },
    {
      header: 'ИНН',
      id: 'inn',
      accessorKey: 'inn',
      size: 200,
    },
    {
      header: 'КПП',
      id: 'kpp',
      accessorKey: 'kpp',
      size: 200,
    },
    {
      header: 'ОГРН',
      id: 'ogrn',
      accessorKey: 'ogrn',
      size: 200,
    },
    {
      header: '',
      id: 'edit__action',
      accessorKey: 'edit__action',
      size: 50,
      cell: ({ row }) => {
        return (
          <EditRemoveButtons
            withEdit
            withRemove
            onEditClick={() => handleConfirmModal(row.original.id)}
            onRemoveClick={() => handleEdit(row.original.id)}
          />
        )
      },
    },
  ]
  return { rows, columns }
}
