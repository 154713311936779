import { useMutation } from 'react-query'

import api from '@shared/api'
import { ApiReqResult } from 'src/interfaces'
import { UseMutationOptions } from 'react-query/types/react/types'


type UploadImageType = {
  data: string
  title: string
}
interface Params {
  skuId: string,
  data: UploadImageType[]
}

interface Result extends ApiReqResult {
  results: (ApiReqResult & { n: string, id: string })[]
}

const useAddSkuItemImage = (options?: UseMutationOptions<Result, any, Params>) => {
  return useMutation<Result, any, Params>(
    async (params: Params) => {
      const result = await api.post(`/items/${params.skuId}/image`, params.data)
      if (!result?.data?.success) {
        throw result
      }
      return result.data
    },
    options
  )
}

export default useAddSkuItemImage
