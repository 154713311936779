import React, { useEffect, useMemo, useState } from 'react'

import {
  AdditionalPackagingModal,
  ExpDateModal,
  InstructionModal,
  ItemCountModal,
  ItemScanSerialModal,
  WeightDimensionsModal,
} from 'src/components'
import { SkuItemPreview } from '@entities/Sku/ui/SkuItemPreview'
import {
  CommonSerialNumberFilled,
  IAddPack,
  ICachedPackage,
  IOrderPackagingOption,
  ISerialNumber, ISku, NewPackageDto, PackagingPurposes,
} from 'src/interfaces'
import { ItemScanSerialModalProps } from 'src/components/ItemScanSerialModal/ItemScanSerialModal'
import AdditionalPackagingV2Modal from '@/components/widgets/AdditionalPackagingModal/AdditionalPackagingV2Modal'
import { usePhysicalSetContext } from '@pages/AssemblingPhysicalSet/context/PhysicalSetContext'

// Процесс отличается от того, что есть в упаковке, по этому требуется
//  переработка в самой упаковке, чтобы его там можно было использовать

/* Конфиг для работы с обработкой товара */
type ItemProcessType = {
  id: string
  image: string
  title: string
  weight?: number
  weight_plan?: number
  require_batch_expdate?: boolean,
  scan_serial_numbers?: ISerialNumber[],
  instruction_id?: string,
  alreadyShowInstruction?: boolean,
  needAddPack?: boolean,
  barcode_used?: string
  require_weight?: boolean
  require_dimensions?: boolean
  expiryMonths?: number
  expiryDaysLimitShipments?: number
  packagings?: IAddPack[]
}

/* Кешированные данные  */
type CacheDataType = {
  cachedSerials?: string[],

  setCachedPackages: (cachedPackages: ICachedPackage) => void
  cachedPackages: Record<string, string>
}

/* Данные, которые возвращаем на выходе */
export type ItemProcessResultType = {
  sku_id: string
  quantity: number
  weight?: number
  packagings?: IAddPack[]
  serial_numbers?: CommonSerialNumberFilled[]
  expirationDate?: string
  dateOfManufacture?: string
}

interface IProps {
  withSkipSerialNumbers?: boolean /* Параметр, который добавляет копку пропустка серийных номеров */
  serialNumbersExtraProps?: Partial<ItemScanSerialModalProps>,
  addingItem: ItemProcessType
  packagingOptions: IOrderPackagingOption[]
  onClose: () => void
  onComplete: (item: ItemProcessResultType) => void
  blurOrFocusBarcodeInput: (type: 'focus' | 'blur') => void
  indicateItemCount?: boolean
  isNeedEnterWeigthDimensions?: boolean
  checkCorrectWeight?: (weight: number) => boolean
  checkItemQuantityIsCorrect?: (skuId: string, count: number) => boolean
  addWeightToCache?: (itemId: string, weight: number) => void
  cache?: CacheDataType
}

export const SkuItemAddProcess = (props: IProps) => {
  const {
    withSkipSerialNumbers,
    indicateItemCount = false,
    isNeedEnterWeigthDimensions = false,

    addingItem,
    onClose,
    onComplete,
    packagingOptions,
    blurOrFocusBarcodeInput,
    checkCorrectWeight,
    checkItemQuantityIsCorrect,
    addWeightToCache,
    cache,
    serialNumbersExtraProps,
  } = props

  const [currentModal, setCurrentModal] = useState<string | null>(null)
  const [count, setCount] = useState<string>('')
  const [interimResult, setInterimResult] = useState<ItemProcessResultType>({ quantity: 1, sku_id: addingItem.id })

  const {
    state,
    state: {
      allSkus,
      skusPackagings,
      physicalSetOrder,
      packagings,
      cacheSerialNumbers,
      addingSkuItem,
      orderPlaces,
      showPhysicalSetPlace,
      weightDimensionsPlace,
      showChangePackagingPlace,
      physicalSetPlaceChz
    },
    dispatch: d,
  } = usePhysicalSetContext()

  useEffect(() => {
    if (!addingItem) return

    blurOrFocusBarcodeInput('blur')
    return () => {
      blurOrFocusBarcodeInput('focus')
    }
  }, [])

  useEffect(() => {
    if (!addingItem) return

    gotoNextStep({})
  }, [])

  const handleAddItem = (resultItem: ItemProcessResultType) => {
    onComplete(resultItem)
  }

  const getModalsArr = () => {
    const modalsArr = []

    const needSerialNumbers = addingItem?.scan_serial_numbers?.length
    const needExpirationDate = addingItem?.require_batch_expdate
    const needInstruction = addingItem.instruction_id && !addingItem.alreadyShowInstruction
    const needAdditionalPack = addingItem?.needAddPack

    if (needInstruction) {
      modalsArr.push('itemInstruction')
    }
    if (isNeedEnterWeigthDimensions) { // todo подумать как сделать норм
      modalsArr.push('weigthDimensions')
    }
    if (
      indicateItemCount // Включен флаг множественного добавления товара
      && !needSerialNumbers // нет серийных номеров для сканирования
      && (!needExpirationDate) // нет сроков годности (СГ) или есть флаг отмены (СГ)
    ) {
      modalsArr.push('itemCount')
    }
    if (needAdditionalPack) {
      modalsArr.push('addPack')
    }
    if (needExpirationDate) {
      modalsArr.push('dateExp')
    }
    if (needSerialNumbers) {
      modalsArr.push('scanSerial')
    }
    return modalsArr
  }

  const gotoNextStep = (resultItem: Partial<ItemProcessResultType>) => {
    const result = { ...interimResult, ...resultItem }
    setInterimResult(result)
    const modalsArr = getModalsArr()
    const currentIndex = modalsArr.findIndex((m: string) => m === currentModal)
    if (currentIndex < modalsArr.length - 1) {
      setCurrentModal(modalsArr[currentIndex + 1])
    } else {
      handleAddItem(result)
    }
  }

  const handleInstructionAccept = () => {
    gotoNextStep({})
  }

  const handleDateExpAdding = (expDate: { expirationDate?: string, dateOfManufacture?: string }) => {
    gotoNextStep(expDate)
  }

  const handleScanSerial = (filledSerial: CommonSerialNumberFilled[]) => {
    gotoNextStep({ serial_numbers: filledSerial })
  }

  const handleAddCount = (count: number) => {
    // Проверяем на допустимый вес для кол-ва товара
    const weightIsCorrect = checkCorrectWeight?.((interimResult.weight ?? 1000) * count)
    if (weightIsCorrect !== undefined && !weightIsCorrect) {
      return
    }
    // Проверяем на допустимое кол-во
    const countIsCorrect = checkItemQuantityIsCorrect?.(addingItem.id, count)
    if (countIsCorrect !== undefined && !countIsCorrect) {
      return
    }

    const newPackaging = addingItem.packagings
      ? [{
        packaging_id: addingItem.packagings?.[0]?.packaging_id,
        quantity: 1,
      }]
      : undefined
    gotoNextStep({
      packagings: newPackaging,
      quantity: count,
    })
  }

  const handleEnterWeigthDimensions = (weight?: number) => {
    gotoNextStep({ weight })
  }

  const handleAdditionalPackagingAdding = (packageId: string) => {
    gotoNextStep({
      packagings: [
        {
          packaging_id: packageId,
          quantity: 1,
        },
      ],
    })
  }

  const newPackagings = useMemo(() =>
      skusPackagings
        .filter(packaging => packaging.purpose === PackagingPurposes.physicalSet)
        .map(packaging => packaging.packagings)
        .flat(),
    []
  );

  if (!addingItem) return null


  return (
    <>
      {currentModal === 'itemInstruction' ? (
        <InstructionModal
          isOpen={true}
          withoutTitle={true}
          headerTitle={'Руководство по упаковке'}
          onClose={handleInstructionAccept}
          onAccept={handleInstructionAccept}
          instructionId={addingItem.instruction_id || 'mock'}
        />
      ) : null}

      {currentModal === 'itemCount' ? (
        <ItemCountModal
          count={count}
          item={{
            ...addingItem,
            barcode: addingItem.barcode_used ?? '',
          }}
          isOpen={true}
          setCount={setCount}
          onItemAdding={handleAddCount}
          onClose={onClose}
        />
      ) : null}

      {currentModal === 'dateExp' ? (
        <ExpDateModal
          item={{ ...addingItem, barcode: addingItem.barcode_used }}
          newItems={[addingItem]}
          isOpen={true}
          onDateExpAdding={(items) =>
            handleDateExpAdding({
              expirationDate: items[0].expirationDate,
              dateOfManufacture: items[0].dateOfManufacture,
            })
          }
          onClose={onClose}
        />
      ) : null}

{/*      {currentModal === 'addPack' ? (
        <AdditionalPackagingModal
          isOpen
          withOverlay
          withConfirm={true}
          item={addingItem}
          packages={packagingOptions}
          onAdditionalPackagingAdding={handleAdditionalPackagingAdding}
          cachedPackages={cache?.cachedPackages}
          setCachedPackages={cache?.setCachedPackages}
          onClose={onClose}
        />
      ) : null}*/}


      {currentModal === 'addPack' ?
        <AdditionalPackagingV2Modal
          isOpen
          withEmptyPackagings={false}
          allSkus={allSkus}
          onClose={onClose}
          skuPackagings={newPackagings}
          // @ts-ignore
          onSubmit={handleAdditionalPackagingAdding}
        />
        : null}

      {currentModal === 'weigthDimensions' ? (
        <WeightDimensionsModal
          isOpen
          item={addingItem}
          onClose={onClose}
          onSubmit={handleEnterWeigthDimensions}
          withClose
          checkPlaceWeight={checkCorrectWeight}
          addWeightToCache={addWeightToCache}
          needUpdateWeightAndDimensions={
            addingItem.require_weight || addingItem.require_dimensions
          }
          top={
            <SkuItemPreview
              image={addingItem.image}
              title={addingItem.title}
              barcode={addingItem.barcode_used ?? ''}
            />
          }
        />
      ) : null}

      {currentModal === 'scanSerial' ? (
        <ItemScanSerialModal
          withSkipSerialNumbers={withSkipSerialNumbers}
          isOpen={true}
          onClose={onClose}
          itemId={addingItem.id}
          itemImage={addingItem?.image}
          itemTitle={addingItem?.title}
          itemBarcodeUsed={addingItem?.barcode_used}
          itemScanSerialNumbers={addingItem?.scan_serial_numbers}
          onSubmit={handleScanSerial}
          alreadyUsedSerialNumbers={cache?.cachedSerials}
          {...serialNumbersExtraProps}
        />
      ) : null}
    </>
  )
}

export default SkuItemAddProcess
