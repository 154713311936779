import { Button } from '@shared/ui/btns/Button'
import React from 'react'
import { IUserRender } from '@pages/DirectoriesPage/pages/UsersSection/types'
import { ActivationBadge } from '@shared/ui/Badges/ActivationBadge'
import { TableColumnType } from '@/components/Table/TanStackTable'

export const getUsersTableColumns = (
  users: IUserRender[],
  handleQrCode: (userId: string) => void,
  isLoading: boolean
): TableColumnType[] => [
    {
      header: 'ФИО',
      accessorKey: 'fioEl',
      id: 'fioEl',
      meta: {
        copyKey: 'fioEl__copy',
      },
      cell: ({ row }) => row.original.fioEl
    },
    {
      header: 'Логин',
      accessorKey: 'loginEl',
      id: 'loginEl',
      meta: {
        copyKey: 'loginEl__copy',
      },
      cell: ({ row }) => row.original.loginEl
    },
    {
      header: 'Пароль',
      accessorKey: 'passwordEl',
      id: 'passwordEl',
      meta: {
        copyKey: 'passwordEl__copy',
      },
      cell: ({ row }) => row.original.passwordEl,
    },
    {
      header: 'Компания',
      accessorKey: 'companyEl',
      id: 'companyEl',
      meta: {
        copyKey: 'companyEl__copy',
      },
      cell: ({ row }) => row.original.companyEl,
    },
    {
      header: 'Роль',
      accessorKey: 'rolesEl',
      id: 'rolesEl',
      meta: {
        copyKey: 'rolesEl__copy',
      },
      cell: ({ row }) => row.original.rolesEl,
      enableSorting: true,
      size: 300
    },
    {
      header: 'Должность',
      accessorKey: 'positionEl',
      id: 'positionEl',
      meta: {
        copyKey: 'positionEl__copy',
      },
      cell: ({ row }) => row.original.positionEl,
      enableSorting: true,
    },
    {
      header: 'Активность',
      accessorKey: 'enabled__action',
      id: 'enabled__action',
      enableSorting: false,
      cell: ({ row }) => {
        return (
          <ActivationBadge size={row.getTableDensity()} active={row.original.enabled} />
        )
      }
    },
    {
      header: 'Печать QR кода',
      accessorKey: 'id__action',
      id: 'id__action',
      enableSorting: false,
      cell: ({ row }) => {
        return (
          <Button
            onlyIcon
            size={'xs'}
            iconSize={'s'}
            view={'ghost'}
            label={'Печать'}
            loading={isLoading}
            onClick={() => handleQrCode(row.original.id)}
          />
        )
      }
    },
    {
      header: 'Редактировать',
      accessorKey: 'buttonEl__action',
      id: 'buttonEl__action',
      enableSorting: false,
      cell: ({ row }) => row.original.buttonEl__action,
    },
  ]