import { ICarrierCompany } from '@/interfaces'
import { getFullAddress } from '@shared/helpers'
import React from 'react'
import { ActivationBadge } from '@shared/ui/Badges/ActivationBadge'
import { EditRemoveButtons } from '@pages/DirectoriesPage/components/EditRemoveButtons/EditRemoveButtons'
import { TableColumnType, TableRowType } from '@/components/Table/TanStackTable'

export const generateTableData = (
  companies: ICarrierCompany[],
  handleEdit: (company: ICarrierCompany) => void,
  handleDeleteRevert: (companyId: string, isDelete: boolean) => void,
) => {
  const rows: TableRowType[] = companies.map(company => ({
    id: company?.id?.toString() || '',
    title: company?.title?.toString() || '',
    phone: company?.phone?.toString() || '',
    address: getFullAddress(company?.legal_address || {}, []),
    inn: company?.inn?.toString() || '',
    kpp: company?.kpp?.toString() || '',
    ogrn: company?.ogrn?.toString() || '',
    company: company,
    deleted: company.deleted,
    actions: ''
  }))

  const columns: TableColumnType[] = [
    {
      header: 'Название',
      enableSorting: true,
      id: 'title',
      accessorKey: 'title',
      size: 200,
    },
    {
      header: 'Тел.',
      id: 'phone',
      accessorKey: 'phone',
      size: 200,
    },
    {
      header: 'Адрес',
      id: 'address',
      accessorKey: 'address',
      size: 200,
    },
    {
      header: 'ИНН',
      id: 'inn',
      accessorKey: 'inn',
      size: 200,
    },

    {
      header: 'Статус',
      id: 'deleted__action',
      accessorKey: 'deleted__action',
      cell: ({ row }) => {
        return (
          <ActivationBadge size={row.getTableDensity()} active={!row.original.deleted} />
        )
      }
    },
    {
      header: '',
      size: 150,
      id: 'actions__action',
      accessorKey: 'actions__action',
      cell: ({ row }) => {
        return (
          <EditRemoveButtons
            withEdit
            withRemove
            onEditClick={() => handleEdit(row?.original.company)}
            onRemoveClick={() => handleDeleteRevert(row.original.id, !row.original.deleted)}
          />
        )
      },
    },
  ]
  return { rows, columns }
}
