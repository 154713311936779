import React, { FC, useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { IRouteParams } from '@pages/DirectoriesPage/types'
import { useGetMerchantList } from '@/hooks'
import { useFilterState } from '@shared/hooks'
import { generateFilterParams, generateFilters } from './utils/filters'
import { SECTIONS } from '../../../../constants'
import { GeneratorFields } from '@widgets/GeneratorFields'
import { generateTableData } from './utils/table'
import DirectoriesTable from '../../../../components/DirectoriesTable/DirectoriesTable'
import useUpdateMerchant from '@/hooks/merchant/useUpdateMerchant'

interface ListProps {
  
}

const List: FC<ListProps> = () => {
  const { page = '', section = SECTIONS.merchants.value } = useParams<IRouteParams>()

  const {
    filterStateDebounced,
    filterState,
    handleChangeFilter
  }  = useFilterState({withoutHistory: true, onChangeFilterState: () => {
    setPagination(0)
  }})
  const [pagination, setPagination] = useState(0)

  const {
    isFetching: isFetchingMerchant,
    refetch: refetchMerchant,
    data: merchantData
  } = useGetMerchantList({
    params: generateFilterParams({ ...filterStateDebounced, page: pagination } || {}),
  })
  const {
    isLoading: updateMerchantIsLoading,
    mutateAsync: updateMerchant,
  } = useUpdateMerchant()

  const filtersConfig = generateFilters()

  const handleClickActionBtn = useCallback(async (value: boolean, row: typeof rows[0]) => {
    try {
      await updateMerchant({ merchant_id: row.id, enabled: value, service_group_set_id: row.service_group_set_id })
      refetchMerchant()
    } catch (e) {
      console.error(e)
    }
  }, [])

  const {rows, columns} = useMemo(() => {
    return generateTableData(
      merchantData?.merchants || [],
      page,
      section,
      handleClickActionBtn
    )
  }, [merchantData?.merchants, handleClickActionBtn])


  return (
    <>
      <GeneratorFields
        visible
        fieldsConfig={filtersConfig}
        valuesState={filterState || {}}
        onChangeField={handleChangeFilter}
      />

      <DirectoriesTable
        withCopy
        isLoading={isFetchingMerchant || updateMerchantIsLoading}
        columns={columns}
        rows={rows}
        pagination={merchantData?.pagination}
        onChangePage={setPagination}
      />
    </>
  )
}

export default List
