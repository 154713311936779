import React from 'react'

import styles from './SkuItemPreview.module.scss'

import { Img } from '@shared/ui/Img'

import { convertWeight, getBatchStr, hideSymbols } from '@shared/helpers'
import noImg from '@shared/assets/images/noImage.png'
import { ItemPreviewType } from '@/interfaces'
import cx from 'classnames'

import isMobile from 'ismobilejs'
import { Flex } from '@shared/ui/Flex'
import { Text } from '@shared/ui/Text'
import { useCollapseText } from '@shared/hooks/useCollapseText'

interface ItemPreviewProps extends ItemPreviewType {
  direction?: 'horizontal' | 'vertical'
  weight?: string | number
  withOpenBarcode?: boolean
  imgClassName?: string
  nameSymbols?: number
}

type infoDataType = {
  label: string,
  value: string,
  hidden?: boolean
  action?: () => void
}

export const SkuItemPreview = (props: ItemPreviewProps) => {
  const {
    image,
    title,
    direction = 'horizontal',
    article,
    barcode,
    batch,
    weight,
    merchant,
    withOpenBarcode,
    imgClassName,
    nameSymbols
  } = props

  const isTsd = isMobile().any

  const isHorizontalMode = direction === 'horizontal'
  const {
    text: itemName,
    toggleText
  } = useCollapseText({text: title, symbols: nameSymbols})

  const itemBarcode = (
    withOpenBarcode
      ? barcode
      : hideSymbols(6, barcode)
  )
  const infoData: infoDataType[] = [
    { label: 'Партия', value: getBatchStr(batch), hidden: Boolean(!batch?.num && !batch?.exp_date) },
    { label: 'Наименование', value: itemName, hidden: false, action: toggleText },
    { label: 'Организация', value: merchant, hidden: !merchant },
    { label: 'Штрихкод', value: itemBarcode, hidden: !barcode },
    { label: 'Артикул', value: article, hidden: !article },
    { label: 'Текущий вес', value: `${convertWeight('g', 'kg', +weight)} кг.`, hidden: !weight },
  ]
  return (
    <Flex
      fullWidth={isTsd}
      gap={isTsd ? 's' : 'm'}
      align={isTsd ? 'start' : 'center'}
      direction={isHorizontalMode ? 'row' : 'column'}
    >
      <Img
        className={cx(
          styles.previewImg,
          { [styles.center]: !isHorizontalMode && isTsd },
          imgClassName,
        )}
        src={image || noImg}
        yandex
      />
      <Flex
        gap={'xs'}
        direction={'column'}
      >
        {
          infoData.map((item) => (
            <div key={item.label} onClick={item.action} hidden={item.hidden}>
              <Text
                as={'p'}
                view={'ghost'}
                size={isTsd ? 'xs' : 'm'}
                children={item.label}
              />
              <Text
                as={'p'}
                size={isTsd ? 's' : 'l'}
                children={item.value}
                style={{
                  wordBreak: 'break-word'
                }}
              />
            </div>
          ))
        }
      </Flex>
    </Flex>
  )
}
