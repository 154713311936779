import { IBarcode, ICellPlace, ICellSkuStock, ISku } from '@/interfaces'
import { TableColumn } from '@consta/uikit/Table'
import { Badge } from '@consta/uikit/Badge'
import React from 'react'
import days from 'dayjs'
import { DATE_VIEW } from '@shared/const/date'
import { getBatchById } from '@entities/Sku/lib/batch'
import { boolToText, convertLength, convertWeight } from '@shared/helpers'
import { IUpdateSkuItemData } from '@/hooks/items/useCreateEditItem'
import { FormState, IBarcodeForm } from '@pages/DirectoriesPage/pages/Nomenclature/pages/Item/types'
import { IconEdit } from '@consta/icons/IconEdit'
import { IconTrash } from '@consta/icons/IconTrash'
import { Flex } from '@shared/ui/Flex'
import { Button } from '@shared/ui/btns/Button'

export const getBarcodeTable = (
  barcodes: IBarcodeForm[],
  onClickEdit: (barcode: {barcode: string, type: string, _id: string}) => void,
  onClickDelete: (barcode: string) => void,
  isEdit: boolean
) => {
  const rows = barcodes.map((code) => ({
    id: code.barcode,
    isActive: boolToText(code.active),
    type: code.type,
    isScanned: boolToText(code.used_in_last_supply),
    editDelete: null,
    _id: code._id
  })) || []

  const columns: TableColumn<typeof rows[number]>[] = [
    {
      title: 'ШК',
      accessor: 'id',
    },
    {
      title: 'Активный',
      accessor: 'isActive',
    },
    {
      title: 'Тип',
      accessor: 'type',
    },
    {
      title: 'Сканировалась в поставке',
      accessor: 'isScanned',
    },
    {
      title: '',
      accessor: 'editDelete',
      renderCell: (row) => isEdit ? (
        <Flex gap={'s'}>
          <Button
            onlyIcon
            iconLeft={IconEdit}
            size={'xs'}
            view={'ghost'}
            onClick={() => onClickEdit({barcode: row.id, type: row.type, _id: row._id})}
          />
          <Button
            onlyIcon
            iconLeft={IconTrash}
            size={'xs'}
            view={'ghost'}
            theme={'danger'}
            onClick={() => onClickDelete(row.id)}
          />
        </Flex>
      ) : null
    },
  ]

  return {
    barcodeRows: rows,
    barcodeCols: columns,
  }
}

export const getPartsTable = (item: ISku) => {
  const rows = item?.parts.map((part) => ({
    id: part.sku_id,
    quantity: part.quantity,
  })) || []

  const columns: TableColumn<typeof rows[number]>[] = [
    {
      title: 'Номенклатура',
      accessor: 'id',
    },
    {
      title: 'Количество',
      accessor: 'quantity',
    },
  ]

  return {
    partsRows: rows,
    partsCols: columns,
  }
}

export const getUnTable = (item: ISku) => {
  const rows = item?.scan_serial_numbers.map((serial) => ({
    id: serial.title,
    type: serial.code,
    template: serial.template,
    required: boolToText(!serial.required),
  })) || []

  const columns: TableColumn<typeof rows[number]>[] = [
    {
      title: 'Тип',
      accessor: 'type',
    },
    {
      title: 'Маска',
      accessor: 'template',
    },
    {
      title: 'Разрешить операции без сканирования',
      accessor: 'required',
    },
  ]

  return {
    unRows: rows,
    unCols: columns,
  }
}

export const getCellsStock = ({ sku, stock, places }: { stock: ICellSkuStock[], places: ICellPlace[], sku: ISku }) => {
  const rows = places.map((place) => {
    const stocksPlace = stock?.filter((stockItem) => stockItem.place_id === place.barcode)
    const quantity = stocksPlace.reduce((acc, stockItem) => acc + stockItem.quantity, 0)
    const reserved = stocksPlace.reduce((acc, stockItem) => acc + stockItem.reserved, 0)
    const skuBatch = stocksPlace.length === 1 && stocksPlace[0].sku_batch_id
      ? getBatchById(sku?.batches, stocksPlace[0].sku_batch_id)
      : undefined
    return ({
      id: place.id,
      cell: place.title,
      condition: place.condition,
      quantity: quantity || 0,
      reserved: reserved || 0,
      expDate: skuBatch ? days(skuBatch.exp_date).format(DATE_VIEW) : stocksPlace.length > 1 ? '...' : undefined,
      rows: stocksPlace.length > 1
        ? stocksPlace.map((stockItem, i) => {
          const skuBatch = getBatchById(sku?.batches, stockItem.sku_batch_id)
          return ({
            id: `${stockItem.sku_id}${skuBatch?.num}${i}`,
            quantity: stockItem.quantity,
            reserved: stockItem.reserved,
            expDate: skuBatch?.exp_date ? days(skuBatch.exp_date).format(DATE_VIEW) : undefined,
          })
        })
        : undefined,
    })
  }) || []

  const columns: TableColumn<typeof rows[number]>[] = [
    {
      title: 'Ячейка',
      accessor: 'cell',
    },
    {
      title: 'Годность',
      accessor: 'condition',
      renderCell: (row) => {
        if (row.condition === 'GOOD') {
          return <Badge status='success' label='Годный' />
        }
        if (row.condition === 'DEFECT') {
          return <Badge status='error' label='Брак' />
        }
      },
    },
    {
      title: 'Срок годности',
      accessor: 'expDate',
    },
    {
      title: 'В наличии',
      accessor: 'quantity',
    },
    {
      title: 'К отбору',
      accessor: 'reserved',
    },
  ]

  return {
    stockRows: rows,
    stockCols: columns,
  }
}

export const generatePostData = (data: FormState, barcodes: IBarcode[], id?: string): IUpdateSkuItemData => {
  return {
    id: id,
    article: data.article,
    barcodes: barcodes.map(barcodeObj => ({barcode: barcodeObj.barcode, type: barcodeObj.type})),
    country_of_origin: data.country_of_origin ? `${data.country_of_origin.id}` : undefined,
    dimensions_plan: {
      width: data.width
        ? convertLength('sm', 'mm', Number(data.width))
        : undefined,
      height: data.height
        ? convertLength('sm', 'mm', Number(data.height))
        : undefined,
      depth: data.depth
        ? convertLength('sm', 'mm', Number(data.depth))
        : undefined,
    },
    external_id: data.external_id,
    manufacturer: data.manufacturer,
    title: data.title,
    title_en: data.title_en,
    tnved_code: data.tnved_code,
    weight_plan: data.weight
      ? convertWeight('kg', 'g', Number(data.weight))
      : undefined,

    fragile: data.fragile
      ? JSON.parse(data.fragile)
      : undefined,
    batch_accounting: data.batch_accounting
      ? JSON.parse(data.batch_accounting)
      : undefined,
    batch_expdate_mandatory: data.batch_expdate_mandatory
      ? JSON.parse(data.batch_expdate_mandatory)
      : undefined,
    require_dimensions: data.require_dimensions
      ? JSON.parse(data.require_dimensions)
      : undefined,
    require_weight: data.require_weight
      ? JSON.parse(data.require_weight)
      : undefined,
  }
}