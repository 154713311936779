import { IVehicle } from '@/interfaces'
import { ActivationBadge } from '@shared/ui/Badges/ActivationBadge'
import React from 'react'
import { EditRemoveButtons } from '@pages/DirectoriesPage/components/EditRemoveButtons/EditRemoveButtons'
import { TableColumnType, TableRowType } from '@/components/Table/TanStackTable'

export const generateTableData = (
  vehicles: IVehicle[],
  handleEdit: (code: string) => void,
  handleDeleteRevert: (code: string, isDelete: boolean) => void,
) => {
  const rows: TableRowType[] = vehicles.map((item) => {
    return {
      id: item.id,
      model: item.model,
      plate_number: item.plate_number,
      deleted: item.deleted,
      edit: '',
    }
  })

  const columns: TableColumnType[] = [
    {
      header: 'Модель',
      size: 300,
      id: 'model',
      accessorKey: 'model',
    },
    {
      header: 'Номер',
      size: 300,
      id: 'plate_number',
      accessorKey: 'plate_number',
    },
    {
      header: 'Компания',
      size: 300,
      id: 'deleted__action',
      accessorKey: 'deleted__action',
      cell: ({ row }) => {
        return (
          <ActivationBadge size={row.getTableDensity()} active={!row.original.deleted} />
        )
      }
    },
    {
      header: '',
      size: 200,
      id: 'edit__action',
      accessorKey: 'edit__action',
      cell: ({ row }) => {
        return (
          <EditRemoveButtons
            withEdit
            withRemove
            withRevert
            deleted={row.original.deleted}
            onEditClick={() => handleEdit(row.original.id)}
            onRemoveClick={() => handleDeleteRevert(row.original.id, !row.original.deleted)}
          />
        )
      },
    },
  ]
  return { rows, columns }
}
