import React, { ComponentProps } from 'react'
import cx from 'classnames'

import styles from './scan-wrapper.module.scss'

import ScanHeader from '../ScanHeader'
import { CurrentBase } from 'src/components/widgets'
import { Flex } from '@/newSrc/shared/ui/Flex'
import useIsMobile from '@/newSrc/pages/ShipmentFromWarehouse/utils/useIsMobile'

declare const ScanWrapperPropSize: readonly ['s', 'm', 'l']
declare type ScanWrapperPropSize = (typeof ScanWrapperPropSize)[number]

interface ScanWrapperProps {
  className?: string
  wrapperChildrenGap?: ComponentProps<typeof Flex>['gap']
  children?: React.ReactNode
  title?: string
  subtitle?: string
  size?: ScanWrapperPropSize
  withBase?: boolean
  withoutGoToMainMenu?: boolean
}

const ScanWrapper = (props: ScanWrapperProps) => {
  const {
    title,
    subtitle,
    size = 'm',
    wrapperChildrenGap = 'l',
    withoutGoToMainMenu,
    className,
    withBase = true,
  } = props
  const isMobile = useIsMobile()

  return (
    <Flex align={!isMobile ? 'center' : undefined} className={styles.wrap}>
      <div className={cx(styles.main, styles[size], className)}>
        <div className={styles.head}>
          <ScanHeader
            title={title}
            subtitle={subtitle}
            withoutGoToMainMenu={withoutGoToMainMenu}
            className={cx({ [styles.mainHeader]: withBase })}
          />
          {withBase && !isMobile ? <CurrentBase /> : null}
        </div>
        <Flex gap={wrapperChildrenGap} direction={'column'}>
          {props.children}
        </Flex>
      </div>
    </Flex>
  )
}

export default ScanWrapper
