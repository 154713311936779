import { IPhysicalSetState } from './interfaces'
import { IReducerAction } from '@/interfaces'
import {
  RESET_STATE,
  SET_ALL_CONTAINERS,
  SET_ALL_SKUS,
  SET_CACHE_SERIAL_NUMBERS,
  SET_CHANGE_PACKAGING_PLACE_VISIBLE,
  SET_MODAL_CHZ_VISIBLE,
  SET_PACKAGINGS,
  SET_PACKAGINGS_SKUS,
  SET_PHYSICAL_SET_ADDING_SKU_ITEM,
  SET_PHYSICAL_SET_ORDER,
  SET_PHYSICAL_SET_ORDER_PLACES,
  SET_PHYSICAL_SET_ORDERS,
  SET_PHYSICAL_SET_PLACE_MODAL_VISIBLE,
  SET_WEIGHT_DIMENSIONS_PLACE_VISIBLE,
} from './actions'
import { cloneDeep } from '@shared/helpers'

export const initialState: IPhysicalSetState = {
  packagings: [],
  skusPackagings: [],
  allSkus: [],
  physicalSetOrders: [],
  physicalSetOrder: null,

  orderPlaces: [],

  cacheSerialNumbers: [],

  containers: [],

  showPhysicalSetPlace: false,

  weightDimensionsPlace: null,
  showChangePackagingPlace: false,

  addingSkuItem: null,
  physicalSetPlaceChz: null
}

export const reducer = (
  state: IPhysicalSetState,
  action: IReducerAction,
) => {
  const { type, payload } = action

  switch (type) {

    case SET_PHYSICAL_SET_ORDERS:
      return { ...state, physicalSetOrders: payload }

    case SET_PHYSICAL_SET_ORDER:
      return { ...state, physicalSetOrder: payload }

    case SET_PACKAGINGS:
      return { ...state, packagings: payload }

    case SET_PACKAGINGS_SKUS:
      return { ...state, skusPackagings: payload }

    case SET_ALL_SKUS:
      return { ...state, allSkus: payload }

    case SET_CACHE_SERIAL_NUMBERS:
      return { ...state, cacheSerialNumbers: payload }

    case SET_ALL_CONTAINERS:
      return { ...state, containers: payload }

    case SET_PHYSICAL_SET_ADDING_SKU_ITEM:
      return { ...state, addingSkuItem: payload }

    case SET_PHYSICAL_SET_ORDER_PLACES:
      return { ...state, orderPlaces: payload }


    case SET_WEIGHT_DIMENSIONS_PLACE_VISIBLE:
      return { ...state, weightDimensionsPlace: payload }

    case SET_MODAL_CHZ_VISIBLE:
      return { ...state, physicalSetPlaceChz: payload }

    case SET_CHANGE_PACKAGING_PLACE_VISIBLE:
      return { ...state, showChangePackagingPlace: payload }

    case SET_PHYSICAL_SET_PLACE_MODAL_VISIBLE:
      return { ...state, showPhysicalSetPlace: payload }

    case RESET_STATE:
      return {
        ...cloneDeep(initialState)
      }


    default:
      return state
  }
}
