import { IPhysicalSetState } from '../store/interfaces'
import { Dispatch, useCallback, useMemo } from 'react'
import {
  ConditionEnum,
  IContainer,
  IDraftPhysicalSetPlace,
  IDraftPhysicalSetPlaceItem,
  IPhysicalSetOrderDto,
  IPhysicalSetOrderItemDto,
  IPhysicalSetOrderPlaceDto,
  IPutPhysicalSetOrderPlaceInnerDto,
  IPutPhysicalSetOrderPlaceInnerItemDto,
  IReducerAction,
  ISku,
  ISkuPart,
  PhysicalSetOrderOperationEnum,
  PhysicalSetOrderStatusEnum,
  PlaceStatusEnum,
} from '@/interfaces'
import { convertServerPlaceToDraft } from '../utils/converters'
import {
  setAddingSkuItemAction,
  setAllContainersAction,
  setAllPhysicalSetPlacesAction,
  setAllSkusAction,
  setCacheSerialsAction,
  setModalChzVisibleAction,
  setPhysicalSetOrderAction,
  setWeightDimensionsPlaceVisibleAction,
} from '../store/actions'
import { useAppContext } from '@shared/providers/AppContextProvider'
import { ItemProcessResultType } from '@/components/widgets'
import dayjs from 'dayjs'
import { DATETIME_REQUEST_FORMAT } from '@shared/const/date'
import { CombinedPlaceItemType, CombinedPlaceType } from '../types'
import { useNotifications } from '@shared/providers/NotificationProvider'


interface Props {
  state: IPhysicalSetState,
  dispatch: Dispatch<IReducerAction>
}

/*
* Хук сложного взаимодействия с стором
* */

const useManageState = (props: Props) => {
  const {
    state: {
      physicalSetOrder,
      orderPlaces,
      containers,
    },
    dispatch,
  } = props

  const notification = useNotifications()

  const {
    currentUser: { workTable },
  } = useAppContext()

  // Разборка
  const isDisassembling = physicalSetOrder?.operation === PhysicalSetOrderOperationEnum.DISASSEMBLING
  // Сборка
  const isAssembling = physicalSetOrder?.operation === PhysicalSetOrderOperationEnum.ASSEMBLING


  /** Меняем статус всех тар на закрытый */
  const changeStatusToClosedForAllTares = () => {
    containers.forEach((container) => {
      if (container.open) {
        container.open = false
      }
    })
    dispatch(setAllContainersAction([...containers]))
  }

  /** Изменяем статус тары на закрытый */
  const changeTareStatusToClosed = (tareBarcode: string) => {
    let container = containers.find(container => container.barcode === tareBarcode)
    if (container) {
      container.open = false
    }
    dispatch(setAllContainersAction([...containers]))
  }

  /** Проверка на корректность рабочего места */
  const checkItemCompleteForPlace = (itemId: string) => {
    if (!currentPhysicalSetPlace || !physicalSetOrder) {
      console.error('Проблема со стейтом')
      return true
    }
    const currentPart = physicalSetOrder.sku.parts.find(part => itemId === part.sku_id)
    const addedItemQuantity = currentPhysicalSetPlace.items.reduce(
      (acc, item) => itemId === item.sku_id ? acc + item.quantity : acc, 0
    )
    return addedItemQuantity === currentPart?.quantity
  }

  /** Проверка на корректность рабочего места */
  const checkCurrentWorkspace = (workspaceBarcode?: string) => {
    return workspaceBarcode === workTable
  }

  /** Проверка принадлежности контейнера (тары к рабочему месту и кондиции) */
  const checkContainerByWorkspace = (
    containerBarcode: string,
    places: CombinedPlaceType[]
  ) => {
    return places.length && Boolean(
      places.find(place => {
        const items: CombinedPlaceItemType[] = place.items
        const tarePlaceItem = items.find(item => item.tare_barcode === containerBarcode)
        /* В сборке / разборки раный способ определения тары для места */
        const placedTareBarcode = place.tare_barcode || tarePlaceItem?.tare_barcode
        return (
          placedTareBarcode === containerBarcode
          && checkCurrentWorkspace(place.workspace?.barcode)
        )
      })
    )
  }

  /** Поиск тары по ШК */
  const findTareByBarcode = (barcode: string) => {
    return containers.find(container => container.barcode === barcode)
  }

  /** Поиск тары по кондиции и рабочему месту */
  const findCurrentTare = (
    places: CombinedPlaceType[],
    containers: IContainer[],
    condition: ConditionEnum = ConditionEnum.GOOD
  ) => {
    return containers.find(container => (
      container.open
      && container.condition === condition
      && checkContainerByWorkspace(container.barcode, places)
    ))
  }

  /** Поиск открытого комплетка для пользователя */
  const findOpenPlace = useCallback(
    <P extends CombinedPlaceType>(places: P[]) => {
      return places.find(place =>
        place.status === PlaceStatusEnum.PACKING
        && checkCurrentWorkspace(place.workspace?.barcode),
      )
    }, [workTable])

  /** Все тары рабочего места */
  const workspaceContainers = useMemo(() => {
      return containers.filter(tare => checkContainerByWorkspace(tare.barcode, orderPlaces))
    },[containers, orderPlaces])

  /** Открытые тары (только текущего стола) */
  const openWorkspaceContainers = useMemo(() => {
      return workspaceContainers.filter(tare => tare.open)
    },[containers, workspaceContainers])

  /** Вычисляем текущее открытый комплект */
  const currentPhysicalSetPlace = useMemo(
    () => findOpenPlace(orderPlaces) || null,
    [orderPlaces, findOpenPlace],
  )
  /** Упакованный комплект для образца (получение ВГХ) */
  const packedPhysicalSetPlace = useMemo(() =>
      orderPlaces?.find(place => place.status === PlaceStatusEnum.PACKED),
    [orderPlaces],
  )
  /** Получение кол-ва упакованных комплектов */
  const orderStatusIsCorrect = useMemo(() => {
      if (!physicalSetOrder) {
        return false
      }
      return [
        PhysicalSetOrderStatusEnum.PICKED,
        PhysicalSetOrderStatusEnum.PACKING,
      ].includes(physicalSetOrder.status)
    },
    [physicalSetOrder?.status],
  )
  /** Получение кол-ва упакованных комплектов */
  const completedPhysicalSetLen = useMemo(() =>
      orderPlaces?.filter(place => place.status === PlaceStatusEnum.PACKED).length || 0,
    [orderPlaces],
  )
  const isFirstPhysicalSet = !packedPhysicalSetPlace

  /** Вычисляем ужу добавленные серийники и добавляем  */
  const setCacheSerialsByOrderPlaces = (places: IPhysicalSetOrderPlaceDto[]) => {
    const scannedSerialNumbers = places
      .reduce((acc, place) => {
        place.items.forEach((item) => {
          const serial_numbers = item.serial_numbers
          if (serial_numbers.length) {
            const numbers = serial_numbers.map((serial_number) => serial_number.value)
            acc.push(numbers)
          }
        })
        return acc
      }, [] as string[][])
      .flat()

    let resultCacheSerialsAction = Array.from(new Set(scannedSerialNumbers))
    dispatch(setCacheSerialsAction(resultCacheSerialsAction))
  }

  /** Задаем начальные данные после получения с сервера */
  const setNewOrderData = (order: IPhysicalSetOrderDto | null, containers: IContainer[], skus: ISku[] = []) => {
    if (order) {
      dispatch(setAllPhysicalSetPlacesAction(order.places.map(convertServerPlaceToDraft)))
      setCacheSerialsByOrderPlaces(order.places)
    }
    dispatch(setPhysicalSetOrderAction({
      ...order,
      sku: skus.find(sku => sku.id === order.sku_id),
      items: order.items.map(item => ({
        ...item,
        sku: skus.find(sku => sku.id === item.sku_id)
      }))
    }))
    dispatch(setAllContainersAction(containers))
    dispatch(setAllSkusAction(skus))
  }

  /** Задаем новую тару */
  const setNewTare = (newTare: IContainer) => {
    dispatch(setAllContainersAction([newTare, ...containers]))
  }

  /** Обновляем стейт текущего места согласно данным о товаре */
  const setOrUpdateCurrentPlaceInfo = (placeId: string, place: IPutPhysicalSetOrderPlaceInnerDto) => {
    let newPlaces = orderPlaces || []
    const newPlace = {
      ...place,
      id: placeId,
    }
    const alreadyAddedPlaceIndex = newPlaces.findIndex(opl => opl.id === placeId || opl.id === place.id)
    const correctPlaceIndex = alreadyAddedPlaceIndex >= 0 ? alreadyAddedPlaceIndex : 0
    if (alreadyAddedPlaceIndex < 0) {
      newPlaces = [newPlace, ...newPlaces]
    } else {
      newPlaces[correctPlaceIndex] = newPlace
    }
    dispatch(setAllPhysicalSetPlacesAction([...newPlaces]))
  }

  /** Поиск товара по ШК */
  const getSkuItemFromOrder = (skuBarcode: string): IPhysicalSetOrderItemDto | undefined => {
    return physicalSetOrder?.items?.find(item => item.sku?.barcodes.find(b => b.barcode === skuBarcode))
  }

  /** Задаем товар на сборку */
  const setCurrentSkuItem = (addingItem: IPhysicalSetOrderItemDto, barcodeUsed: string): IDraftPhysicalSetPlaceItem | undefined => {
    const complete = isDisassembling && checkItemCompleteForPlace(addingItem.sku_id)
    if (complete) {
      notification?.show('alert', 'Весь товар комплекта уже добавлен')
      return
    }
    const newCurrentItem = {
      ...addingItem,
      barcode_used: barcodeUsed,
    }
    dispatch(setAddingSkuItemAction(newCurrentItem))
    return newCurrentItem
  }

  /** Проверка комплекта на завершенность */
  const checkPhysicalSetPlaceIsComplete = (skuParts: ISkuPart[], items: IPutPhysicalSetOrderPlaceInnerItemDto[]): boolean => {
    if (!skuParts.length) {
      return false
    }
    return skuParts.reduce((acc, part) => {
      const currentItemQuantity = items
        .filter(item => item.sku_id === part.sku_id)
        .reduce((acc, item) => acc + item.quantity, 0)
      return acc && currentItemQuantity === part.quantity
    }, true)
  }

  const makeNewEmptyPlace = (data: {
    tareBarcode?: string,
    disassembledPlaceId?: string
  }): IDraftPhysicalSetPlace => {
    return {
      /* требуется только на сборке */
      tare_barcode: isAssembling ? data.tareBarcode : undefined,
      disassembled_place_id: data.disassembledPlaceId,
      status: PlaceStatusEnum.PACKING,
      workspace: workTable ? { barcode: workTable } : undefined,
      items: [],
    }
  }

  /** Подготавливаем данные для создания или обновления комплекта */
  const addItemToCurrentPlaceLocal = (
    itemProcessData: ItemProcessResultType & {tare_barcode?: string},
    addingSkuItem: IDraftPhysicalSetPlaceItem,
    container: IContainer,
  ): IPutPhysicalSetOrderPlaceInnerDto => {
    const skuParts = physicalSetOrder?.sku?.parts || []
    const newPlace = makeNewEmptyPlace({tareBarcode: container.barcode})

    const place = currentPhysicalSetPlace || newPlace
    const newItems = [
      ...place.items,
      {
        packed_at: dayjs().format(DATETIME_REQUEST_FORMAT),
        quantity: itemProcessData.quantity,
        serial_numbers: itemProcessData.serial_numbers,
        expiration_date: itemProcessData.expirationDate,
        date_of_manufacture: itemProcessData.dateOfManufacture,
        sku_id: addingSkuItem.sku_id,
        barcode_used: addingSkuItem.barcode_used,
        /* требуется только на разборке */
        tare_barcode: isDisassembling ? container.barcode : undefined
      },
    ]
    const isComplete = checkPhysicalSetPlaceIsComplete(skuParts, newItems)
    const weightDimension = packedPhysicalSetPlace ? {
      packagings: packedPhysicalSetPlace.packagings,
      dimensions: packedPhysicalSetPlace.dimensions,
      weight: packedPhysicalSetPlace.weight,
    } : {}
    return {
      ...place,
      ...weightDimension,
      status: isComplete ? PlaceStatusEnum.PACKED : PlaceStatusEnum.PACKING,
      items: newItems,
    }
  }

  const openWeightDimensionPlaceModal = (place: IDraftPhysicalSetPlace) => {
    dispatch(setWeightDimensionsPlaceVisibleAction(place))
    dispatch(setAddingSkuItemAction(null))
  }
  const closeWeightDimensionPlaceModal = () => {
    dispatch(setWeightDimensionsPlaceVisibleAction(null))
  }

  const openChzModal = (place: IDraftPhysicalSetPlace) => {
    dispatch(setModalChzVisibleAction(place))
    dispatch(setAddingSkuItemAction(null))
  }
  const closeChzModal = () => {
    dispatch(setModalChzVisibleAction(null))
  }

  return {
    orderPacked: physicalSetOrder?.status === PhysicalSetOrderStatusEnum.PACKED,
    isDisassembling,
    isAssembling,
    currentPhysicalSetPlace,
    orderStatusIsCorrect,
    completedPhysicalSetLen,
    isFirstPhysicalSet,
    workspaceContainers,
    openWorkspaceContainers,

    setNewTare,
    findTareByBarcode,
    checkContainerByWorkspace,
    setNewOrderData,
    findCurrentTare,
    setOrUpdateCurrentPlaceInfo,
    makeNewEmptyPlace,
    getSkuItemFromOrder,
    setCurrentSkuItem,
    addItemToCurrentPlaceLocal,
    checkCurrentWorkspace,
    changeTareStatusToClosed,
    changeStatusToClosedForAllTares,
    openWeightDimensionPlaceModal,
    closeWeightDimensionPlaceModal,
    openChzModal,
    closeChzModal,
  }
}
export default useManageState
